/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { HomeContainer } from '../Layout/homeStyles';
import {
  AddOperationContainer,
  BtnContainer,
  BoxMui,
  BoxTitle,
  BreadcumsContainer,
  OperationFormContainer,
} from './operationsStyles';
import { useOperations } from './useOperations';
import useClients from '../Clients/useClients';
import useUsers from '../Team/useUsers';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import useStock from '../Stock/useStock';
import Button from '../../components/Button/Button';
import CancelButton from '../../components/Button/CancelButton';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';
import PayServProvForm from './Forms/PayServProvForm';
import PaySalariesForm from './Forms/PaySalariesForm';
import DeliveryPositionForm from './Forms/DeliveryPositionForm';
import MainForm from './Forms/MainForm';
import CurrentAccountForm from './Forms/CurrentAccountForm';
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@mui/material';
import theme from '../../theme';
import { operationTypes } from '../../utils/constants';
import useLogistic from '../Logistic/useLogistic';
import useNotistack from '../Notistack/useNotistack';
import CurrentAccountTransferForm from './Forms/CurrentAccountTransferForm';
import operationFinacialOrinstitutional from '../../utils/operationFinancialOrInstitutional';

const AddOperation = ({ notificationClicked, setNotificationClicked }) => {
  const navigate = useNavigate();
  const { deliveryId } = useParams();

  const { getClients, clients } = useClients();
  const { getDeliverys, getUsers, deliverys, users } = useUsers();
  const { getStock, stock } = useStock();
  const {
    createOperation,
    getOperations,
    registerPosition,
    serviceProviders,
    getServiceProivders,
    createNewServiceProvider,
    deleteServiceProvider,
    errors,
    setErrors,
    loading,
    changeOperationStatus,
    changeSubOperationStatus,
    getDeliveryOperations,
    deliveryOperations,
  } = useOperations();

  const [createIncomeCurrentAccount, setCreateIncomeCurrentAccount] =
    useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClientToTransfer, setSelectedClientToTransfer] =
    useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currencyChecked, setCurrencyChecked] = useState(null);
  const [valueReciboEntra, setValueReciboEntra] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const { showNotification } = useNotistack();
  const [selectValue, setSelectValue] = useState(null);
  const [currencyToTransfer, setCurrencyToTransfer] = useState(null);
  const [isClientsLoading, setIsClientsLoading] = useState(true);
  const [isStockLoading, setIsStockLoading] = useState(true);
  const [isDeliveriesLoading, setIsDeliveriesLoading] = useState(true);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const location = useLocation();
  const params = useParams();
  const clientId = params.id;

  const [position, setPosition] = useState({
    currency: '',
    balance: '',
    userId: selectedUser?._id,
  });

  const [operation, setOperation] = useState({
    client: '',
    clientToTransfer: '',
    operation_type: '',
    input_currency: '',
    input_amount: '',
    output_currency: '',
    output_amount: '',
    liquidator_type: '',
    commission: '',
    input_anomaly: '',
    output_anomaly: '',
    comments: '',
    profile: '',
    address: '',
    exchange_rate: '',
    useCurrentAccount: false,
    addressDetail: '',
    exchangeRateType: 'multiplicar',
    calculatedExchangeRate: 0,
    mathematicalOperation: '',
    subOperations: [],
    broker: '',
    intermediary: '',
    intermediaryAmount: '',
    amount: '',
    marketPrice: 0,
    pointsOfDifference: 0,
    intermediaryType: '',
    brokerType: '',
    useDolarOrUsdtBase: '',
    exchangeRateToConvert: 0,
    createIncomeCurrentAccount: false,
    isLiquidatorTypeChecked: false,
    lymBroker: '',
    lymCommission: '',
  });

  const [subOperations, setSubOperations] = useState([]);
  const { getPositionByProfile, deliveryPosition } = useLogistic();

  const handlePositionChange = (event, value) => {
    setPosition({
      ...position,
      [event.target.name]: event.target.value,
    });
  };

  const handleAddPosition = (event) => {
    if (operation.operation_type === 'RECIBO/ENTRA POS.DELIVERY') {
      changeStatusSelect(selectValue);
    }
    event.preventDefault();
    registerPosition(position, operation.operation_type);
  };

  const handleInputChange = (event) => {
    if (errors[event.target.name]) {
      setErrors({ ...errors, [event.target.name]: false });
    }
    setOperation({
      ...operation,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubOperationsInputChange = (event, index) => {
    if (errors[event.target.name]) {
      setErrors({ ...errors, [event.target.name]: false });
    }

    const updatedSubOperations = [...subOperations];
    const value =
      event.target.name === 'liquidator_type' ||
      event.target.name === 'address' ||
      event.target.name === 'addressDetail'
        ? event.target.value
        : parseFloat(event.target.value) || 0;

    updatedSubOperations[index] = {
      ...updatedSubOperations[index],
      [event.target.name]: value,
    };

    // Si no es la última suboperación, ajustar la última suboperación
    if (index < updatedSubOperations.length - 1) {
      const totalInput = updatedSubOperations
        .slice(0, -1)
        .reduce((acc, subOp) => acc + (subOp.input_amount || 0), 0);
      const totalOutput = updatedSubOperations
        .slice(0, -1)
        .reduce((acc, subOp) => acc + (subOp.output_amount || 0), 0);

      updatedSubOperations[updatedSubOperations.length - 1] = {
        ...updatedSubOperations[updatedSubOperations.length - 1],
        input_amount: operation.input_amount - totalInput,
        output_amount: operation.output_amount - totalOutput,
      };
    }

    setSubOperations(updatedSubOperations);
  };

  const handleNumericFormatChange = (values, name, e) => {
    const { floatValue } = values;
    setOperation({
      ...operation,
      [name]: floatValue,
    });
  };

  const rows = deliveryOperations.flatMap((operation) => {
    if (operation?.subOperations?.length > 0) {
      return (
        // Si solo una de las suboperaciones es C.delivery, solo quedara un elemento en el array del mapeo, por lo que siempre sera 0 el index.
        operation.subOperations
          .filter((subOp) => subOp.status === 'Confirmado Delivery')
          .map((subOp, subOpIndex) => ({
            id: subOp._id,
            index: `${operation.index}-${
              operation.subOperations.findIndex((e) => e._id === subOp._id) + 1
            }`,
            client: operation.client?.name,
            inputCurrency: operation.input_currency?.currency,
            inputAmount: subOp.input_amount,
            outputCurrency: operation.output_currency?.currency,
            outputAmount: subOp.output_amount,
            operationType: operation.operation_type,
            isSubOp: true,
            operationId: operation._id,
          }))
      );
    } else if (operation.status === 'Confirmado Delivery') {
      return [
        {
          id: operation._id,
          index: operation.index,
          client: operation.client?.name,
          inputCurrency: operation.input_currency?.currency,
          inputAmount: operation.input_amount,
          outputCurrency: operation.output_currency?.currency,
          outputAmount: operation.output_amount,
          operationType: operation.operation_type,
          isSubOp: false,
        },
      ];
    } else {
      return [];
    }
  });

  const changeStatusSelect = async (e) => {
    try {
      rowSelectionModel.map(async (op) => {
        const hasActiveIsSubOp = rows.some(
          (row) => row.id === op && row.isSubOp,
        );
        const opId = rows.find((row) => row.id === op)?.operationId || null;

        const targetRow = rows.find((row) => row.id === op && row.isSubOp);

        const subOpIndex = targetRow
          ? parseInt(targetRow.index.split('-')[1])
          : null;

        if (hasActiveIsSubOp) {
          await changeSubOperationStatus(opId, subOpIndex - 1, {
            statusValue: e,
          });
          showNotification(
            '¡El estado de las operaciones se modificó con éxito!',
            'success',
          );
        } else {
          await changeOperationStatus(op, { statusValue: e }, 'multi');
        }
      });
    } catch (error) {
      showNotification(
        'Ha ocurrido un error al cambiar el estado de las operaciones.',
        'error',
      );
    }
    setRowSelectionModel([]);
  };

  const validateAnomaliesAmounts = () => {
    const { input_anomaly, output_anomaly, input_amount, output_amount } =
      operation;

    if (input_anomaly > input_amount || output_anomaly > output_amount) {
      showNotification(
        'Las anomalias no pueden ser mayores a los montos de entrada/salida',
        'error',
      );
      return;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const operationsPromises = [];
    validateAnomaliesAmounts();

    try {
      const isInstitutionalOperation = operationFinacialOrinstitutional(
        operation.operation_type,
      );

      if (selectedClient) {
        operation.client = selectedClient._id;
      }

      if (operation.operation_type === 'TRASPASO CTA.CORRIENTE') {
        operation.clientToTransfer = selectedClientToTransfer?._id;
        operation.client = selectedClient?._id;
        operation.input_currency = currencyToTransfer?.value;
      }

      if (selectedUser) {
        operation.profile = selectedUser._id;
      }

      if (currencyChecked === null && operation.useDolarOrUsdtBase === '') {
        operation.useCurrentAccount = false;
      } else if (operation.useDolarOrUsdtBase === '') {
        operation.currencyChecked = currencyChecked;
        if (operation.input_amount > currencyChecked.amount) {
          if (currencyChecked.amount < 0) {
            // operation.input_anomaly = operation.input_amount;
          } else {
            // operation.input_anomaly = operation.input_amount - currencyChecked.amount;
          }
        } else {
          operation.input_anomaly = 0;
        }
      }

      const updatedOperation = {
        ...operation,
        classification: isInstitutionalOperation
          ? 'INSTITUTIONAL'
          : 'FINANCIERA',
        createIncomeCurrentAccount: createIncomeCurrentAccount,
      };

      const createdOperation = await createOperation(
        updatedOperation,
        operation.profile,
      );
      operationsPromises.push(createdOperation);

      if (createIncomeCurrentAccount) {
        const currentAccountOperation = {
          client: selectedClient._id,
          comments: operation.comments,
          input_amount: operation.output_amount,
          input_currency: operation.output_currency,
          operation_type: 'INGRESO CTA.CORRIENTE',
          classification: isInstitutionalOperation
            ? 'INSTITUTIONAL'
            : 'FINANCIERA',
          parentOperationId: createdOperation._id,
        };

        operationsPromises.push(
          createOperation(currentAccountOperation, operation.profile),
        );
      }

      await Promise.all(operationsPromises);
      navigate('/operaciones');
    } catch (error) {
      console.error('Error al crear operaciones:', error);
    }
  };

  const handleAddSubOperation = () => {
    const emptySubOperation = {
      liquidator_type: '',
      assignedDelivery: null,
      input_amount: 0,
      output_amount: 0,
    };

    const subOp = {
      liquidator_type: operation.liquidator_type,
      assignedDelivery: operation.assignedDelivery,
      input_amount: operation.input_amount,
      output_amount: operation.output_amount,
      input_anomaly: operation.input_anomaly,
      output_anomaly: operation.output_anomaly,
      address: operation.address,
    };
    setOperation({
      ...operation,
      liquidator_type: 'multiple',
    });
    if (subOperations?.length < 1) {
      setSubOperations((prevSubOperations) => [
        ...prevSubOperations,
        subOp,
        emptySubOperation,
      ]);
    } else {
      setSubOperations((prevSubOperations) => [
        ...prevSubOperations,
        emptySubOperation,
      ]);
    }
  };

  const handleSubtractSubOperation = (index) => {
    if (subOperations?.length === 2) {
      setSubOperations([]);

      setOperation({
        ...operation,
        liquidator_type: '',
      });
    } else {
      const updatedSubOperations = [...subOperations];
      updatedSubOperations.splice(index, 1);
      setSubOperations(updatedSubOperations);
    }
  };

  const handleSubOperationAmounts = (values, name, index) => {
    const { floatValue } = values;
    if (name === 'output_anomaly' || name === 'input_anomaly') {
      setSubOperations((prevSubOperations) => {
        const updatedSubOperations = [...prevSubOperations];
        updatedSubOperations[index] = {
          ...updatedSubOperations[index],
          [name]: floatValue,
        };
        return updatedSubOperations;
      });
    } else {
      let sumOfSubOpsAmounts = 0;

      const updatedSubOperations = [...subOperations];
      updatedSubOperations[index] = {
        ...updatedSubOperations[index],
        [name]: floatValue,
      };

      updatedSubOperations
        .slice(0, updatedSubOperations.length - 1)
        .forEach((subOperation) => {
          sumOfSubOpsAmounts +=
            subOperation?.[name] === undefined ? 0 : subOperation[name];
        });

      let lastSubOpValue =
        operation[name] === '' ? 0 : operation[name] - sumOfSubOpsAmounts;

      if (lastSubOpValue < 0) {
        lastSubOpValue = 0;
      }

      updatedSubOperations[updatedSubOperations.length - 1] = {
        ...updatedSubOperations[updatedSubOperations.length - 1],
        [name]: lastSubOpValue,
      };

      setSubOperations(updatedSubOperations);
    }
  };

  useEffect(() => {
    if (location.pathname.includes('ingreso-cta-cte')) {
      setOperation((operation) => ({
        ...operation,
        operation_type: 'INGRESO CTA.CORRIENTE',
      }));
      const clientToSet = clients.find((client) => client._id === clientId);
      setOperation((operation) => ({
        ...operation,
        client: clientToSet,
      }));
    }
  }, [clientId, clients]);

  useEffect(() => {
    setOperation((prevOperation) => ({
      ...prevOperation,
      subOperations: subOperations,
    }));
  }, [subOperations]);

  useEffect(() => {
    const loadClients = async () => {
      await getClients();
      setIsClientsLoading(false);
    };

    const loadStock = async () => {
      await getStock();
      setIsStockLoading(false);
    };

    const loadDeliveries = async () => {
      await getDeliverys();
      setIsDeliveriesLoading(false);
    };

    const loadUsers = async () => {
      await getUsers();
      setIsUsersLoading(false);
    };

    loadDeliveries();
    loadStock();
    loadClients();
    loadUsers();

    getOperations();
    getServiceProivders();
  }, []);

  useEffect(() => {
    setErrors({
      client: false,
      operation_type: false,
      input_currency: false,
      input_amount: false,
      output_currency: false,
      output_amount: false,
      liquidator_type: false,
      input_anomaly: false,
      output_anomaly: false,
      address: false,
      exchange_rate: false,
      paidTeamMember: false,
      broker: false,
      balance: false,
      currency: false,
      userId: false,
    });
  }, [operation.operation_type]);

  useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate(`/operaciones/${notificationClicked.id}`);
    }
  }, [notificationClicked.isClicked]);

  useEffect(() => {
    if (
      operation.operation_type === 'RECIBO/ENTRA POS.DELIVERY' &&
      position.userId
    ) {
      getPositionByProfile(position.userId);
    }
  }, [position.userId]);

  useEffect(() => {
    if (subOperations.length > 1) {
      const totalInput = subOperations
        .slice(0, -1)
        .reduce((acc, subOp) => acc + (subOp.input_amount || 0), 0);
      const totalOutput = subOperations
        .slice(0, -1)
        .reduce((acc, subOp) => acc + (subOp.output_amount || 0), 0);

      const lastSubOperation = subOperations[subOperations.length - 1];
      const updatedLastSubOperation = {
        ...lastSubOperation,
        input_amount: Math.max(0, operation.input_amount - totalInput),
        output_amount: Math.max(0, operation.output_amount - totalOutput),
      };

      // Actualizar la última suboperación sólo si es necesario
      if (
        lastSubOperation.input_amount !==
          updatedLastSubOperation.input_amount ||
        lastSubOperation.output_amount !== updatedLastSubOperation.output_amount
      ) {
        const updatedSubOperations = [
          ...subOperations.slice(0, -1),
          updatedLastSubOperation,
        ];
        setSubOperations(updatedSubOperations);
      }
    }
  }, [subOperations, operation.input_amount, operation.output_amount]);

  useEffect(() => {
    if (deliveryId) {
      const isRemovingPosition = location.pathname.includes('quitar-posicion');
      setOperation((operation) => ({
        ...operation,
        operation_type: isRemovingPosition
          ? 'RECIBO/ENTRA POS.DELIVERY'
          : 'ENTREGO/SALE POS.DELIVERY',
      }));
    }
  }, [deliveryId]);

  useEffect(() => {
    const loadDelivery = async () => {
      const delivery = deliverys.find((d) => d._id.toString() === deliveryId);
      if (delivery) {
        setSelectedUser(delivery);
        setPosition((prevPosition) => ({
          ...prevPosition,
          userId: delivery._id,
        }));
        await getDeliveryOperations(delivery._id);
      }
    };

    if (deliveryId && deliverys.length > 0) {
      loadDelivery();
    }
  }, [deliveryId, deliverys, operation.operation_type]);

  useEffect(() => {
    if (
      operation.operation_type === 'RECIBO/ENTRA POS.DELIVERY' &&
      position.userId
    ) {
      getPositionByProfile(position.userId);
      getDeliveryOperations(position.userId);
    }
  }, [position.userId, operation.operation_type]);

  const noOptionSelected = selectValue === null && !isDisabled;

  return (
    <HomeContainer style={{ marginTop: '50px' }}>
      <AddOperationContainer>
        <BreadcumsContainer>
          <BreadcrumbsMui
            title="Cargar operacion"
            prev="Operaciones"
            path={'/operaciones'}
          />
        </BreadcumsContainer>
        <BoxTitle>
          <h1>Cargar operacion</h1>
        </BoxTitle>
        <BoxMui
          component="form"
          noValidate
          onSubmit={
            operation.operation_type === 'ENTREGO/SALE POS.DELIVERY' ||
            operation.operation_type === 'RECIBO/ENTRA POS.DELIVERY'
              ? handleAddPosition
              : handleSubmit
          }
        >
          <OperationFormContainer>
            {operation.operation_type === 'PAGO SERV/PROV' ? (
              <PayServProvForm
                key={'payserv'}
                operation={operation}
                handleInputChange={handleInputChange}
                stock={stock}
                serviceProviders={serviceProviders}
                createNewServiceProvider={createNewServiceProvider}
                getServiceProivders={getServiceProivders}
                setOperation={setOperation}
                deleteServiceProvider={deleteServiceProvider}
                errors={errors}
                isStockLoading={isStockLoading}
                loading={loading}
              />
            ) : operation.operation_type === 'PAGO SUELDOS' ? (
              <PaySalariesForm
                key={'paysalaries'}
                handleInputChange={handleInputChange}
                setOperation={setOperation}
                operation={operation}
                stock={stock}
                users={users}
                errors={errors}
                isStockLoading={isStockLoading}
                isUsersLoading={isUsersLoading}
              />
            ) : operation.operation_type === 'ENTREGO/SALE POS.DELIVERY' ||
              operation.operation_type === 'RECIBO/ENTRA POS.DELIVERY' ? (
              <DeliveryPositionForm
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
                changeOperationStatus={changeOperationStatus}
                changeSubOperationStatus={changeSubOperationStatus}
                selectValue={selectValue}
                setSelectValue={setSelectValue}
                valueReciboEntra={valueReciboEntra}
                rows={rows}
                getDeliveryOperations={getDeliveryOperations}
                deliveryOperations={deliveryOperations}
                key={'del'}
                handlePositionChange={handlePositionChange}
                usersDelivery={deliverys}
                selectedUser={selectedUser}
                setPosition={setPosition}
                position={position}
                stock={stock}
                opType={operation.operation_type}
                operation={operation}
                handleInputChange={handleInputChange}
                deliveryPosition={deliveryPosition}
                errors={errors}
                setSelectedUser={setSelectedUser}
                isDeliveriesLoading={isDeliveriesLoading}
                isStockLoading={isStockLoading}
                loading={loading}
                deliveryId={deliveryId}
                isDisabled={isDisabled}
                setIsDisabled={setIsDisabled}
              />
            ) : operation.operation_type === 'TRASPASO CTA.CORRIENTE' ? (
              <CurrentAccountTransferForm
                key={'curre'}
                handleInputChange={handleInputChange}
                stock={stock}
                setSelectedClient={setSelectedClient}
                selectedClient={selectedClient}
                setSelectedClientToTransfer={setSelectedClientToTransfer}
                selectedClientToTransfer={selectedClientToTransfer}
                clients={clients}
                operation={operation}
                errors={errors}
                currencyToTransfer={currencyToTransfer}
                handleNumericFormatChange={handleNumericFormatChange}
                setCurrencyToTransfer={setCurrencyToTransfer}
                isClientsLoading={isClientsLoading}
                isStockLoading={isStockLoading}
              />
            ) : operation.operation_type === 'INGRESO CTA.CORRIENTE' ||
              operation.operation_type === 'EGRESO CTA.CORRIENTE' ? (
              <CurrentAccountForm
                key={'curre'}
                handleInputChange={handleInputChange}
                stock={stock}
                setSelectedClient={setSelectedClient}
                selectedClient={selectedClient}
                clients={clients}
                setSelectedUser={setSelectedUser}
                selectedUser={selectedUser}
                deliverys={deliverys}
                operation={operation}
                setOperation={setOperation}
                errors={errors}
                isClientsLoading={isClientsLoading}
                isStockLoading={isStockLoading}
                isDeliveriesLoading={isDeliveriesLoading}
              />
            ) : operation.operation_type === '' ? (
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  sx={{ color: `${theme.palette.inputText.main}` }}
                  id="input"
                >
                  Tipo de Operacion
                </InputLabel>
                <Select
                  labelId="input"
                  name="operation_type"
                  onChange={(event) => handleInputChange(event)}
                  value={operation.operation_type}
                >
                  <ListSubheader>OPERACIONES FINANCIERAS</ListSubheader>
                  {operationTypes().map((o, i) => {
                    if (i > 7) return null;
                    return (
                      <MenuItem key={i} value={o}>
                        {o}
                      </MenuItem>
                    );
                  })}
                  <ListSubheader>OPERACIONES INSTITUCIONALES</ListSubheader>
                  {operationTypes().map((o, i) => {
                    if (i < 8) return null;
                    return (
                      <MenuItem key={i} value={o}>
                        {o}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : (
              <MainForm
                key={'main'}
                handleInputChange={handleInputChange}
                handleNumericFormatChange={handleNumericFormatChange}
                operation={operation}
                setOperation={setOperation}
                getStock={getStock}
                stock={stock}
                usersDelivery={deliverys}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                clients={clients}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
                currencyChecked={currencyChecked}
                setCurrencyChecked={setCurrencyChecked}
                createIncomeCurrentAccount={createIncomeCurrentAccount}
                setCreateIncomeCurrentAccount={setCreateIncomeCurrentAccount}
                subOperations={subOperations}
                setSubOperations={setSubOperations}
                handleAddSubOperation={handleAddSubOperation}
                handleSubtractSubOperation={handleSubtractSubOperation}
                handleSubOperationsInputChange={handleSubOperationsInputChange}
                handleSubOperationAmounts={handleSubOperationAmounts}
                errors={errors}
                isClientsLoading={isClientsLoading}
                isStockLoading={isStockLoading}
                isDeliveriesLoading={isDeliveriesLoading}
              />
            )}
          </OperationFormContainer>
          {operation.operation_type === '' ? null : (
            <BtnContainer>
              <Button
                disable={loading || noOptionSelected}
                type="submit"
                title="GUARDAR"
              />
              <CancelButton
                title="CANCELAR"
                onClick={() => navigate('/operaciones')}
              />
            </BtnContainer>
          )}
        </BoxMui>
      </AddOperationContainer>
    </HomeContainer>
  );
};

export default AddOperation;
