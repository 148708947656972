import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import theme from '../../theme';

const StockHead = () => {
  const headerCellStyles = {
    fontWeight: '500',
    color: theme.palette.tableTitle.main,
  };

  return (
    <TableHead>
      <TableRow>
        {[
          'DIVISA',
          'TC (USD)',
          'FÍSICO ACTUAL',
          "COMISIONES USD", 
          "CC",
          'ANOMALÍA',
          'BALANCE TOTAL',
          '',
        ].map((title, index) => (
          <TableCell
            key={index}
            sx={{
              ...headerCellStyles,
              paddingLeft: index === 0 ? '25px' : undefined,
            }}
            align={index === 0 ? undefined : 'left'}
          >
            {title}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default StockHead;
