import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import Searcher from '../Searcher/Searcher';
import Broker from './Broker';
import BrokerHead from './BrokerHead';
import { useStyles } from './BrokersTableStyles';

const BrokersTable = ({
  brokers,
  loading,
  deleteBroker,
  brokersForSearch,
  getBrokers,
  setBrokers,
}) => {
  const classes = useStyles();

  const renderBrokersTable = () => (
    <TableContainer>
      <Table>
        <BrokerHead />
        <TableBody>
          {brokers.map((broker) => (
            <Broker
              key={broker._id}
              broker={broker}
              deleteBroker={deleteBroker}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderEmptyMessage = () => (
    <h2 className={classes.emptyMessage}>No hay registros de brokers.</h2>
  );

  return (
    <div>
      <div style={{ marginRight: '2rem' }}>
        <Searcher
          context="brokers"
          brokersForSearch={brokersForSearch}
          getBrokers={getBrokers}
          setBrokers={setBrokers}
        />
      </div>
      {!brokers.length && !loading
        ? renderEmptyMessage()
        : renderBrokersTable()}
    </div>
  );
};

export default BrokersTable;
