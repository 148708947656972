import { useEffect, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import AnomaliesTable from '../../components/Anomalies/AnomaliesTable';
import {
  AnomaliesContainer,
  AnomaliesTitle,
  AnomaliesTableContainer,
  RightButtonsContainer,
  LeftButtonsContainer,
  ButtonsContainer,
  BtnDatePickerActive,
} from './anomaliesStyles';
import useAnomalies from './useAnomalies';
import { CSVLink } from 'react-csv';
import ButtonOutline from '../../components/Button/ButtonOutline';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import theme from '../../theme';
import { useNavigate } from 'react-router-dom';

const Anomalies = ({ notificationClicked }) => {
  const {
    anomalies,
    getAnomalies,
    loading,
    totalPages,
    page,
    setPage,
    setAnomalies,
    setChangeAmount,
    changeAmount,
    save,
    setSave,
    updateMountAnomaly,
    payOffAnomaly,
    disableAnomaly,
    SearchAnomalies,
    setSearch,
    search,
    anomaliesCsv,
    getAnomaliesForCsv,
  } = useAnomalies();
  const [date, setDate] = useState();
  const navigate = useNavigate();

  const handleDateChange = (e) => {
    setDate(e);
    getAnomalies(search, page, e);
  };

  const popperSx = {
    '& .MuiPickersDay-today': {
      backgroundColor: '#F25456 !important',
      color: 'white',
      transition: 'none !important',
      border: '1px solid #F25456',
    },

    '& .Mui-selected': {
      backgroundColor: '#F25456 !important',
      transition: 'none',
      color: 'white',

      '&:hover': {
        backgroundColor: '#F25456 !important',
        color: 'white',
      },

      '&:focus': {
        backgroundColor: '#F25456 !important',
        color: 'white',
      },

      '&:active': {
        backgroundColor: '#F25456 !important',
        color: 'white',
      },
    },
  };

  useEffect(() => {
    getAnomalies(search, page, date);
  }, []);

  useEffect(() => {
    getAnomaliesForCsv();
  }, []);

  useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate(`/operaciones/${notificationClicked.id}`);
    }
  }, [notificationClicked.isClicked]);

  return (
    <AnomaliesContainer>
      <AnomaliesTitle>Anomalías</AnomaliesTitle>
      <ButtonsContainer>
        <LeftButtonsContainer>
          {date ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={date}
                OpenPickerButtonProps={{
                  style: {
                    color: theme.palette.title.main,
                  },
                }}
                disableMaskedInput={true}
                disableFuture={true}
                onChange={(newValue) => {
                  handleDateChange(
                    new Date(newValue.$d).toISOString().split('T')[0],
                  );
                }}
                PopperProps={{
                  sx: popperSx,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: `65%`,
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '10px',
                        border: `1.5px solid ${theme.palette.title.main}`,
                        height: '40px',
                        margin: 0,
                        color: theme.palette.title.main,
                        fontWeight: '500',
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          ) : (
            <BtnDatePickerActive
              onClick={() => {
                setDate(new Date().toISOString().split('T')[0]);
                getAnomalies(search, 1, new Date().toISOString().split('T')[0]);
              }}
            >
              FILTRAR POR FECHA
            </BtnDatePickerActive>
          )}
          {date !== undefined ? (
            <BtnDatePickerActive
              onClick={() => {
                setDate(undefined);
                getAnomalies(search, page, undefined);
              }}
              sx={{
                color: `${theme.palette.icons.main}`,
                ':hover': { cursor: 'pointer' },
                fontSize: '2rem',
                margin: '1rem',
              }}
            >
              TODAS LAS FECHAS
            </BtnDatePickerActive>
          ) : (
            false
          )}
        </LeftButtonsContainer>
        <RightButtonsContainer>
          <CSVLink
            data={anomaliesCsv}
            filename="Anomalias.csv"
            target="_blank"
            style={{ textDecoration: 'none' }}
            separator={';'}
          >
            <ButtonOutline icon="csv" title="EXPORTAR CSV" />
          </CSVLink>
        </RightButtonsContainer>
      </ButtonsContainer>

      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '150px',
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <AnomaliesTableContainer>
            <AnomaliesTable
              anomalies={anomalies}
              setChangeAmount={setChangeAmount}
              changeAmount={changeAmount}
              save={save}
              setSave={setSave}
              setAnomalies={setAnomalies}
              updateMountAnomaly={updateMountAnomaly}
              payOffAnomaly={payOffAnomaly}
              disableAnomaly={disableAnomaly}
              page={page}
              setPage={setPage}
              SearchAnomalies={SearchAnomalies}
              getAnomalies={getAnomalies}
              setSearch={setSearch}
              search={search}
              loading={loading}
              date={date}
              setDate={setDate}
              totalPages={totalPages}
            />
          </AnomaliesTableContainer>
        </>
      )}
    </AnomaliesContainer>
  );
};

export default Anomalies;
