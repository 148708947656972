import { useState } from 'react';
import fetchFromApi from '../../utils/fetchFromApi';
import useNotistack from '../Notistack/useNotistack';
import { useNavigate } from 'react-router-dom';

const useBrokers = () => {
  const [brokers, setBrokers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingButtons, setLoadingButtons] = useState(false);
  const [intermediaryInfo, setIntermediaryInfo] = useState([]);
  const [noIntermediaryInfo, setNoIntermediaryInfo] = useState([]);

  const { showNotification } = useNotistack();
  const [brokerInfo, setBrokerInfo] = useState([]);
  const [brokersForSearch, setBrokersForSearch] = useState();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [search, setSearch] = useState();
  const [switcher, setSwitcher] = useState(false);
  const [brokersForCsv, setBrokersForCsv] = useState([]);
  const [commissions, setCommissions] = useState([]);
  const [brokerInfoWcommissionsFiltered, setBrokerInfoWcommissionsFiltered] =
    useState();
  const [commissionSearch, setCommissionSearch] = useState();
  const [isPaying, setIsPaying] = useState(false);
  const [isAccreditting, setIsAccreditting] = useState(false);

  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/comisionistas');
  };

  const getBrokersPaginate = async (search, page) => {
    setLoading(true);
    try {
      let result = await fetchFromApi(
        'GET',
        `broker/paginate/${page}?search=${search}`,
      );

      if (!result.docs.length && page !== 1) {
        result = await fetchFromApi(
          'GET',
          `broker/paginate/${page - 1}?search=${search}`,
        );
      }
      setBrokers(result.docs);
      setTotalPages(result.totalPages);
      setPage(result.page);
      setLoading(false);
    } catch (error) {
      showNotification('Error al obtener los brokers.', 'error');
    }
    setLoading(false);
  };

  const getBrokers = async (search) => {
    setLoading(true);
    try {
      let result = await fetchFromApi('GET', `broker/?search=${search}`);
      setBrokersForSearch(result);
      setBrokers(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showNotification('Error al obtener los brokers.', 'error');
    }
  };

  const getBrokersForCsv = async () => {
    try {
      const response = await fetchFromApi('GET', `broker/csv`);
      setBrokersForCsv(response);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteBroker = async (id, search, page) => {
    setLoading(true);
    try {
      const result = await fetchFromApi('PUT', `broker/disable/${id}`);
      if (result) {
        showNotification(result, 'success');
      }

      getBrokersPaginate(search, page);
    } catch (error) {
      showNotification('Broker no eliminado.', 'error');
    }
  };

  const getBrokerById = async (id, search, switcher, context) => {
    setLoading(true);
    try {
      const response = await fetchFromApi(
        'GET',
        `broker/${id}?search=${search}&toggle=${switcher}&context=${context}`,
      );
      if (context === 'commissions') {
        setBrokerInfo(response[0]); // Para los totalAmounts
        setBrokerInfoWcommissionsFiltered(response[1]); // Esto es lo que se mostrara al buscar
        setLoading(false);
      } else {
        setBrokerInfo(response); // Para los totalAmounts
        setBrokerInfoWcommissionsFiltered(response); // Esto es lo que se mostrara en un principio
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getSpecificBrokerDetails = async (id, type) => {
    setLoading(true);
    try {
      const response = await fetchFromApi('GET', `broker/${id}`);
      if (type === 'intermediary') {
        setIntermediaryInfo(response);
      } else {
        setNoIntermediaryInfo(response);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const registerBroker = async (brokerData, setErrors) => {
    setLoadingButtons(true);
    try {
      const response = await fetchFromApi('POST', 'broker', brokerData);
      if (Array.isArray(response) && response[0]) {
        showNotification(response[1], 'success');
        if (setErrors) setErrors([]);
        await getBrokers();
        return response[0];
      } else {
        throw new Error('La respuesta no tiene el formato esperado');
      }
    } catch (error) {
      if (setErrors) setErrors(error.response?.data || error.message);
    } finally {
      setLoadingButtons(false);
    }
  };

  const updateBroker = async (brokerData, setErrors, id) => {
    setLoadingButtons(true);

    try {
      const response = await fetchFromApi('PUT', `broker/${id}`, brokerData);
      showNotification(response[1], 'success');
      setErrors([]);
      handleRedirect();
    } catch (error) {
      setErrors(error.response.data);
      setLoadingButtons(false);
    }
  };

  const deleteCommission = async (id) => {
    try {
      const response = await fetchFromApi('PUT', `commission/disable/${id}`);
      showNotification(response[1], 'success');
    } catch (error) {
      console.log(error);
    }
  };

  const payOffCommission = async (id, stockId, amount) => {
    setIsPaying(true);
    try {
      const response = await fetchFromApi('PUT', `commission/payoff/${id}`, {
        stockId,
        amount,
      });
      showNotification(response[1], 'success');
      setIsPaying(false);
    } catch (error) {
      console.log(error);
      setIsPaying(false);
    }
  };

  const creditCurrentAccount = async (id, amount) => {
    setIsAccreditting(true);
    try {
      const response = await fetchFromApi('POST', `commission/credit/${id}`, {
        amount,
      });
      showNotification(response, 'success');
      setIsAccreditting(false);
    } catch (error) {
      console.log(error);
      setIsAccreditting(false);
    }
  };

  const payOffAllCommissions = async (id) => {
    try {
      const response = await fetchFromApi('PUT', `commission/payoffall/${id}`);
      showNotification(response[1], 'success');
    } catch (error) {
      console.log(error);
    }
  };

  const getCommissions = async () => {
    try {
      let response = await fetchFromApi('GET', `commission/commissions`);
      if (response) {
        setCommissions(response);
      }
    } catch (error) {
      showNotification('Error al obtener las comisiones.', 'error');
    }
  };

  return {
    getBrokersPaginate,
    brokers,
    deleteBroker,
    updateBroker,
    loading,
    getBrokerById,
    brokerInfo,
    registerBroker,
    setBrokers,
    loadingButtons,
    setLoadingButtons,
    open,
    setOpen,
    totalPages,
    setPage,
    page,
    setSearch,
    search,
    getBrokers,
    brokersForSearch,
    deleteCommission,
    payOffCommission,
    payOffAllCommissions,
    brokerInfoWcommissionsFiltered,
    setCommissionSearch,
    commissionSearch,
    switcher,
    setSwitcher,
    getBrokersForCsv,
    brokersForCsv,
    getCommissions,
    commissions,
    getSpecificBrokerDetails,
    intermediaryInfo,
    noIntermediaryInfo,
    creditCurrentAccount,
    isPaying,
    isAccreditting,
  };
};

export default useBrokers;
