const contextMap = {
  anomalies: {
    title: 'Desactivar anomalía',
    message: '¿Está seguro que desea desactivar esta anomalía?',
    action: 'DESACTIVAR',
  },
  clients: {
    title: 'Eliminar cliente',
    message: '¿Está seguro que desea eliminar este cliente?',
    action: 'ELIMINAR',
  },
  operation: {
    title: 'Eliminar operación',
    message: '¿Está seguro que desea eliminar esta operación?',
    action: 'ELIMINAR',
  },
  operationEdit: {
    title: 'Editar operación',
    message:
      'Debes cambiar el estado de la operación a PENDIENTE para poder editarla, si la operación paso por C.DELIVERY quedara posición en el delivery que se debe corregir',
  },
  panic: {
    title: 'Activar botón de pánico',
    message: '¿Está seguro que desea activar el botón de pánico?',
    action: 'ACTIVAR',
  },
  disabled: {
    title: 'Desactivar botón de pánico',
    message: '¿Está seguro que desea desactivar el botón de pánico?',
    action: 'DESACTIVAR',
  },
  stock: {
    title: 'Desactivar divisa',
    message: '¿Está seguro que desea desactivar esta divisa?',
    action: 'DESACTIVAR',
  },
  brokers: {
    title: 'Eliminar comisionista',
    message: '¿Está seguro que desea eliminar a este comisionista?',
    action: 'ELIMINAR',
  },
  commission: {
    title: 'Eliminar comisión',
    message: '¿Está seguro que desea eliminar esta comisión?',
    action: 'ELIMINAR',
  },
  payOffAllCommissions: {
    title: 'Liquidar todas las comisiones',
    message: '¿Está seguro que desea liquidar todas las comisiones?',
    action: 'LIQUIDAR',
  },
};

export default contextMap;
