import React from 'react';
import {
  ActionsContainer,
  OperationsSelectAndDeleteContainer,
} from '../../containers/Operations/operationsStyles';
import SelectMUI from '../../components/Select/SelectMui';
import DeleteIcon from '@mui/icons-material/Delete';
import theme from '../../theme';
import Loader from '../Loader/Loader';
import ClearIcon from '@mui/icons-material/Clear';

const RenderSelectAndActions = ({
  handleMultipleDelete,
  handleChangeSelect,
  selectOperationsOptions,
  selected,
  isLoading,
  setSelected,
  setChecked,
}) => {
  return (
    <OperationsSelectAndDeleteContainer>
      <ActionsContainer onClick={handleMultipleDelete}>
        <DeleteIcon
          sx={{
            color: `${theme.palette.icons.main}`,
            ':hover': { cursor: 'pointer' },
          }}
        />
      </ActionsContainer>
      <SelectMUI
        options={selectOperationsOptions}
        onChangeFunction={(e, newValue) => {
          handleChangeSelect(newValue);
        }}
        context="operation"
      />
      <span>{selected.length} operaciones seleccionadas</span>
      {isLoading && <Loader style={{ marginTop: '0px' }} />}
      <ClearIcon
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          setSelected([]);
          setChecked(false);
        }}
      />
    </OperationsSelectAndDeleteContainer>
  );
};

export default RenderSelectAndActions;
