import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
  ClientsContainer,
  Title,
  ClientTableContainer,
  ButtonsContainer,
  LeftButtonsContainer,
  RightButtonsContainer,
  Btn,
} from './clientStyles';
import useClients from './useClients';
import ButtonOutline from '../../components/Button/ButtonOutline';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';

import ClientsTable from '../../components/Clients/ClientsTable';

const Clients = ({ notificationClicked }) => {
  const navigate = useNavigate();

  let {
    getClientsPaginate,
    clients,
    deleteClient,
    loading,
    page,
    setPage,
    totalPages,
    search,
    setSearch,
    clientsCsv,
    getClientsForCsv,
    importCsv,
  } = useClients();

  const handleCsvUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event) => {
      const csv = event.target.result;
      importCsv(csv);
    };
  };

  useEffect(() => {
    getClientsPaginate(search, page);
    getClientsForCsv();
  }, []);

  useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate(`/operaciones/${notificationClicked.id}`);
    }
  }, [notificationClicked.isClicked]);

  return (
    <ClientsContainer>
      <Title>Clientes</Title>
      <ButtonsContainer>
        <LeftButtonsContainer>
          <div style={{ marginTop: '0px' }}>
            <Btn onClick={() => navigate('/agregar-clientes')}>
              NUEVO CLIENTE
            </Btn>
            <input
              type="file"
              id="csv-input"
              accept=".csv, application/vnd.ms-excel"
              onChange={handleCsvUpload}
              style={{ display: 'none' }}
            />
            <label htmlFor="csv-input">
              <Btn
                as="span"
                style={{
                  fontSize: '13.5px',
                }}
              >
                IMPORTAR CSV
              </Btn>
            </label>
          </div>
        </LeftButtonsContainer>
        <RightButtonsContainer>
          <CSVLink
            data={clientsCsv}
            filename="Clientes.csv"
            target="_blank"
            separator={','}
            style={{ textDecoration: 'none' }}
            enclosingCharacter={`"`}
          >
            <ButtonOutline icon="csv" title="EXPORTAR CSV" />
          </CSVLink>
        </RightButtonsContainer>
      </ButtonsContainer>

      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '150px',
          }}
        >
          <Loader />
        </div>
      ) : (
        <ClientTableContainer>
          <ClientsTable
            clients={clients}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            search={search}
            setSearch={setSearch}
            loading={loading}
            getClientsPaginate={getClientsPaginate}
            deleteClient={deleteClient}
          ></ClientsTable>
        </ClientTableContainer>
      )}
    </ClientsContainer>
  );
};

export default Clients;
