import {
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
} from '@mui/material';
import { useEffect } from 'react';
import useAuth from '../../Login/useAuth';
import theme from '../../../theme';
import useUsers from '../../Team/useUsers';
import formatNumber from '../../../utils/formatNumber';
import {
  CurrenciesContainer,
  CurrenciesInputContainer,
  CurrencyContainer,
  ExchangeRateContainer,
} from '../operationsStyles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export const CurrenciesInputDetail = ({ stock, operation, errors }) => {
  const user = useAuth();
  const { getProfile, profile } = useUsers();

  useEffect(() => {
    if (user) {
      getProfile(user?.id);
    }
  }, []);

  const findStock = (stock, currency) => {
    const stockFound = stock?.find((item) => item._id === currency);
    return stockFound?.currency;
  };

  const outputCurrency = operation?.output_currency?._id;

  return (
    <CurrenciesInputContainer>
      <CurrenciesContainer>
        <AddIcon
          sx={{
            color: 'green',
            margin: '0',
            padding: '0',
            position: 'relative',
            right: '50px',
            top: '75px',
            fontSize: '20px',
          }}
        />
        <CurrencyContainer>
          {/* {DIVISA DE ENTRADA} */}
          <FormControl sx={{ width: '30%' }} error={errors.input_currency}>
            <InputLabel
              sx={{
                color: `${theme.palette.inputText.main}`,
                marginLeft: '20px',
              }}
              id="input"
              shrink={false}
            >
              {findStock(stock, operation?.input_currency)}
            </InputLabel>

            <Select
              disabled={true}
              labelId="input"
              name="input_currency"
              value={operation?.input_currency}
              sx={{
                borderRadius: '14px',
                backgroundColor: '#F0F2F5',
                height: '49.5px',
                border: 'none',
                cursor: 'pointer',
              }}
            />
          </FormControl>
          {/* MONTO DE ENTRADA */}
          {operation?.input_amount && (
            <TextField
              variant="filled"
              value={
                operation?.input_amount
                  ? formatNumber(operation?.input_amount)
                  : ''
              }
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                style: {
                  color: `black`,
                  height: '46px',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                  paddingBottom: '12px',
                },
              }}
              error={errors.input_amount}
              disabled={true}
              placeholder="Monto de entrada"
            />
          )}
        </CurrencyContainer>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ExchangeRateContainer>
            {/* TIPO DE CAMBIO */}

            <InputLabel
              sx={{
                marginRight: '10px',
              }}
            >
              TC
            </InputLabel>
            {operation?.exchange_rate && (
              <TextField
                error={errors.exchange_rate}
                disabled={true}
                placeholder="Tipo de cambio"
                value={
                  operation?.exchange_rate
                    ? formatNumber(operation?.exchange_rate)
                    : ''
                }
                InputLabelProps={{
                  style: {
                    color: `${theme.palette.inputText.main}`,
                  },
                }}
                InputProps={{
                  style: {
                    height: '50px',
                    borderRadius: '10px',
                    marginRight: '10px',
                  },
                }}
              />
            )}

            <TextField
              name="mathematicalOperation"
              placeholder="Calcular"
              value={operation?.mathematicalOperation}
              disabled={true}
              InputLabelProps={{
                style: {
                  color: `${theme.palette.inputText.main}`,
                },
              }}
              InputProps={{
                style: {
                  height: '50px',
                  borderRadius: '10px',
                  marginRight: '10px',
                },
              }}
            />
            <InputLabel
              sx={{
                marginRight: '10px',
              }}
            >
              CÁLCULO
            </InputLabel>
          </ExchangeRateContainer>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: `${theme.palette.inputText.main}`,
          }}
        >
          {operation?.calculatedExchangeRate > 0 && (
            <h4>TC Calculado: {operation?.calculatedExchangeRate}</h4>
          )}
        </div>

        <RemoveIcon
          sx={{
            color: 'red',
            margin: '0',
            padding: '0',
            position: 'relative',
            right: '50px',
            top: '75px',
            fontSize: '20px',
          }}
        />
        <CurrencyContainer>
          {/* {DIVISA DE SALIDA} */}
          <FormControl sx={{ width: '30%' }} error={errors.output_currency}>
            <InputLabel
              sx={{
                color: `${theme.palette.inputText.main}`,
                marginLeft: '20px',
              }}
              id="output"
              shrink={false}
            >
              {findStock(stock, outputCurrency)}
            </InputLabel>

            <Select
              disabled={true}
              labelId="output"
              name="input_currency"
              value={operation?.output_currency}
              sx={{
                borderRadius: '14px',
                backgroundColor: '#F0F2F5',
                height: '49.5px',
                border: 'none',
                cursor: 'pointer',
              }}
            />
          </FormControl>

          {/* MONTO DE SALIDA */}
          {operation?.output_amount && (
            <TextField
              variant="filled"
              value={
                operation?.output_amount
                  ? formatNumber(operation?.output_amount)
                  : ''
              }
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                style: {
                  color: `black`,
                  height: '46px',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                  paddingBottom: '12px',
                },
              }}
              error={errors.output_amount}
              disabled={true}
              placeholder="Monto de salida"
            />
          )}
        </CurrencyContainer>
      </CurrenciesContainer>
    </CurrenciesInputContainer>
  );
};
