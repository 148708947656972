import React, { useEffect } from 'react';
import formatNumber from '../../utils/formatNumber';
import { DataGrid } from '@mui/x-data-grid';
import SelectMUI from '../../components/Select/SelectMui';
import { selectOperationsOptions } from '../../utils/constants';

const columns = [
  { field: 'id' },
  { field: 'index', headerName: 'ID', width: 20 },
  { field: 'client', headerName: 'Cliente', width: 130 },
  { field: 'inputCurrency', headerName: 'Divisa de entrada', width: 70 },
  {
    field: 'inputAmount',
    headerName: 'Monto de entrada',
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => formatNumber(value),
  },
  {
    field: 'outputCurrency',
    headerName: 'Divisa de salida',
    type: 'number',
    width: 70,
  },
  {
    field: 'outputAmount',
    headerName: 'Monto de salida',
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => formatNumber(value),
  },
  {
    field: 'operationType',
    headerName: 'Tipo de operacion',
    type: 'number',
    width: 130,
  },
  {
    field: 'isSubOp',
    headerName: 'is subOp',
  },
  {
    field: 'operationId',
    headerName: 'opId',
  },
];

const DeliveryTable = ({
  rowSelectionModel,
  setRowSelectionModel,
  setSelectValue,
  rows,
  deliveryOperations,
  isDisabled,
  setIsDisabled,
}) => {
  useEffect(() => {
    if (
      rowSelectionModel.length > 0 &&
      rowSelectionModel !== undefined &&
      rowSelectionModel !== null
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [rowSelectionModel]);

  return (
    <>
      {' '}
      {isDisabled ? null : (
        <SelectMUI
          key={selectOperationsOptions}
          options={selectOperationsOptions}
          onChangeFunction={(e, newValue) => {
            setSelectValue(newValue);
          }}
          context="operation"
        />
      )}{' '}
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          columns: {
            columnVisibilityModel: {
              id: false,
              isSubOp: false,
              operationId: false,
            },
          },
        }}
        pageSizeOptions={[5, 10, 20]}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
      />
    </>
  );
};

export default DeliveryTable;
