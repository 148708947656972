import React from 'react';
import fetchFromApi from '../../utils/fetchFromApi';

const useHistory = () => {
  const [history, setHistory] = React.useState([]);
  const [opening, setOpening] = React.useState([]);
  const [closing, setClosing] = React.useState([]);

  const getHistoryByDate = async (date) => {
    try {
      const response = await fetchFromApi('GET', `stock-history/${date}`);

      if (response) {
        console.log(response);
        setHistory(response.message[0].stock);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    getHistoryByDate,
    history,
    setHistory,
    opening,
    closing,
  };
};

export default useHistory;
