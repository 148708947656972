import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
import { display } from '@mui/system';
import React from 'react';
import theme from '../../theme';
import { operationTableTitles } from '../../utils/constants';

const OperationHead = ({ handleSelectAllOperations, checked, user }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        {operationTableTitles.map((t) => (
          <TableCell
            key={t.name}
            sx={{
              fontWeight: '500',
              color: `${theme.palette.tableTitle.main}`,
              width: `${t.width}`,
              fontSize: '14px',
              paddingLeft: `${t.paddingLeft}`,
              paddingRight: `${t.paddingRight}`,
              whiteSpace: 'nowrap',
            }}
            align="left"
          >
            {t.name === 'checkbox' ? (
              <Checkbox
                disabled={user?.role === 'liquidador_caja'}
                sx={{
                  color: 'gray',
                  '&.Mui-checked': {
                    color: '#384764',
                  },
                }}
                checked={checked}
                onChange={handleSelectAllOperations}
              />
            ) : (
              t.name
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default OperationHead;
