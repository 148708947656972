import React, { useState } from 'react';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import theme from '../../theme';
import { useNavigate } from 'react-router-dom';
import formatNumber from '../../utils/formatNumber';
import AlertDialog from '../Dialog/AlertDialog';
import { IconSpan } from '../../containers/Operations/operationsStyles';
import CustomModal from '../CustomModal/CustomModal';
import ModalContentComponent from './ModalContentComponent';

const Stock = ({
  id,
  currency,
  exchange_rate,
  initial_balance,
  actual_balance,
  checkToday,
  getTotalAnomalieValue,
  desativateCurrency,
  stock,
  icon,
  totalCommissions,
  currentAccountsTotal,
  handleCheckModal,
  isOpen,
  setIsOpen,
  selectedCurrency,
  handleChangeDifference,
  difference,
  customHandleClosure,
  currencies,
  setCurrencies,
}) => {
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);

  const cellStyle = {
    color: theme.palette.tableText.main,
    fontWeight: '500',
    width: '250px',
  };

  const iconStyle = {
    color: theme.palette.icons.main,
    ':hover': { cursor: 'pointer' },
  };

  const totalCommissionString = totalCommissions();
  const totalCommissionNumber = parseFloat(totalCommissionString);
  const adjustedActualBalance =
    currency === 'USD'
      ? actual_balance - totalCommissionNumber
      : actual_balance;

  const exchangeValue = () => {
    if (['ARS', 'BRL'].includes(currency)) {
      return actual_balance / exchange_rate;
    } else if (
      ['EURO CHICO', 'EURO GRANDE', 'LIBRAS', 'USDT'].includes(currency)
    ) {
      return actual_balance * exchange_rate;
    }
    return null;
  };

  const thereIsDifference = () => {
    return formatNumber(selectedCurrency?.actual_balance - difference);
  };

  const actualBalanceMinusDifference = difference
    ? selectedCurrency?.actual_balance - difference
    : 0;

  const isClosedDb = currencies?.[currency]?.isClosed;
  const differenceDb = currencies?.[currency]?.difference;

  return (
    <>
      <TableRow>
        <TableCell sx={{ ...cellStyle, paddingLeft: '25px' }}>
          {icon && <IconSpan>{icon}</IconSpan>} {currency}
        </TableCell>
        <TableCell align="left" sx={cellStyle}>
          ${formatNumber(exchange_rate)}
        </TableCell>
        {/* <TableCell align="left" sx={cellStyle}>
          ${formatNumber(initial_balance)}
        </TableCell> */}
        <TableCell align="left" sx={cellStyle}>
          ${formatNumber(actual_balance)}
          {currency !== 'USD' && exchangeValue() !== null && (
            <small style={{ color: 'red' }}>
              {` ${formatNumber(exchangeValue())}`}
            </small>
          )}
        </TableCell>
        <TableCell align="left" sx={cellStyle}>
          {currency === 'USD' ? `$${formatNumber(totalCommissionNumber)}` : ''}
        </TableCell>
        <TableCell align="left" sx={cellStyle}>
          {currentAccountsTotal[currency]
            ? `$${formatNumber(currentAccountsTotal[currency])}`
            : '$0'}
        </TableCell>
        <TableCell align="left" sx={cellStyle}>
          ${formatNumber(getTotalAnomalieValue(stock))}
        </TableCell>
        <TableCell align="left" sx={cellStyle}>
          ${formatNumber(adjustedActualBalance + getTotalAnomalieValue(stock))}
        </TableCell>
        <TableCell align="center">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginRight: checkToday() ? '50px' : undefined,
              gap: '25px',
              alignItems: 'center',
            }}
          >
            {checkToday() && (
              <>
                <Checkbox
                  checked={isClosedDb}
                  onClick={() => handleCheckModal(stock)}
                  sx={{
                    background:
                      isClosedDb && differenceDb > 0 ? 'red' : 'green',
                    color: 'white',
                  }}
                />

                <EditIcon
                  sx={iconStyle}
                  onClick={() => navigate(`/editar-divisa/${id}`)}
                />
                <DeleteIcon sx={iconStyle} onClick={() => setOpenAlert(true)} />
                <AlertDialog
                  onClose={() => setOpenAlert(false)}
                  open={openAlert}
                  handlerAction={() => desativateCurrency(id)}
                  context="stock"
                />
              </>
            )}
          </div>
        </TableCell>
      </TableRow>
      <CustomModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <ModalContentComponent
          selectedCurrency={selectedCurrency}
          handleChangeDifference={handleChangeDifference}
          difference={difference}
          thereIsDifference={thereIsDifference}
          actualBalanceMinusDifference={actualBalanceMinusDifference}
          customHandleClosure={customHandleClosure}
          setIsOpen={setIsOpen}
          currencies={currencies}
          setCurrencies={setCurrencies}
        />
      </CustomModal>
    </>
  );
};

export default Stock;
