import styled from 'styled-components';
import theme from '../../theme';
import Box from '@mui/material/Box';
import { Modal } from '@mui/material';

export const LeftButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 30px;
  height: 40px;
  align-items: center;
`;

export const RightButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 30px;
  height: 40px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-content: center;
`;

export const StockContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: 260px;
  margin-top: 100px;
`;

export const StockTitle = styled.h1`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${theme.palette.title.main};
  margin: 0;
  padding: 0 30px;
  margin-bottom: 20px;
  position: relative;
  bottom: 20px;
`;

export const StockTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0px;
  padding-top: 20px;
  border-radius: 10px;
  background-color: #fff;
  margin: 0 auto;
  width: 96.5%;
  margin-top: 20px;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
`;

export const TotalBalance = styled.h2`
  font-size: 1rem;
  color: white;
  text-align: center;
`;

export const TotalContainer = styled.div`
  background: #d43436; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
`;

export const TotalContainerOutline = styled.div`
  background: none;
  width: auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #f35658;
  padding: 10px;
`;

export const TotalBalanceOutline = styled.h2`
  font-size: 1rem;
  color: #f35658;
  text-align: center;
`;

export const InputDate = styled.input`
  border: 1px solid ${theme.palette.title.main};
  color: ${theme.palette.title.main};
  padding: 0px 20px;
  border-radius: 10px;
  background: none;
`;

export const Select = styled.select`
  border: 1.5px solid ${theme.palette.title.main};
  color: ${theme.palette.title.main};
  border-radius: 10px;
  padding: 10px 20px;
  margin-left: 10px;
  background: none;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
`;

export const Option = styled.option`
  font-family: 'Roboto', sans-serif !important;
  background-color: white;
  color: #384764;
  font-weight: 500;

  &:hover {
    background-color: red;
  }
  &:active {
    background-color: red;
  }
  &:selected {
    background-color: red;
  }
`;

export const BoxMui = styled(Box)`
  background-color: #fff;
  border-radius: 10px;
  padding: 70px;
  width: 80%;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
`;

export const BoxTitle = styled.div`
  background: #d43436; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 77%;
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  position: relative;
  top: 50px;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

export const AddStockContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 48px 0px 0px 200px;
`;

export const BreadcumsContainer = styled.div`
  margin-right: 77%;
  margin-bottom: 40px;
`;

export const ModalContent = styled.div`
  background-color: white;
  width: 50%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
`;

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const YourActualBalance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const RecordDifference = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

export const CloseCurrency = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`;

export const ActualBalance = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 50px;
  border: 1px solid #f2e2f5;
  border-radius: 5px;
`;

export const DifferenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const DifferenceSpan = styled.span`
  font-size: 14px;
`;
