import { Box } from '@mui/material';
import styled from 'styled-components';
import theme from '../../theme';

export const OperationsContainer = styled.div`
  margin-bottom: 10px;
  width: 100%;
  padding-right: 20px;
  padding-left: 40px;
`;

export const Operation = styled.div`
  background-color: aliceblue;
  text-align: left;
  padding: 30px;
  border-radius: 5px;
`;

export const FilterBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OperationsTitle = styled.h1`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${theme.palette.title.main};
  margin: 0;
  margin-bottom: 20px;
  position: relative;
  bottom: 20px;
  margin-left: 1rem;
`;

export const TransferCurrentAccountFormContainer = styled.div`
  display: flex;
`;

export const OperationsButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 40px;
`;

export const OperationsSelectAndDeleteContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  gap: 10px;
  height: 40px;
`;

export const PopopverButtonsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 20px;
  align-items: center;
  justify-content: center;
`;

export const AddOperationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;
`;

export const OperationFormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopOperationFormContainer = styled.div`
  display: flex;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
`;

export const BottomOperationFormContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const SubOperationFormContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 30px;
  margin: 15px 0px;
  align-items: center;
`;

export const SubOperationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

export const BoxMui = styled(Box)`
  background-color: #fff;
  border-radius: 10px;
  padding: 70px;
  width: 80%;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
`;

export const BoxTitle = styled.div`
  background: #d43436; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 77%;
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  position: relative;
  top: 50px;
`;

export const BreadcumsContainer = styled.div`
  margin-right: 77%;
  margin-bottom: 20px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px 0px;
  margin-right: 30px;
`;

export const CurrenciesInputContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
`;

export const CurrenciesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
`;

export const CurrencyContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid #d3d6da;
  border-radius: 14px;
  height: 50px;
  align-items: center;
`;

export const ExchangeRateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ClientBalanceContainer = styled.div`
  margin: 20px 0px;
`;

export const AllOperationsButton = styled.button`
  border: none;
  cursor: pointer;
  height: 50%;
  font-size: 15px;
  margin-left: 20px;
  color: #f76060;
  background-color: #ffffff;
  padding: 10px;
  &:hover {
    background-color: #f0b4b1;
    border-radius: 5px;
    color: white;
  }
`;

export const CotizationContainer = styled.div`
  display: flex;
  gap: 20px;
  border: 1px solid black;
  padding: 15px;
  margin: 10px 0px;
  flex-direction: column;
  border-radius: 5px;
`;

export const BtnPayCotization = styled.button`
  background: #d43436; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif !important;
  box-shadow: 0px 3px 3px #b3abab;
  transition: 0.3s;
  &:hover {
    box-shadow: none;
  }
  align-text: center;
  height: 35px;
  margin-left: 20px;
`;

export const MathematicalResult = styled.h5`
  color: ${theme.palette.tableTitle.main};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
`;

export const CorredorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 40px;
`;

export const CorredorTitle = styled.span`
  color: #f35658;
  margin-right: 10px;
  font-weight: bold;
  font-size: 1rem;
`;

export const CorredorLine = styled.hr`
  flex-grow: 1;
  border: none;
  border-top: 2px solid #f35658;
  margin: 0;
`;

export const SuboperationContainerInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
`;

export const IconSpan = styled.span`
  font-size: 15px;
  color: black;
  font-weight: bold;
`;

export const FormLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #d3d6da;
  border-radius: 10px;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
`;

export const StyledSpan = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FilterAndSearcherContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-items: center;
  gap: 15px;
  margin: 25px 0px;
`;

export const OperationTableContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionsContainer = styled.div`
  background-color: #f7f7f5;
  border-radius: 50px;
  padding: 12px 13px 6px 13px;
  cursor: pointer;
`;

export const DoubleOperationTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

export const TableTitle = styled.span`
  font-weight: 600;
  color: ${theme.palette.title.main};
  margin: 0;
  font-size: 14px;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
`;
