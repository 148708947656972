import React, { useEffect, useState } from 'react';
import { Collapse, TableBody, TableCell, TableRow } from '@mui/material';
import CollapseTable from './CollapseTable';
import SubOpsCollapseTable from './SubOpsCollapseTable';
import OperationRow from './OperationRow';

const Operation = ({
  operation,
  deleteOperation,
  handleSelectOperation,
  selected,
  filters,
  page,
  getOperations,
  checkOperation,
  user,
  isDisabled,
}) => {
  const [isEditable, setIsEditable] = useState(true);
  const {
    _id,
    input_currency,
    output_currency,
    anomalie,
    comments,
    status,
    assignedDelivery,
    createdAt,
    exchange_rate,
    liquidator,
    exchangeRateType,
    marketPrice,
    mathematicalOperation,
    calculatedExchangeRate,
    checkedByCaja,
    confirmedBy,
  } = operation;

  const [open, setOpen] = React.useState(false);
  const [liquidatorValue, setLiquidatorValue] = useState(
    liquidator?.name ? liquidator : undefined,
  );

  let inputAnomalie = anomalie.filter((a) => a.type === 'input');
  let outputAnomalie = anomalie.filter((a) => a.type === 'output');

  const isEditableFunction = (value) => {
    if (value === 'Pendiente') {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
  };

  useEffect(() => {
    isEditableFunction(status);
  }, [operation]);

  return (
    <TableBody>
      <OperationRow
        operation={operation}
        getOperations={getOperations}
        filters={filters}
        page={page}
        handleSelectOperation={handleSelectOperation}
        selected={selected}
        deleteOperation={deleteOperation}
        isEditable={isEditable}
        open={open}
        setOpen={setOpen}
        setLiquidatorValue={setLiquidatorValue}
        setIsEditable={setIsEditable}
        checkOperation={checkOperation}
        user={user}
        isDisabled={isDisabled}
      />

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {operation.subOperations?.length <= 0 ? (
              <CollapseTable
                key={createdAt}
                commission={marketPrice}
                inputAnomalie={inputAnomalie}
                outputAnomalie={outputAnomalie}
                assignedDelivery={assignedDelivery}
                comments={comments}
                created={createdAt}
                input_currency={input_currency?.currency?.toUpperCase()}
                output_currency={output_currency?.currency?.toUpperCase()}
                exchange_rate={exchange_rate}
                liquidatorValue={liquidatorValue}
                exchangeRateType={exchangeRateType}
                mathematicalOperation={mathematicalOperation}
                calculatedExchangeRate={calculatedExchangeRate}
                checkedByCaja={checkedByCaja}
                confirmedBy={confirmedBy}
              />
            ) : (
              operation.subOperations.map((subOp, index) => (
                <SubOpsCollapseTable
                  key={createdAt}
                  subOp={subOp}
                  opId={_id}
                  index={index}
                  input_currency={input_currency?.currency.toUpperCase()}
                  output_currency={output_currency?.currency.toUpperCase()}
                  filters={filters}
                  page={page}
                  getOperations={getOperations}
                />
              ))
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default Operation;
