import React, { useEffect } from 'react';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';
import { HomeContainer } from '../Layout/homeStyles';
import { BreadcumsContainer } from './operationsStyles';
import { useOperations } from './useOperations';
import { useNavigate } from 'react-router-dom';

import CotizationsTable from '../../components/Operation/CotizationsTable';

const PrechargedOperations = ({ notificationClicked }) => {
  const { cotizations, getCotizations, chargeCotization } = useOperations();
  const navigate = useNavigate();

  useEffect(() => {
    getCotizations();
  }, []);

  const handleSubmitCotization = async (e, id) => {
    e.preventDefault();
    await chargeCotization(id, { value: e.target[0].value });
    getCotizations();
  };

  useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate(`/operaciones/${notificationClicked.id}`);
    }
  }, [notificationClicked.isClicked]);

  return (
    <HomeContainer>
      <BreadcumsContainer>
        <BreadcrumbsMui
          title="Solicitud cotizaciones"
          prev="Operaciones"
          path={'/operaciones'}
        />
      </BreadcumsContainer>
      {cotizations.length === 0 ? (
        <h2
          style={{
            textAlign: 'center',
            margin: '80px auto',
            padding: '10px 0px',
            backgroundColor: 'rgb(212, 52, 54)',
            color: 'white',
            width: '50%',
            borderRadius: '10px',
          }}
        >
          No hay solicitudes de cotizacion
        </h2>
      ) : (
        <CotizationsTable
          cotizations={cotizations}
          handleSubmitCotization={handleSubmitCotization}
        />
      )}
    </HomeContainer>
  );
};

export default PrechargedOperations;
