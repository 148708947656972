import React, { useState } from 'react';
import { TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { UserTableCellBody } from '../../containers/Team/userStyles';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../Dialog/AlertDialog';

const UserIndividual = ({ deleteUser, user }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const navigate = useNavigate();

  const handlerDeleteUser = () => {
    deleteUser(user._id);
    setOpenAlert(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const renderUserTableCell = (content) => (
    <UserTableCellBody align="left" width={'300px'}>
      {content}
    </UserTableCellBody>
  );

  return (
    <TableRow>
      {renderUserTableCell(user.name)}
      {renderUserTableCell(user.email)}
      {renderUserTableCell(user.cellphone)}
      {renderUserTableCell(user.role)}
      {renderUserTableCell(formatDate(user.createdAt))}

      <UserTableCellBody align="left" width={'300px'}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '34px',
          }}
        >
          <EditIcon
            onClick={() => navigate(`/profile/${user._id}`)}
            style={{ cursor: 'pointer' }}
          />
          <AlertDialog
            handlerAction={handlerDeleteUser}
            page={1}
            objectId={user._id}
            open={openAlert}
            onClose={() => setOpenAlert(false)}
            context={'users'}
          />
          <DeleteIcon
            onClick={() => setOpenAlert(true)}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </UserTableCellBody>
    </TableRow>
  );
};

export default UserIndividual;
