import React, { useEffect, useState, useRef } from 'react';
import { HomeContainer } from '../Layout/homeStyles';
import { useNavigate, useParams } from 'react-router-dom';
import BrokersTable from '../../components/Brokers/BrokersTable';
import useBrokers from './useBrokers';
import formatNumber from '../../utils/formatNumber';

import {
  BrokersContainer,
  BrokersButtonsContainer,
  BreadcumsContainer,
  TotalInfo,
  BrokerDetailsTableContainer,
  BtnPayOff,
  TotalInfoContent,
  NameContainer,
  ActionsContainer,
  ActionsContent,
  CardsContainer,
} from '../Brokers/brokerStyles';

import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';

import { CSVLink } from 'react-csv';
import ButtonOutline from '../../components/Button/ButtonOutline';
import Searcher from '../../components/Searcher/Searcher';
import BrokersDetailsTable from '../../components/Brokers/BrokersDetailsTable';
import AlertDialog from '../../components/Dialog/AlertDialog';

const BrokerDetails = ({ notificationClicked, setNotificationClicked }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    brokers,
    getBrokersPaginate,
    loading,
    page,
    setPage,
    search,
    brokerInfo,
    deleteBroker,
    payOffAllCommissions,
    getBrokerById,
    setCommissionSearch,
    commissionSearch,
    brokerInfoWcommissionsFiltered,
    switcher,
    setSwitcher,
  } = useBrokers();

  const [totalAmount, setTotalAmount] = useState(0);
  const [stockId, setStockId] = useState();
  const [openAlert, setOpenAlert] = useState(false);

  const getTotalAmount = () => {
    setTotalAmount(0);
    brokerInfo?.commissions?.forEach((c) => {
      setTotalAmount((prevState) => {
        return (prevState += c.amount);
      });
    });

    brokerInfo?.commissions?.forEach((c) => {
      setStockId(c.stock[0]);
    });
  };

  const handlePayOffAllCommissions = async () => {
    try {
      await payOffAllCommissions(id);
      await getBrokerById(id);
      getTotalAmount();
      setOpenAlert(false);
    } catch (error) {
      console.error('Ocurrió un error:', error);
    }
  };

  useEffect(() => {
    getBrokerById(id, undefined, switcher);
  }, []);

  useEffect(() => {
    if (brokerInfo) {
      getTotalAmount();
    }
  }, [brokerInfo]);

  useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate(`/operaciones/${notificationClicked.id}`);
    }
  }, [notificationClicked.isClicked]);

  return (
    <HomeContainer>
      <BrokersContainer>
        <BreadcumsContainer>
          <BreadcrumbsMui
            title={`${brokerInfo?.name}`}
            secondTitle={'Comisionistas'}
            prev="Comisionistas"
            path={'/comisionistas'}
          />
        </BreadcumsContainer>

        <BrokersButtonsContainer>
          <CardsContainer>
            <TotalInfo>
              <TotalInfoContent>
                <NameContainer>
                  <span>{brokerInfo.name}</span>
                </NameContainer>
              </TotalInfoContent>
              <ActionsContainer>
                <ActionsContent>
                  <span>Monto total: {formatNumber(totalAmount)} USD</span>
                  <AlertDialog
                    handlerAction={handlePayOffAllCommissions}
                    page={1}
                    open={openAlert}
                    onClose={() => setOpenAlert(false)}
                    context={'payOffAllCommissions'}
                  />
                  <BtnPayOff
                    style={{ marginLeft: '3rem' }}
                    onClick={() => setOpenAlert(true)}
                    disabled={brokerInfo?.commissions?.length === 0}
                  >
                    LIQUIDAR TODO{' '}
                  </BtnPayOff>
                </ActionsContent>
              </ActionsContainer>
            </TotalInfo>

            <TotalInfo>
              <TotalInfoContent>
                <NameContainer>
                  <span>Cuenta Corriente USD</span>
                </NameContainer>
              </TotalInfoContent>
              <ActionsContent>
                <span
                  style={{
                    padding: '1rem',
                  }}
                >
                  {brokerInfo?.currentAccount
                    ? formatNumber(brokerInfo?.currentAccount[0]?.amount)
                    : '-'}
                </span>
              </ActionsContent>
            </TotalInfo>
          </CardsContainer>

          <CSVLink
            separator={';'}
            data={'o'}
            filename="operations.csv"
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            <ButtonOutline icon="csv" title="EXPORTAR CSV" />
          </CSVLink>
        </BrokersButtonsContainer>
        <BrokerDetailsTableContainer>
          <BrokersDetailsTable
            brokers={brokers}
            loading={loading}
            page={page}
            setPage={setPage}
            getBrokersPaginate={getBrokersPaginate}
            search={search}
            deleteBroker={deleteBroker}
            brokerInfo={brokerInfo}
            getBrokerById={getBrokerById}
            getTotalAmount={getTotalAmount}
            setCommissionSearch={setCommissionSearch}
            commissionSearch={commissionSearch}
            brokerInfoWcommissionsFiltered={brokerInfoWcommissionsFiltered}
            switcher={switcher}
            setSwitcher={setSwitcher}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  justifyItems: 'center',
                  gap: '15px',
                  margin: '25px 0px',
                }}
              >
                <Searcher context={'brokers'} setPage={setPage} page={page} />
              </div>
            </div>
          </BrokersDetailsTable>
        </BrokerDetailsTableContainer>
      </BrokersContainer>
    </HomeContainer>
  );
};

export default BrokerDetails;
