import React, { useState, useCallback } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { TableRow } from '@mui/material';
import { ClientTableCellBody } from '../../containers/Clients/clientStyles';
import CopyToClipboardButton from '../Button/CopyToClipboard';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../../components/Dialog/AlertDialog.jsx';
import DeleteIcon from '@mui/icons-material/Delete';
import formatNumber from '../../utils/formatNumber.js';

const ClientsIndividual = ({ client, deleteClient, search, page }) => {
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);

  const handlerDeleteClient = useCallback(() => {
    deleteClient(client._id, search, page);
  }, [client._id, deleteClient, search, page]);

  const navigateToEdit = useCallback(() => {
    navigate(`/editar-cliente/${client._id}`);
  }, [navigate, client._id]);

  const renderClientInfo = (info) => info || '-';

  const getCurrencyAmount = (currencyName) => {
    const currencyAccount = client?.currentAccount?.find(
      (account) => account.currency.name === currencyName,
    );
    return currencyAccount?.amount;
  };

  const usdCcAmount = getCurrencyAmount('USD');
  const usdtCcAmount = getCurrencyAmount('USDT');
  const arsCcAmount = getCurrencyAmount('ARS');

  return (
    <TableRow>
      <ClientTableCellBody
        align="left"
        width={'300px'}
        onClick={() => navigate(`/clientes/${client._id}`)}
      >
        {client.name}
      </ClientTableCellBody>

      <ClientTableCellBody align="left" width={'300px'}>
        {renderClientInfo(formatNumber(usdCcAmount))}
      </ClientTableCellBody>

      <ClientTableCellBody align="left" width={'300px'}>
        {renderClientInfo(formatNumber(usdtCcAmount))}
      </ClientTableCellBody>

      <ClientTableCellBody align="left" width={'300px'}>
        {renderClientInfo(formatNumber(arsCcAmount))}
      </ClientTableCellBody>

      <ClientTableCellBody align="left" width={'300px'}>
        {renderClientInfo(client.address)}
      </ClientTableCellBody>

      <ClientTableCellBody align="left" width={'300px'}>
        <CopyToClipboardButton msg={client._id.slice(20)} id={client._id} />
      </ClientTableCellBody>

      <ClientTableCellBody align="left" width={'300px'}>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '34px' }}>
          <EditIcon onClick={navigateToEdit} style={{ cursor: 'pointer' }}>
            Editar
          </EditIcon>
          <AlertDialog
            handlerAction={handlerDeleteClient}
            page={page}
            objectId={client._id}
            open={openAlert}
            onClose={() => setOpenAlert(false)}
            context={'clients'}
            object={client}
          />
          <DeleteIcon
            onClick={() => setOpenAlert(true)}
            style={{ cursor: 'pointer' }}
          >
            Eliminar
          </DeleteIcon>
        </div>
      </ClientTableCellBody>
    </TableRow>
  );
};

export default ClientsIndividual;
