import React from 'react';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';
import useStock from './useStock';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material/styles';
import Button from '../../components/Button/Button';
import {
  BoxMui,
  BoxTitle,
  BtnContainer,
  AddStockContainer,
} from './stockStyles';
import CancelButton from '../../components/Button/CancelButton';
import themeMui from '../../theme';
import { NumericFormat } from 'react-number-format';
import { Checkbox, FormControlLabel } from '@mui/material';

const EditCurrency = ({ notificationClicked }) => {
  const id = window.location.pathname.split('/')[2];
  const { getCurrency, updateCurrency, currency, errors, loading } = useStock();
  const navigate = useNavigate();

  const [currencyEdited, setCurrencyEdited] = React.useState({
    currency: null,
    exchange_rate: null,
    initial_balance: null,
    isDigital: null,
    icon: null,
  });

  const handleChangeIsDigital = (e) => {
    const { name, checked } = e.target;
    setCurrencyEdited({ ...currencyEdited, [name]: checked });
  };

  const handleGetCurrency = async () => {
    await getCurrency(id);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrencyEdited({ ...currency, [name]: value });
  };

  const handleChangeBalance = (values, e) => {
    const { formattedValue, floatValue } = values;
    setCurrencyEdited({
      ...currencyEdited,
      initial_balance: floatValue,
    });
  };

  const handleChangeTc = (values, e) => {
    const { formattedValue, floatValue } = values;
    setCurrencyEdited({
      ...currencyEdited,
      exchange_rate: floatValue,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateCurrency(id, currencyEdited);
  };

  React.useEffect(() => {
    handleGetCurrency();
    setCurrencyEdited({
      currency: currency?.currency,
      exchange_rate: currency?.exchange_rate,
      initial_balance: currency?.initial_balance,
      isDigital: currency?.isDigital,
      icon: currency?.icon,
    });
  }, [currency?.currency, currency?.exchange_rate, currency?.initial_balance]);

  React.useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate(`/operaciones/${notificationClicked.id}`);
    }
  }, [notificationClicked.isClicked]);

  return (
    <>
      <ThemeProvider>
        <AddStockContainer>
          <div style={{ marginRight: '78%' }}>
            <BreadcrumbsMui
              title="Editar divisa"
              prev="Stock"
              path={'/stock'}
            />
          </div>
          <BoxTitle>
            <h1>Editar divisa</h1>
          </BoxTitle>
          <BoxMui component="form" noValidate onSubmit={handleSubmit}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '100px',
                gap: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  gap: '10px',
                }}
              >
                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '50%',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  required
                  helperText="Divisa"
                  autoFocus
                  name="currency"
                  variant="standard"
                  onChange={handleChange}
                  error={errors?.currency}
                  value={currencyEdited.currency}
                />

                <NumericFormat
                  customInput={TextField}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                  required
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '50%',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  helperText="Tipo de cambio"
                  variant="standard"
                  name="exchange_rate"
                  value={currencyEdited.exchange_rate}
                  error={errors?.exchange_rate}
                  onValueChange={handleChangeTc}
                  allowNegative={false}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  gap: '10px',
                }}
              >
                <NumericFormat
                  customInput={TextField}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                  required
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '50%',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  helperText="Balance Inicial"
                  variant="standard"
                  name="initial_balance"
                  value={currencyEdited.initial_balance}
                  error={errors?.initial_balance}
                  onValueChange={handleChangeBalance}
                />
                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '50%',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  helperText="Icono"
                  variant="standard"
                  name="icon"
                  value={currencyEdited.icon}
                  onChange={handleChange}
                />
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => handleChangeIsDigital(e)}
                    checked={currencyEdited.isDigital}
                    name="isDigital"
                    color="primary"
                    sx={{
                      color: 'grey',
                      '&.Mui-checked': {
                        color: 'green',
                      },
                    }}
                  />
                }
                label="¿Es digital?"
                sx={{
                  color: `${themeMui.palette.inputText.main}`,
                  marginTop: '10px',
                }}
              />
            </div>

            <BtnContainer>
              <Button type="submit" title="GUARDAR" disable={loading} />
              <CancelButton
                title="CANCELAR"
                onClick={() => navigate('/stock')}
              />
            </BtnContainer>
          </BoxMui>
        </AddStockContainer>
      </ThemeProvider>
    </>
  );
};

export default EditCurrency;
