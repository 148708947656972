/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';

import { HomeContainer } from '../Layout/homeStyles';
import {
  AddOperationContainer,
  BoxMui,
  BoxTitle,
  BreadcumsContainer,
  OperationFormContainer,
} from './operationsStyles';
import { useOperations } from './useOperations';
import useStock from '../Stock/useStock';
import useUsers from '../Team/useUsers';
import useClients from '../Clients/useClients';
import DetailForm from './Forms/DetailForm';
import useBrokers from '../Brokers/useBrokers';

const OperationDetail = ({ notificationClicked, setNotificationClicked }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { getOperationById, operation, setOperation, updateOperation, errors } =
    useOperations();
  const { stock, getStock } = useStock();
  const { getDeliverys, deliverys } = useUsers();
  const { clients, getClients } = useClients();
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const {
    getSpecificBrokerDetails,
    intermediaryInfo,
    noIntermediaryInfo,
    isLoading,
  } = useBrokers();

  const getBrokersInfo = async () => {
    if (operation.broker) {
      await getSpecificBrokerDetails(operation.broker, 'broker');
    }
    if (operation.intermediary) {
      await getSpecificBrokerDetails(operation.intermediary, 'intermediary');
    }
  };

  const handleInputChange = (e) => {
    setOperation({ ...operation, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await updateOperation(id, operation, operation?.assignedDelivery?._id);
  };

  let inputAnomalie = operation?.anomalie?.filter((a) => a.type === 'input');
  let outputAnomalie = operation?.anomalie?.filter((a) => a.type === 'output');

  useEffect(() => {
    getOperationById(id);
    getDeliverys();
    getStock();
    getClients();
  }, []);

  useEffect(() => {
    getBrokersInfo();
  }, [operation]);

  useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate(`/operaciones/${notificationClicked.id}`);
    }
  }, [notificationClicked.isClicked]);

  useEffect(() => {
    setSelectedClient(operation.client);
    setSelectedUser(operation.assignedDelivery);
  }, [operation.client, operation.assignedDelivery]);

  return (
    <HomeContainer>
      <AddOperationContainer>
        <BreadcumsContainer>
          <BreadcrumbsMui
            title="Detalle"
            prev="Operaciones"
            path={'/operaciones'}
          />
        </BreadcumsContainer>
        <BoxTitle>
          <h1>Detalle</h1>
        </BoxTitle>
        <BoxMui component="form" noValidate onSubmit={handleSubmit}>
          <OperationFormContainer>
            <DetailForm
              errors={errors}
              handleInputChange={handleInputChange}
              setSelectedClient={setSelectedClient}
              setSelectedUser={setSelectedUser}
              setOperation={setOperation}
              usersDelivery={deliverys}
              operation={operation}
              stock={stock}
              clients={clients}
              selectedClient={selectedClient}
              selectedUser={selectedUser}
              inputAnomalie={inputAnomalie}
              outputAnomalie={outputAnomalie}
              context={'detail'}
              intermediaryInfo={intermediaryInfo}
              noIntermediaryInfo={noIntermediaryInfo}
              isLoading={isLoading}
            />
          </OperationFormContainer>
        </BoxMui>
      </AddOperationContainer>
    </HomeContainer>
  );
};

export default OperationDetail;
