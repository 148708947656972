import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import PaginationItem from '@mui/material/PaginationItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaginationItem-root': {
      color: '#7C8198',
    },
    '& .Mui-selected': {
      background: `linear-gradient(
        to left,
        #f35658,
        #d43436
      )`,
      border: '0',
      color: '#FFFFFF',
    },
  },
}));

export default function Paginate({
  page,
  setPage,
  totalPages,
  search,
  getFunction,
  date,
}) {
  const handleChange = (event, value) => {
    setPage(value);
    getFunction(search, value, date);
  };

  const classes = useStyles();
  return (
    <Stack
      spacing={1000}
      style={{ color: 'red', margin: '1rem' }}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',
        marginTop: '1rem',
      }}
    >
      <Pagination
        hideNextButton={page === totalPages ? true : false}
        hidePrevButton={page === 1 ? true : false}
        count={totalPages}
        page={page}
        size="large"
        classes={{ ul: classes.root }}
        onChange={handleChange}
        variant="outlined"
      >
        <PaginationItem></PaginationItem>
      </Pagination>
    </Stack>
  );
}
