import {
  TableCell,
  TableRow,
  IconButton,
  MenuItem,
  Select,
  Checkbox,
} from '@mui/material';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import theme from '../../theme';
import CopyToClipboardButton from '../Button/CopyToClipboard';
import AlertDialog from '../Dialog/AlertDialog';
import formatNumber from '../../utils/formatNumber';
import formatDate from '../../utils/formatDate';
import operationFinacialOrinstitutional from '../../utils/operationFinancialOrInstitutional';
import { IconSpan } from '../../containers/Operations/operationsStyles';
import { useNavigate } from 'react-router-dom';
import { operationStatus } from '../../utils/constants';
import { useOperations } from '../../containers/Operations/useOperations';

const OperationRow = ({
  operation,
  getOperations,
  filters,
  page,
  handleSelectOperation,
  selected,
  deleteOperation,
  isEditable,
  open,
  setOpen,
  setLiquidatorValue,
  setIsEditable,
  checkOperation,
  user,
  isDisabled,
}) => {
  const {
    _id,
    input_currency,
    output_currency,
    input_amount,
    output_amount,
    operation_type,
    liquidator_type,
    anomalie,
    status,
    client,
    clientToTransfer,
    assignedDelivery,
    serviceProvider,
    paidTeamMember,
    broker,
    deliveryPositionOperation,
    index,
    exchange_rate,
    dateCreated,
    sub_type_op,
    useCurrentAccount,
    checkedByCaja,
  } = operation;

  const navigate = useNavigate();
  const [statusValue, setStatusValue] = useState(status);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertEdit, setOpenAlertEdit] = useState(false);
  const [isChecked, setIsChecked] = useState(checkedByCaja?.isChecked || false);

  const userLocal = JSON.parse(localStorage.getItem('user'));
  const { changeOperationStatus } = useOperations();

  const isOptionDisabled = (userRole, statusValue, option) => {
    if (statusValue === 'Liquidado' || statusValue === 'Finalizado') {
      return option !== 'Pendiente' && option !== 'Finalizado';
    }

    if (userRole === 'tesoreria') {
      return [
        'Confirmado Cliente',
        'Confirmado LYM',
        'Pendiente',
        'Confirmado Delivery',
      ].includes(option);
    }
    if (userRole === 'liquidador_caja') {
      return [
        'Confirmado Delivery',
        'Finalizado',
        'Confirmado LYM',
        'Confirmado Cliente',
        'Pendiente',
        'Liquidado',
      ].includes(option);
    }
    const deliveryDisabledOptions = new Set([
      'Confirmado LYM',
      'Confirmado Cliente',
    ]);

    if (statusValue === 'Confirmado Delivery') {
      return deliveryDisabledOptions.has(option);
    }

    return (
      ((statusValue === 'Confirmado LYM' ||
        statusValue === 'Confirmado Cliente') &&
        ['Confirmado Cliente', 'Confirmado LYM'].includes(option)) ||
      option === 'Confirmado Delivery'
    );
  };

  const getOptions = (userRole, statusValue) =>
    operationStatus.map((option) => ({
      value: option,
      label: option.toUpperCase(),
      disabled: isOptionDisabled(userRole, statusValue, option),
    }));

  const handleChangeStatus = async (e) => {
    const user = {
      name: userLocal.name,
      email: userLocal.email,
      role: userLocal.role,
    };

    setIsEditable(false);
    setStatusValue(e.target.value);
    await changeOperationStatus(
      _id,
      { statusValue: e.target.value },
      undefined,
      user,
      operation_type,
    );

    if (e.target.value === 'Liquidado') {
      setLiquidatorValue({
        name: userLocal.name,
        email: userLocal.email,
      });
    } else if (e.target.value !== 'Finalizado') {
      setLiquidatorValue(undefined);
    }

    await getOperations(filters, page);
  };

  const handleCheckChange = async (event) => {
    const newCheckedStatus = event.target.checked;
    setIsChecked(newCheckedStatus);
    await checkOperation(_id, newCheckedStatus, userLocal);
  };

  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell sx={{ paddingLeft: '12px', paddingRight: '5px' }}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
          sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04);', padding: '2px' }}
        >
          {open ? (
            <KeyboardArrowUpIcon sx={{ color: '#384764' }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ color: '#384764' }} />
          )}
        </IconButton>
      </TableCell>
      {operation?.liquidator_type?.toLowerCase() === 'multiple' ? (
        <TableCell sx={{ paddingLeft: '3px' }}>
          <span
            style={{
              backgroundColor: '#f35658',
              borderRadius: '100%',
              color: 'white',
              paddingLeft: '9px',
              paddingRight: '9px',
              paddingTop: '4px',
              paddingBottom: '4px',
              fontSize: '16px',
            }}
          >
            {operation.subOperations?.length}
          </span>
        </TableCell>
      ) : (
        <span
          style={{
            backgroundColor: 'transparent',
            borderRadius: '100%',
            paddingLeft: '9px',
            paddingRight: '9px',
            paddingTop: '4px',
            paddingBottom: '4px',
            fontSize: '16px',
          }}
        />
      )}

      <TableCell sx={{ paddingLeft: '0px', paddingRight: '4px' }}>
        <Checkbox
          disabled={user?.role === 'liquidador_caja' || isDisabled}
          sx={{
            color: 'gray',
            '&.Mui-checked': {
              color: '#384764',
            },
            ':hover': { cursor: 'pointer' },
          }}
          onChange={handleSelectOperation}
          value={JSON.stringify({
            id: operation._id,
            type: operation.operation_type,
          })}
          checked={selected?.some((o) => o.id === operation._id)}
        />
      </TableCell>
      <TableCell
        align="left"
        sx={{
          color: `${theme.palette.tableText.main}`,
          fontWeight: '500',
          fontSize: '14px',
          paddingLeft: '8px',
          paddingRight: '8px',
          cursor: 'pointer',
        }}
        onClick={() => navigate(`/operation/${_id}`)}
      >
        {clientToTransfer && operation_type === 'INGRESO CTA.CORRIENTE'
          ? clientToTransfer?.name
          : operation_type === 'ENTREGO/SALE POS.DELIVERY'
          ? assignedDelivery.name
          : client
          ? client?.name
          : serviceProvider
          ? serviceProvider.name
          : paidTeamMember
          ? paidTeamMember.name
          : broker
          ? broker
          : deliveryPositionOperation
          ? deliveryPositionOperation.name
          : '---'}
        {useCurrentAccount && (
          <AccountBalanceWalletIcon
            sx={{
              color: 'green',
              fontSize: '15px',
              marginLeft: '5px',
              marginBottom: '-2px',
            }}
          />
        )}
      </TableCell>

      <TableCell
        sx={{ paddingLeft: '8px', paddingRight: '8px', fontSize: '14px' }}
      >
        {input_currency ? (
          <>
            {input_currency?.icon && (
              <IconSpan>{input_currency?.icon}</IconSpan>
            )}{' '}
            {input_currency?.currency?.toUpperCase()}
          </>
        ) : (
          '---'
        )}{' '}
        {anomalie?.map((a, i) => {
          if (a.type === 'input' && a.status === 'active') {
            return (
              <span style={{ color: 'green' }} key={i}>
                ◉
              </span>
            );
          }
        })}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          color: `${theme.palette.tableText.main}`,
          fontWeight: '500',
          fontSize: '14px',
          paddingLeft: '8px',
          paddingRight: '8px',
        }}
      >
        {input_amount ? '$' + formatNumber(input_amount) : '---'}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          color: `${theme.palette.tableText.main}`,
          fontWeight: '500',
          fontSize: '14px',
          paddingLeft: '8px',
          paddingRight: '8px',
        }}
      >
        {exchange_rate}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          color: `${theme.palette.tableText.main}`,
          fontWeight: '500',
          fontSize: '14px',
          paddingLeft: '8px',
          paddingRight: '8px',
        }}
      >
        {output_currency ? (
          <>
            {output_currency?.icon && (
              <IconSpan>{output_currency?.icon}</IconSpan>
            )}{' '}
            {output_currency?.currency?.toUpperCase()}
          </>
        ) : (
          '---'
        )}{' '}
        {anomalie?.map((a, i) => {
          if (a.type === 'output' && a.status === 'active') {
            return (
              <span style={{ color: 'red' }} key={i}>
                ◉
              </span>
            );
          }
        })}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          color: `${theme.palette.tableText.main}`,
          fontWeight: '500',
          fontSize: '14px',
          paddingLeft: '8px',
          paddingRight: '8px',
        }}
      >
        {output_amount ? '$' + formatNumber(output_amount) : '---'}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          color: `${theme.palette.tableText.main}`,
          fontWeight: '500',
          fontSize: '14px',
          paddingLeft: '8px',
          paddingRight: '8px',
        }}
      >
        {operation_type.toUpperCase()}
        {sub_type_op ? `/${sub_type_op}` : null}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          color: `${theme.palette.tableText.main}`,
          fontWeight: '500',
          fontSize: '14px',
          paddingLeft: '8px',
          paddingRight: '8px',
        }}
      >
        {liquidator_type ? liquidator_type?.toUpperCase() : '---'}
      </TableCell>
      {operationFinacialOrinstitutional(operation_type) ? (
        <TableCell
          key={operation._id}
          align="left"
          sx={{
            color: `${theme.palette.tableText.main}`,
            fontWeight: '500',
            fontSize: '14px',
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          <Select
            variant="standard"
            disableUnderline
            value={statusValue}
            onChange={handleChangeStatus}
            sx={{
              color: `${theme.palette.tableText.main}`,
              fontSize: '14px',
            }}
          >
            {(operation_type === 'EGRESO CTA.CORRIENTE' ||
              operation_type === 'INGRESO CTA.CORRIENTE') && (
              <MenuItem value={'Pendiente'}>PENDIENTE</MenuItem>
            )}
            {(operation_type === 'EGRESO CTA.CORRIENTE' ||
              operation_type === 'INGRESO CTA.CORRIENTE') &&
              assignedDelivery && (
                <MenuItem disabled={true} value={'Confirmado Delivery'}>
                  CONFIRMADO DELIVERY
                </MenuItem>
              )}
            {operationStatus.map((o, i) => {
              if (i < 4) return;
              return (
                <MenuItem value={o} key={i}>
                  {o.toUpperCase()}
                </MenuItem>
              );
            })}
          </Select>
        </TableCell>
      ) : (
        <TableCell
          align="left"
          sx={{
            color: `${theme.palette.tableText.main}`,
            fontWeight: '500',
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          {liquidator_type !== 'multiple' && (
            <Select
              variant="standard"
              disableUnderline
              value={statusValue}
              onChange={handleChangeStatus}
              sx={{
                color: `${theme.palette.tableText.main}`,
                fontSize: '14px',
              }}
            >
              {getOptions(userLocal.role, statusValue).map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled || isDisabled}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </TableCell>
      )}
      <TableCell>
        <Checkbox checked={isChecked} onChange={handleCheckChange} />
      </TableCell>
      <TableCell
        align="left"
        sx={{
          color: `${theme.palette.tableText.main}`,
          fontWeight: '500',
          fontSize: '14px',
          paddingLeft: '8px',
          paddingRight: '8px',
        }}
      >
        {formatDate(dateCreated)}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          color: `${theme.palette.tableText.main}`,
          fontWeight: '500',
          fontSize: '14px',
          paddingLeft: '8px',
          paddingRight: '8px',
        }}
      >
        <CopyToClipboardButton id={index} />
      </TableCell>
      <TableCell
        align="left"
        sx={{
          paddingLeft: '0px',
          paddingRight: '0px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '5px',
            paddingLeft: '0',
            paddingRight: '0',
          }}
        >
          <AlertDialog
            open={openAlertEdit}
            onClose={() => setOpenAlertEdit(false)}
            context={'operationEdit'}
          />

          {userLocal?.role !== 'liquidador_caja' && (
            <EditIcon
              sx={{
                color: `${theme.palette.icons.main}`,
                ':hover': { cursor: 'pointer' },
                visibility: `${
                  operation_type === 'PAGO SERV/PROV' ||
                  operation_type === 'PAGO SUELDOS' ||
                  operation_type === 'LIQ.COMISIONES' ||
                  operation_type === 'ENTREGO/SALE POS.DELIVERY' ||
                  operation_type === 'RECIBO/ENTRA POS.DELIVERY'
                    ? 'hidden'
                    : 'visible'
                }`,
              }}
              onClick={() => {
                isEditable
                  ? navigate(`/editar-operacion/${_id}`)
                  : setOpenAlertEdit(true);
              }}
            >
              Editar
            </EditIcon>
          )}

          <AlertDialog
            handlerAction={deleteOperation}
            objectId={operation._id}
            open={openAlert}
            onClose={() => setOpenAlert(false)}
            context={'operation'}
          />

          {userLocal?.role !== 'liquidador_caja' && (
            <DeleteIcon
              sx={{
                color: `${theme.palette.icons.main}`,
                ':hover': { cursor: 'pointer' },
              }}
              onClick={() => setOpenAlert(true)}
            >
              Eliminar
            </DeleteIcon>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default OperationRow;
