import React, { useCallback } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  Typography,
  Stack,
} from '@mui/material';
import Searcher from '../Searcher/Searcher';
import Comission from './Comission';
import BrokerDetailsHead from './BrokerDetailsHead';
import IosSwitch from './IosSwitch.jsx';
import theme from '../../theme';
import { SwitchAndButtonContainer } from '../../containers/Brokers/brokerStyles';

const BrokersDetailsTable = ({
  deleteBroker,
  brokerInfo,
  getBrokerById,
  getTotalAmount,
  setCommissionSearch,
  commissionSearch,
  brokerInfoWcommissionsFiltered,
  switcher,
  setSwitcher,
}) => {
  const handleSwitchChange = useCallback(() => {
    setSwitcher(!switcher);
    getBrokerById(brokerInfo._id, commissionSearch, !switcher, 'commissions');
  }, [brokerInfo._id, commissionSearch, switcher, getBrokerById, setSwitcher]);

  return (
    <div>
      <SwitchAndButtonContainer>
        <Searcher
          setSearch={setCommissionSearch}
          search={commissionSearch}
          context="commissions"
          getCommissionsSearch={() =>
            getBrokerById(
              brokerInfo._id,
              commissionSearch,
              switcher,
              'commissions',
            )
          }
          getCommissionsEmpty={() =>
            getBrokerById(brokerInfo._id, undefined, switcher, 'commissions')
          }
        />
        <Stack direction="row" spacing={1} alignItems="center">
          <IosSwitch
            onClick={handleSwitchChange}
            checked={switcher}
            colorProp="red"
            inputProps={{ 'aria-label': 'ant design' }}
          />
          <Typography sx={!switcher ? { color: 'red' } : { color: '#e0e0e0' }}>
            Pendientes
          </Typography>
          <Typography sx={switcher ? {} : { color: '#e0e0e0' }}>
            Liquidadas
          </Typography>
        </Stack>
      </SwitchAndButtonContainer>

      {!brokerInfoWcommissionsFiltered?.commissions?.length ? (
        <h2
          style={{
            textAlign: 'center',
            marginBottom: '7rem',
            color: theme.palette.title.main,
          }}
        >
          No hay registros de comisiones.
        </h2>
      ) : (
        <TableContainer>
          <Table>
            <BrokerDetailsHead />
            <TableBody>
              {brokerInfoWcommissionsFiltered?.commissions?.map(
                (commission) => (
                  <Comission
                    key={commission._id}
                    commission={commission}
                    deleteBroker={deleteBroker}
                    getBrokerById={getBrokerById}
                    getTotalAmount={getTotalAmount}
                  />
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default BrokersDetailsTable;
