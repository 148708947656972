import React, { useEffect } from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import Searcher from '../../components/Searcher/Searcher';
import UserIndividual from '../../components/Team/UserIndividual';
import UsersHead from './UsersHead';
import theme from '../../theme';

const Users = ({
  getUsers,
  users,
  deleteUser,
  userForSearch,
  setUsers,
  loading,
}) => {
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <TableContainer>
      <Searcher
        context="users"
        userForSearch={userForSearch}
        setUsers={setUsers}
        getUsers={getUsers}
      />
      {!users?.length && !loading ? (
        <h2
          style={{ textAlign: 'center', color: `${theme.palette.title.main}` }}
        >
          No hay usuarios con ese nombre.
        </h2>
      ) : (
        <Table
          sx={{
            width: '100%',
            margin: 'auto',
          }}
        >
          <UsersHead />
          <TableBody>
            {users?.map((user, i) => (
              <UserIndividual user={user} deleteUser={deleteUser} key={i} />
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default Users;
