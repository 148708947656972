import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';

const OperationType = ({ filters, setFilters, checkboxStyle }) => {
  const [checked, setChecked] = useState({
    'COMP.VTA.BILLETE': filters.operationType.includes('COMP.VTA.BILLETE'),
    'COMP.VTA.USDT': filters.operationType.includes('COMP.VTA.USDT'),
    'ENV.RECP.TRANSF.PES/EFV': filters.operationType.includes(
      'ENV.RECP.TRANSF.PES/EFV',
    ),
    'ENV.RECP.TRANSF.PES': filters.operationType.includes(
      'ENV.RECP.TRANSF.PES',
    ),
    'SUBIDA CABLE': filters.operationType.includes('SUBIDA CABLE'),
    'BAJADA CABLE': filters.operationType.includes('BAJADA CABLE'),
    'RECP.CHEQU.PES/EFV': filters.operationType.includes('RECP.CHEQU.PES/EFV'),
    'INT.OP.TER/EFV': filters.operationType.includes('INT.OP.TER/EFV'),
    'PAGO SERV/PROV': filters.operationType.includes('PAGO SERV/PROV'),
    'PAGO SUELDOS': filters.operationType.includes('PAGO SUELDOS'),
    'LIQ.COMISIONES': filters.operationType.includes('LIQ.COMISIONES'),
    'INGRESO CTA.CORRIENTE': filters.operationType.includes(
      'INGRESO CTA.CORRIENTE',
    ),
    'EGRESO CTA.CORRIENTE': filters.operationType.includes(
      'EGRESO CTA.CORRIENTE',
    ),
    'ENTREGO/SALE POS.DELIVERY': filters.operationType.includes(
      'ENTREGO/SALE POS.DELIVERY',
    ),
    'RECIBO/ENTRA POS.DELIVERY': filters.operationType.includes(
      'RECIBO/ENTRA POS.DELIVERY',
    ),
  });

  useEffect(() => {
    setChecked({
      'COMP.VTA.BILLETE': filters.operationType.includes('COMP.VTA.BILLETE'),
      'COMP.VTA.USDT': filters.operationType.includes('COMP.VTA.USDT'),
      'ENV.RECP.TRANSF.PES/EFV': filters.operationType.includes(
        'ENV.RECP.TRANSF.PES/EFV',
      ),
      'ENV.RECP.TRANSF.PES': filters.operationType.includes(
        'ENV.RECP.TRANSF.PES',
      ),
      'SUBIDA CABLE': filters.operationType.includes('SUBIDA CABLE'),
      'BAJADA CABLE': filters.operationType.includes('BAJADA CABLE'),
      'RECP.CHEQU.PES/EFV':
        filters.operationType.includes('RECP.CHEQU.PES/EFV'),
      'INT.OP.TER/EFV': filters.operationType.includes('INT.OP.TER/EFV'),
      'PAGO SERV/PROV': filters.operationType.includes('PAGO SERV/PROV'),
      'PAGO SUELDOS': filters.operationType.includes('PAGO SUELDOS'),
      'LIQ.COMISIONES': filters.operationType.includes('LIQ.COMISIONES'),
      'INGRESO CTA.CORRIENTE': filters.operationType.includes(
        'INGRESO CTA.CORRIENTE',
      ),
      'EGRESO CTA.CORRIENTE': filters.operationType.includes(
        'EGRESO CTA.CORRIENTE',
      ),
      'ENTREGO/SALE POS.DELIVERY': filters.operationType.includes(
        'ENTREGO/SALE POS.DELIVERY',
      ),
      'RECIBO/ENTRA POS.DELIVERY': filters.operationType.includes(
        'RECIBO/ENTRA POS.DELIVERY',
      ),
    });
  }, [filters.operationType.length]);

  const handleChange = (e) => {
    if (e.target.checked) {
      setFilters({
        ...filters,
        operationType: [...filters.operationType, e.target.value],
      });
      setChecked({ ...checked, [e.target.value]: true });
    } else {
      setChecked({ ...checked, [e.target.value]: false });

      const newFilter = filters;
      const removeFilter = filters.operationType.filter(
        (s) => s !== e.target.value,
      );

      newFilter.operationType = removeFilter;
      setFilters(newFilter);
    }
  };

  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="COMP.VTA.BILLETE"
          value="COMP.VTA.BILLETE"
          onChange={handleChange}
          checked={checked['COMP.VTA.BILLETE']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="COMP.VTA.USDT"
          value="COMP.VTA.USDT"
          onChange={handleChange}
          checked={checked['COMP.VTA.USDT']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="ENV.RECP.TRANSF.PES/EFV"
          value="ENV.RECP.TRANSF.PES/EFV"
          onChange={handleChange}
          checked={checked['ENV.RECP.TRANSF.PES/EFV']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="ENV.RECP.TRANSF.PES"
          value="ENV.RECP.TRANSF.PES"
          onChange={handleChange}
          checked={checked['ENV.RECP.TRANSF.PES']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="SUBIDA CABLE"
          value="SUBIDA CABLE"
          onChange={handleChange}
          checked={checked['SUBIDA CABLE']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="BAJADA CABLE"
          value="BAJADA CABLE"
          onChange={handleChange}
          checked={checked['BAJADA CABLE']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="RECP.CHEQU.PES/EFV"
          value="RECP.CHEQU.PES/EFV"
          onChange={handleChange}
          checked={checked['RECP.CHEQU.PES/EFV']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="INT.OP.TER/EFV"
          value="INT.OP.TER/EFV"
          onChange={handleChange}
          checked={checked['INT.OP.TER/EFV']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="PAGO SERV/PROV"
          value="PAGO SERV/PROV"
          onChange={handleChange}
          checked={checked['PAGO SERV/PROV']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="PAGO SUELDOS"
          value="PAGO SUELDOS"
          onChange={handleChange}
          checked={checked['PAGO SUELDOS']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="LIQ.COMISIONES"
          value="LIQ.COMISIONES"
          onChange={handleChange}
          checked={checked['LIQ.COMISIONES']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="INGRESO CTA.CORRIENTE"
          value="INGRESO CTA.CORRIENTE"
          onChange={handleChange}
          checked={checked['INGRESO CTA.CORRIENTE']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="EGRESO CTA.CORRIENTE"
          value="EGRESO CTA.CORRIENTE"
          onChange={handleChange}
          checked={checked['EGRESO CTA.CORRIENTE']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="ENTREGO/SALE POS.DELIVERY"
          value="ENTREGO/SALE POS.DELIVERY"
          onChange={handleChange}
          checked={checked['ENTREGO/SALE POS.DELIVERY']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="RECIBO/ENTRA POS.DELIVERY"
          value="RECIBO/ENTRA POS.DELIVERY"
          onChange={handleChange}
          checked={checked['RECIBO/ENTRA POS.DELIVERY']}
        />
      </FormGroup>
    </div>
  );
};

export default OperationType;
