import React from 'react';
import { TableHead, TableRow } from '@mui/material';
import { UserTableCell } from '../../containers/Team/userStyles';

const BusinessHead = ({ headCells }) => {
  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell) => (
          <UserTableCell align="left" key={headCell}>
            {headCell}
          </UserTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default BusinessHead;
