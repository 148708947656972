import React, { useState, useCallback } from 'react';
import { TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { UserTableCellBody } from '../../containers/Team/userStyles';
import { BtnPayOff } from '../../containers/Brokers/brokerStyles';
import AlertDialog from '../Dialog/AlertDialog';
import useBrokers from '../../containers/Brokers/useBrokers';
import formatNumber from '../../utils/formatNumber';
import { useParams, useNavigate } from 'react-router-dom';

const Comission = ({ commission, getBrokerById, getTotalAmount }) => {
  const {
    deleteCommission,
    payOffCommission,
    creditCurrentAccount,
    isAccreditting,
    isPaying,
  } = useBrokers();
  const { id } = useParams();
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);

  const formatCreatedAt = useCallback((createdAt) => {
    const date = new Date(createdAt);
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'America/Argentina/Buenos_Aires',
    };
    return date.toLocaleString('es-AR', options);
  }, []);

  const handlerDeleteComission = useCallback(() => {
    deleteCommission(commission._id);
    setOpenAlert(false);
    if (id) {
      getBrokerById(id);
    }
  }, [commission._id, deleteCommission, getBrokerById, id]);

  const handlerPayOffCommission = useCallback(async () => {
    try {
      await payOffCommission(
        commission._id,
        commission.stock[0],
        commission.amount,
      );
      if (id) {
        getBrokerById(id);
      }
      getTotalAmount();
    } catch (error) {
      console.log(error);
    }
  }, [commission, payOffCommission, getBrokerById, getTotalAmount, id]);

  const handlerCreditCurrentAccount = useCallback(async () => {
    try {
      await creditCurrentAccount(commission._id, commission.amount);
      if (id) {
        getBrokerById(id);
      }
      getTotalAmount();
    } catch (error) {
      console.log(error);
    }
  }, [commission, creditCurrentAccount, getBrokerById, getTotalAmount, id]);

  const navigateToOperation = useCallback(() => {
    navigate(`/operation/${commission.operation[0]}`);
  }, [navigate, commission.operation]);

  return (
    <TableRow>
      <UserTableCellBody align="left" width={'300px'}>
        {formatCreatedAt(commission.createdAt)}
      </UserTableCellBody>

      <UserTableCellBody align="left" width={'300px'}>
        {commission.typeOfBroker}
      </UserTableCellBody>

      <UserTableCellBody align="left" width={'300px'}>
        {formatNumber(commission.amount)} USD
      </UserTableCellBody>

      <UserTableCellBody
        align="left"
        width={'300px'}
        onClick={navigateToOperation}
        style={{ cursor: 'pointer' }}
      >
        #{commission.index}
      </UserTableCellBody>

      <UserTableCellBody align="left" width={'300px'}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '14px',
            alignItems: 'center',
          }}
        >
          {commission.status === 'active' && (
            <BtnPayOff
              style={{ cursor: 'pointer' }}
              onClick={() => handlerPayOffCommission(commission._id)}
              disabled={isPaying}
            >
              LIQUIDAR
            </BtnPayOff>
          )}
          {commission.status === 'active' && (
            <BtnPayOff
              style={{ cursor: 'pointer' }}
              onClick={() => handlerCreditCurrentAccount(commission._id)}
              disabled={isAccreditting}
            >
              ACREDITAR
            </BtnPayOff>
          )}

          <AlertDialog
            handlerAction={handlerDeleteComission}
            page={1}
            objectId={commission._id}
            open={openAlert}
            onClose={() => setOpenAlert(false)}
            context={'commission'}
          />

          {commission.status === 'active' && (
            <DeleteIcon
              onClick={() => setOpenAlert(true)}
              style={{ cursor: 'pointer' }}
            />
          )}
        </div>
      </UserTableCellBody>
    </TableRow>
  );
};

export default Comission;
