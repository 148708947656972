import styled from 'styled-components';
import theme from '../../theme';
import { Box, Modal } from '@mui/material';

export const LogisticContainer = styled.div`
  margin-left: 300px;
  margin-top: 40px;
  width: 100%;
`;

export const OperationAndMapContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const CurrencyContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid #d3d6da;
  border-radius: 14px;
  height: 50px;
  align-items: center;
  margin: 4rem 0;
`;

export const ChangePositionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

export const PositionFormContainer = styled.div`
  display: flex;
`;

export const BreadcumsContainer = styled.div`
  margin-right: 77%;
  margin-bottom: 20px;
`;
export const BoxTitle = styled.div`
  background: #d43436; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 77%;
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  position: relative;
  top: 50px;
`;

export const BoxMui = styled(Box)`
  background-color: #fff;
  border-radius: 10px;
  padding: 70px;
  width: 80%;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  padding-top: 10rem;
`;

export const MapContainer = styled.div`
  width: 50%;
  padding-right: 30px;
`;

export const OperationContainer = styled.div`
  width: 50%;
`;

export const Select = styled.select`
  border: 1px solid ${theme.palette.title.main};
  color: ${theme.palette.title.main};
  padding: 10px 10px;
  border-radius: 5px;
  background: none;
  &:hover {
    cursor: pointer;
  }
`;

export const LogisticTitle = styled.h1`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${theme.palette.title.main};
`;

export const LogisticSubTitle = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  color: ${theme.palette.title.main};
`;

export const TransferButton = styled.button`
  background: #364769; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #364769,
    #364769
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #364769,
    #364769
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif !important;
  box-shadow: 0px 3px 3px #b3abab;
  transition: 0.3s;
  &:hover {
    box-shadow: none;
  }

  width: 8rem;
  padding: 1rem;
  align-text: center;
`;

export const SwitchAndButtonContainer = styled.div`
  display: flex;
  margin: 2rem 0rem;
  align-items: center;
`;

export const RetryContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const RetryButton = styled.button`
  border: 1px solid #d43436;
  background-color: transparent;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  color: #d43436;
`;

export const StackContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 50px;
  height: 100%;
`;

export const NoRegisteredDelivery = styled.h2`
  text-align: center;
  padding: 10px 0px;
  color: #d43436;
  width: 57%;
  border-radius: 10px;
`;

export const ModalPositionHistory = styled(Modal)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
  max-height: 90vh;
  overflow-y: auto;
  margin: auto;
`;

export const ContentModalContainer = styled.div`
  background-color: white;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
`;

export const ButtonAndDatePickerContainer = styled.div`
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const NoHistory = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

export const SplitOperationContentModal = styled.div`
  background-color: white;
  width: 50%;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  height: 80vh;
`;

export const ModalItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  align-items: center;
  padding: 1rem;
`;

export const ModalItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
  margin-top: 1rem;
`;

export const ModalTitle = styled.h2`
  color: ${theme.palette};
`;

export const InputContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const BtnContainerModal = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  gap: 1rem;
`;

export const SubopsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  align-items: center;
`;

export const Title = styled.h2`
  color: ${theme.palette.title.main};
  font-size: 1.5rem;
`;

export const SubOpIndexContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;

  h3 {
    color: ${theme.palette.btn.main};
    font-size: 1rem;
    border-bottom: 1px solid ${theme.palette.btn.main};
    width: 100%;
  }
`;

export const SpanLabel = styled.span`
  color: ${theme.palette.title.main};
`;

export const RemoveButton = styled.button`
  background: none;
  border: none;
  color: red;
  font-size: 1.2em;
  cursor: pointer;
  margin-left: 10px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
`;

export const TotalSpan = styled.span`
  color: ${theme.palette.title.main};
  border: 1px solid ${theme.palette.title.main};
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const TotalStreet = styled.div`
  display: flex;
  gap: 10px;
`;

export const TotalStreetTitle = styled.h1`
  color: ${theme.palette.title.main};
  font-size: 1rem;
`;

export const AmountsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const AmountItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  color: ${theme.palette.title.main};
  font-weight: bold;
  font-size: 14px;
`;
