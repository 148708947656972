import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';
import Button from '../../components/Button/Button';
import CancelButton from '../../components/Button/CancelButton';

import { HomeContainer } from '../Layout/homeStyles';
import {
  AddOperationContainer,
  BoxMui,
  BoxTitle,
  BreadcumsContainer,
  BtnContainer,
  OperationFormContainer,
} from './operationsStyles';
import { useOperations } from './useOperations';
import useStock from '../Stock/useStock';
import useUsers from '../Team/useUsers';
import useClients from '../Clients/useClients';
import MainForm from './Forms/MainForm';
import CurrentAccountForm from './Forms/CurrentAccountForm';
import operationFinacialOrinstitutional from '../../utils/operationFinancialOrInstitutional';
import Loader from '../../components/Loader/Loader';
import useNotistack from '../Notistack/useNotistack';

const EditOperation = ({ notificationClicked, setNotificationClicked }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showNotification } = useNotistack();
  const {
    getOperationById,
    operation,
    setOperation,
    updateOperation,
    errors,
    loading,
    createOperation,
  } = useOperations();

  const { stock, getStock, getCurrency } = useStock();
  const { getDeliverys, deliverys } = useUsers();
  const { clients, getClients } = useClients();

  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [subOperationsAnomalies, setSubOperationsAnomalies] = useState([]);
  const [subOperations, setSubOperations] = useState([]);
  const [currencyChecked, setCurrencyChecked] = useState(null);
  const [createIncomeCurrentAccount, setCreateIncomeCurrentAccount] =
    useState();
  const [shouldCreateSubOperation, setShouldCreateSubOperation] =
    useState(false);
  const [isStockLoading, setIsStockLoading] = useState(true);
  const [isDeliveriesLoading, setIsDeliveriesLoading] = useState(true);
  const [isClientsLoading, setIsClientsLoading] = useState(true);
  const [isOperationLoading, setIsOperationLoading] = useState(true);

  const isCurrentOperationInstitutional = useCallback(
    () => operationFinacialOrinstitutional(operation.operation_type),
    [operation],
  );
  const handleSubOperationsInputChange = (event, index) => {
    const updatedSubOperations = [...operation.subOperations];

    const value =
      event.target.name === 'liquidator_type' ||
      event.target.name === 'address' ||
      event.target.name === 'addressDetail'
        ? event.target.value // Asigna directamente para cadenas
        : parseFloat(event.target.value) || 0; // Parsea para números

    updatedSubOperations[index] = {
      ...updatedSubOperations[index],
      [event.target.name]: value,
    };

    setOperation({ ...operation, subOperations: updatedSubOperations });
  };

  const handleAnomalies = useCallback(() => {
    const anomalies = operation.subOperations.flatMap((subOp) =>
      subOp.anomalie.map((anomalie) => ({
        index: anomalie.index,
        amount: anomalie.amount,
        type: anomalie.type,
        id: anomalie._id,
        status: anomalie.status,
      })),
    );
    setSubOperationsAnomalies(
      anomalies.length ? anomalies : subOperationsAnomalies,
    );
  }, [operation.subOperations, subOperationsAnomalies]);

  const validateAnomaliesAmounts = () => {
    if (operation.input_anomaly > operation.input_amount) return true;

    if (operation.output_anomaly > operation.output_amount) return true;

    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const notValid = validateAnomaliesAmounts();
    if (notValid) {
      showNotification(
        'Las anomalias no pueden ser mayores a los montos de entrada/salida',
        'error',
      );
      return;
    }
    const currencyName = await getCurrency(operation.input_currency);
    const client = clients.find(
      (client) => client._id === operation.client._id,
    );
    const clientCurrentAccount = client?.currentAccount?.find(
      (currentAccount) =>
        currentAccount.currency.id.toString() === operation.input_currency,
    );

    const updatedOperation = {
      ...operation,
      currencyChecked: operation.useCurrentAccount
        ? {
            currency: {
              id: operation.input_currency,
              name: currencyName.currency,
            },
            amount: clientCurrentAccount?.amount || 0,
          }
        : null,
      createIncomeCurrentAccount,
      input_anomaly:
        operation.input_anomaly !== undefined ? operation.input_anomaly : null,
      output_anomaly:
        operation.output_anomaly !== undefined
          ? operation.output_anomaly
          : null,
    };

    if (shouldCreateSubOperation) {
      const currentAccountOperation = {
        client: selectedClient._id,
        comments: operation.comments,
        input_amount: operation.output_amount,
        input_currency: operation.output_currency,
        operation_type: 'INGRESO CTA.CORRIENTE',
        classification: 'FINANCIERA',
        parentOperationId: operation._id,
      };
      await createOperation(currentAccountOperation, operation.profile);
    }

    await updateOperation(
      id,
      updatedOperation,
      operation?.assignedDelivery?._id,
      subOperationsAnomalies,
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newValue = value === '' ? null : value;

    setOperation((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  const handleSubtractSubOperation = (index) => {
    const pendingCount = operation.subOperations?.filter(
      (subOp) => subOp.status === 'Pendiente',
    ).length;

    if (
      (operation.subOperations[index].status === 'Pendiente' &&
        pendingCount > 1) ||
      (pendingCount === 1 &&
        operation.subOperations.length === 1 &&
        operation.subOperations[index].status === 'Pendiente')
    ) {
      const updatedSubOperations = [...operation.subOperations];
      updatedSubOperations.splice(index, 1);
      setOperation({
        ...operation,
        subOperations: updatedSubOperations,
        liquidator_type: updatedSubOperations.length > 0 ? 'multiple' : '',
      });
    } else {
      alert(
        'No se puede eliminar una suboperación con estado distinto a Pendiente o dejar todas las suboperaciones sin estado "Pendiente".',
      );
    }
  };

  useEffect(() => {
    if (operation.assignedDelivery) {
      setSelectedUser(operation.assignedDelivery);
    }
  }, [operation.assignedDelivery]);

  const renderForm = () => {
    const isCurrentAccountType = [
      'EGRESO CTA.CORRIENTE',
      'INGRESO CTA.CORRIENTE',
    ].includes(operation.operation_type);
    return isCurrentAccountType ? (
      <CurrentAccountForm
        handleInputChange={(e) =>
          setOperation({ ...operation, [e.target.name]: e.target.value })
        }
        clients={clients}
        deliverys={deliverys}
        selectedClient={selectedClient}
        setSelectedClient={setSelectedClient}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        operation={operation}
        stock={stock}
        errors={errors}
        setOperation={setOperation}
        context={'edit'}
        isCurrentOperationInstitutional={isCurrentOperationInstitutional()}
      />
    ) : (
      <MainForm
        errors={errors}
        handleInputChange={(e) => handleInputChange(e)}
        handleNumericFormatChange={(values, name) =>
          setOperation({ ...operation, [name]: values.floatValue })
        }
        setSelectedClient={setSelectedClient}
        setSelectedUser={setSelectedUser}
        setOperation={setOperation}
        usersDelivery={deliverys}
        operation={operation}
        stock={stock}
        clients={clients}
        selectedClient={selectedClient}
        selectedUser={selectedUser}
        inputAnomalie={operation?.anomalie?.filter((a) => a.type === 'input')}
        outputAnomalie={operation?.anomalie?.filter((a) => a.type === 'output')}
        context={'edit'}
        handleAddSubOperation={() => {
          const emptySubOperation = {
            liquidator_type: '',
            assignedDelivery: null,
            status: 'Pendiente',
            input_amount: 0,
            output_amount: 0,
          };
          setOperation({
            ...operation,
            subOperations: [...operation.subOperations, emptySubOperation],
            liquidator_type: 'multiple',
          });
        }}
        handleSubtractSubOperation={(index) => {
          handleSubtractSubOperation(index);
        }}
        handleSubOperationsInputChange={(event, index) => {
          handleSubOperationsInputChange(event, index);
        }}
        handleSubOperationAmounts={(values, name, index) => {
          const updatedSubOperations = [...operation.subOperations];
          updatedSubOperations[index] = {
            ...updatedSubOperations[index],
            [name]: values.floatValue,
          };
          setOperation({ ...operation, subOperations: updatedSubOperations });
        }}
        subOperations={operation.subOperations}
        setSubOperations={setSubOperations}
        subOperationsAnomalies={subOperationsAnomalies}
        handleSubOperationAnomaliesChange={(amount, index, type) => {
          const i = subOperationsAnomalies.findIndex(
            (e) => e.index === index && e.type === type,
          );
          if (i !== -1) {
            let updatedSubOperationsAnomalies = [...subOperationsAnomalies];
            updatedSubOperationsAnomalies[i].amount = amount.floatValue;
            setSubOperationsAnomalies(updatedSubOperationsAnomalies);
          } else {
            const newAnomalie = {
              operationId: operation?._id,
              stock:
                type === 'input'
                  ? operation?.input_currency
                  : operation?.output_currency._id,
              amount: amount.floatValue,
              type,
              client: operation?.client._id,
              index,
            };
            setSubOperationsAnomalies([...subOperationsAnomalies, newAnomalie]);
          }
        }}
        isCurrentOperationInstitutional={isCurrentOperationInstitutional()}
        currencyChecked={currencyChecked}
        setCurrencyChecked={setCurrencyChecked}
        setCreateIncomeCurrentAccount={setCreateIncomeCurrentAccount}
        createIncomeCurrentAccount={createIncomeCurrentAccount}
        onCheckboxChange={(newCheckedState) => {
          setCreateIncomeCurrentAccount(newCheckedState);
          setShouldCreateSubOperation(newCheckedState);
        }}
        isStockLoading={isStockLoading}
        isDeliveriesLoading={isDeliveriesLoading}
        isClientsLoading={isClientsLoading}
      />
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      await getOperationById(id);
      await getDeliverys();
      await getClients();
      await getStock();
      setIsStockLoading(false);
      setIsDeliveriesLoading(false);
      setIsClientsLoading(false);
      setIsOperationLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate(`/operaciones/${notificationClicked.id}`);
    }
  }, [notificationClicked, navigate]);

  useEffect(() => {
    if (operation.subOperations && operation.subOperations.length > 1) {
      const subOperations = operation.subOperations;

      // Calcular los totales de las suboperaciones que no están en estado 'Pendiente'
      const totalNonPendingInput = subOperations
        .filter((subOp) => subOp.status !== 'Pendiente')
        .reduce((acc, subOp) => acc + (subOp.input_amount || 0), 0);
      const totalNonPendingOutput = subOperations
        .filter((subOp) => subOp.status !== 'Pendiente')
        .reduce((acc, subOp) => acc + (subOp.output_amount || 0), 0);

      // Encontrar la última suboperación pendiente
      const lastPendingIndex = subOperations
        .slice()
        .reverse()
        .findIndex((subOp) => subOp.status === 'Pendiente');

      if (lastPendingIndex !== -1) {
        const index = subOperations.length - 1 - lastPendingIndex;

        const totalPendingInput = subOperations
          .slice(0, index)
          .filter((subOp) => subOp.status === 'Pendiente')
          .reduce((acc, subOp) => acc + (subOp.input_amount || 0), 0);
        const totalPendingOutput = subOperations
          .slice(0, index)
          .filter((subOp) => subOp.status === 'Pendiente')
          .reduce((acc, subOp) => acc + (subOp.output_amount || 0), 0);

        const updatedSubOperation = {
          ...subOperations[index],
          input_amount: Math.max(
            0,
            operation.input_amount - totalNonPendingInput - totalPendingInput,
          ),
          output_amount: Math.max(
            0,
            operation.output_amount -
              totalNonPendingOutput -
              totalPendingOutput,
          ),
        };

        if (
          subOperations[index].input_amount !==
            updatedSubOperation.input_amount ||
          subOperations[index].output_amount !==
            updatedSubOperation.output_amount
        ) {
          const updatedSubOperations = [
            ...subOperations.slice(0, index),
            updatedSubOperation,
            ...subOperations.slice(index + 1),
          ];
          setOperation({ ...operation, subOperations: updatedSubOperations });
        }
      }
    }
  }, [
    operation.subOperations,
    operation.input_amount,
    operation.output_amount,
  ]);

  return (
    <HomeContainer>
      <AddOperationContainer>
        <BreadcumsContainer>
          <BreadcrumbsMui
            title="Editar operacion"
            prev="Operaciones"
            path="/operaciones"
          />
        </BreadcumsContainer>
        <BoxTitle>
          <h1>Editar operacion</h1>
        </BoxTitle>
        {isOperationLoading ? (
          <Loader style={{ marginTop: '10rem' }} />
        ) : (
          <BoxMui component="form" noValidate onSubmit={handleSubmit}>
            <OperationFormContainer>{renderForm()}</OperationFormContainer>
            <BtnContainer>
              <Button type="submit" title="GUARDAR" disable={loading} />
              <CancelButton
                title="CANCELAR"
                onClick={() => navigate('/operaciones')}
              />
            </BtnContainer>
          </BoxMui>
        )}
      </AddOperationContainer>
    </HomeContainer>
  );
};

export default EditOperation;
