const pushData = (operations) => {
  const array = [];
  operations?.map((s) => {
    array.push([
      `${s?._id}`,
      `${s?.input_currency?.currency}`,
      `${s?.input_amount}`,
      `${s?.output_currency?.currency}`,
      `${s?.output_amount}`,
      `${s?.operation_type}`,
      `${s?.liquidator_type}`,
      `${s?.status}`,
      `${s?.client?.name}`,
    ]);
  });

  return array;
};

export const handleFormatCsv = (operations) => {
  const csvData = [
    [
      'Id',
      'Divisa de entrada',
      'Monto de entrada',
      'Divisa de salida',
      'Monto de salida',
      'Tipo de op.',
      'Liquidador',
      'Estado',
      'Cliente',
    ],
    ...pushData(operations),
  ];

  return csvData;
};
