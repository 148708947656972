import styled from 'styled-components';
import theme from '../../theme';

export const Btn = styled.button`
  background: #d43436; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  border: none;
  border-radius: 10px;
  padding: 12px 30px;
  margin-right: 10px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif !important;
  box-shadow: 0px 6px 12px #b3abab;
  transition: 0.3s;
  &:hover {
    box-shadow: none;
  }
`;

export const BtnWidth = styled.button`
  background: #d63638; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f25456,
    #d63638
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f25456,
    #d63638
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  margin-right: 10px;
  cursor: pointer;
  width: 100%;
`;

export const BtnOutline = styled.button`
  background: transparent;
  color: ${theme.palette.title.main} !important;
  border: 1.5px solid ${theme.palette.title.main};
  border-radius: 10px;
  padding: 12px 20px;
  margin-left: 10px;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Roboto', sans-serif !important;
  display: flex;
  gap: 5px;
  align-items: center;
  height: 40px;
`;

export const BtnOutline2 = styled.div`
  background: transparent;
  color: ${theme.palette.title.main} !important;
  border: 1.5px solid ${theme.palette.title.main};
  border-radius: 10px;
  padding: 12px 20px;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Roboto', sans-serif !important;
  display: flex;
  gap: 5px;
  align-items: center;
  height: 40px;
`;

export const CancelBtn = styled.button`
  background-color: #97a0af;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 30px;
  margin-right: 10px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif !important;
  box-shadow: 0px 6px 12px #b3abab;
  transition: 0.3s;
  &:hover {
    box-shadow: none;
  }
  height: 40px;
`;
