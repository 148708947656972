import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useBrokers from '../../Brokers/useBrokers';
import formatNumber from '../../../utils/formatNumber';
import DeleteIcon from '@mui/icons-material/Delete';
import useStock from '../../Stock/useStock';

import {
  CorredorContainer,
  CorredorLine,
  CorredorTitle,
} from '../operationsStyles';

const BrokerForm = ({
  setOperation,
  operation,
  filter,
  profile,
  errors,
  theme,
  handleAddBroker,
  inputCurrencyState,
  outputCurrencyState,
  setShowAddBrokerButton,
  showAddBrokerButton,
  addedBroker,
  setAddedBroker,
  brokerType,
  context,
  exchangeRate,
}) => {
  const { getCurrency, currency, getStock } = useStock();
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [initialBroker, setInitialBroker] = useState(null);
  const { brokers, getBrokers, loading } = useBrokers();

  const exchangeRateToConvert = exchangeRate;

  let percentage = 0.0005; // El porcentaje de la comisión.

  const pairsToMultiply = [
    'ARS / EURO CHICO',
    'ARS / EURO GRANDE',
    'LIBRAS / ARS',
  ];

  const comissionCalc = () => {
    if (!operation?.input_amount && !operation?.output_amount) {
      return 0;
    }

    let total = 0;

    // Si la moneda de entrada o salida es USD, usar ese monto directamente.
    if (inputCurrencyState?.currency === 'USD' || currency === 'USD') {
      total = operation?.input_amount;
    } else if (
      outputCurrencyState?.currency === 'USD' ||
      operation.output_currency.currency === 'USD'
    ) {
      total = operation?.output_amount;
    } else {
      // Si ninguna moneda es USD, convertir el monto relevante a USD.
      const amountToConvert =
        inputCurrencyState?.currency !== 'ARS'
          ? operation?.input_amount
          : operation?.output_amount;

      const pair = `${inputCurrencyState?.currency} / ${outputCurrencyState?.currency}`;
      if (!exchangeRateToConvert || isNaN(exchangeRateToConvert)) {
        console.log('No hay TC para convertir el monto a USD.');
        return 0;
      }
      if (pairsToMultiply.includes(pair)) {
        total = amountToConvert * exchangeRateToConvert;
      } else {
        total = amountToConvert / exchangeRateToConvert;
      }
    }

    // Calcular la comisión basada en el monto en USD.
    const commissionAmount = total * percentage;
    return formatNumber(commissionAmount); 
  };

  const handleDeleteBroker = () => {
    setSelectedBroker(null);
    setOperation({
      ...operation,
      broker: null,
      commission: '',
    });
    setShowAddBrokerButton(!showAddBrokerButton);
    setAddedBroker(!addedBroker);
  };

  useEffect(() => {
    const commission = comissionCalc();
    setOperation({
      ...operation,
      commission: commission,
      brokerType: brokerType,
    });
  }, [
    exchangeRate,
    operation.input_amount,
    operation.output_amount,
    inputCurrencyState,
    outputCurrencyState,
    operation.exchange_rate,
  ]);

  useEffect(() => {
    console.log('Component did mount: fetching brokers and stock');

    getBrokers();
    getStock();
    getCurrency(operation?.input_currency);
  }, []);

  useEffect(() => {
    const foundBroker = brokers.find(
      (broker) => broker._id === operation?.broker,
    );
    setInitialBroker(foundBroker || null);
  }, [operation?.broker, brokers]);

  return loading ? (
    <>
      <CorredorContainer>
        <CorredorTitle>Cargando corredores...</CorredorTitle>
        <CorredorLine />
      </CorredorContainer>
    </>
  ) : (
    <div>
      <CorredorContainer>
        <CorredorTitle>Corredor</CorredorTitle>
        <CorredorLine />
      </CorredorContainer>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '33%',
          }}
        >
          <Autocomplete
            value={context === 'edit' ? initialBroker : selectedBroker}
            onChange={(event, newValue) => {
              console.log('onChange triggered with:', newValue);
              if (typeof newValue === 'string') {
                console.log('New broker as string:', newValue);
                setSelectedBroker({
                  name: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                handleAddBroker(newValue.inputValue)
                  .then((newBrokerId) => {
                    if (newBrokerId) {
                      setSelectedBroker({
                        name: newValue.inputValue,
                        _id: newBrokerId,
                      });
                      setOperation({
                        ...operation,
                        broker: newBrokerId,
                      });
                      getBrokers();
                    } else {
                      console.log('No se pudo crear el broker');
                    }
                  })
                  .catch((error) => {
                    console.log('Error al agregar el broker:', error);
                  });
              } else {
                console.log('New broker as object:', newValue);
                setSelectedBroker(newValue);
                setOperation({
                  ...operation,
                  broker: newValue?._id,
                });
                if (context === 'edit') {
                  setOperation({
                    ...operation,
                    broker: newValue?._id,
                  });
                }
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;

              const isExisting = options.filter(
                (option) =>
                  option.name.toLowerCase() === inputValue.toLowerCase(),
              );

              if (inputValue !== '' && isExisting.length === 0) {
                filtered.push({
                  inputValue,
                  name: `Agregar "${inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={brokers}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }

              if (option.inputValue) {
                return option.inputValue;
              }

              return option.name;
            }}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            freeSolo
            fullWidth
            disabled={profile?.role === 'liquidador_caja' ? true : false}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors.client}
                label="Corredor"
                variant="standard"
                InputLabelProps={{
                  style: {
                    color: `${theme.palette.inputText.main}`,
                  },
                }}
                sx={{
                  input: {
                    color: `${theme.palette.inputText.main}`,
                  },
                  '& .MuiInputLabel-root': { color: '#D3D6DA' },
                }}
              />
            )}
          />
        </div>

        <div
          style={{
            width: '33%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: theme.palette.primary.main,
              padding: '10px',
              borderRadius: '10px',
              border: '1px solid #D3D6DA',
              color: theme.palette.title.main,
            }}
          >
            <h4
              style={{
                margin: '0',
                padding: '0',
              }}
            >
              Comisión:{' '}
              <span
                style={{
                  color: 'gray',
                  fontWeight: 'lighter',
                }}
              >
                0.05%
              </span>
            </h4>
          </div>
        </div>

        <div
          style={{
            width: '33%',
          }}
        >
          <TextField
            variant="standard"
            InputLabelProps={{
              style: { color: `${theme.palette.inputText.main}` },
            }}
            sx={{
              input: {
                color: `${theme.palette.inputText.main}`,
              },
              '& .MuiInputLabel-root': { color: '#D3D6DA' },
              borderBottom: '1px solid #D3D6DA',
            }}
            InputProps={{ disableUnderline: true }}
            fullWidth
            value={context === 'edit' ? operation.commission : comissionCalc()}
            disabled
          />
        </div>

        <DeleteIcon
          sx={{
            color: 'white',
            margin: '0',
            padding: '5px',
            cursor: 'pointer',
            backgroundColor: '#f35658',
            borderRadius: '50%',
            width: '25px',
            height: '25px',
          }}
          onClick={() => handleDeleteBroker()}
        />
      </div>
    </div>
  );
};

export default BrokerForm;
