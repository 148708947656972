import { TextField } from '@mui/material';

const CustomTextField = ({ label, type, id, name, autoComplete, onChange, onKeyDown }) => {
  return (
    <TextField
      margin="normal"
      required
      fullWidth
      label={label}
      type={type}
      id={id}
      name={name}
      autoComplete={autoComplete}
      onChange={onChange}
      autoFocus
      onKeyDown={onKeyDown}
      sx={{
        '& label.Mui-focused': {
          color: '#D43436',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#D2D6D9',
        },
        '& .MuiOutlinedInput-root': { 
          '& fieldset': {
            borderColor: '#D3D6DA',
          },
          '&:hover fieldset': {
            borderColor: '#D43436',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#D43436',
          },
        },
      }}
    />
  );
};

export default CustomTextField;
