import { Box, CssBaseline } from '@mui/material';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar/index.js';

const Layout = ({ notificationClicked, setNotificationClicked }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleLeftDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar drawerOpen={drawerOpen} drawerToggle={handleLeftDrawerToggle} />
      <Outlet
        notificationClicked={notificationClicked}
        setNotificationClicked={setNotificationClicked}
      />
    </Box>
  );
};

export default Layout;
