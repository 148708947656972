import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import AuthVerify from './AuthVerify.js';
import { useState } from 'react';
import { SocketProvider } from '../../utils/socket.js';
import '../../App.css';

import Layout from '../Layout/index.jsx';
import theme from '../../theme';
import Login from '../Login/Login';
import { PrivateRoute } from './PrivateRoute.jsx';
import Users from '../Team/Users';
import AddUser from '../Team/AddUser';
import EditUser from '../Team/EditUser';
import Operations from '../Operations/Operations';
import Clients from '../Clients/Clients';
import AddClient from '../Clients/AddClient';
import AddOperation from '../Operations/AddOperation';
import AddCurrency from '../Stock/AddCurrency';
import Stock from '../Stock/Stock';
import EditCurrency from '../Stock/EditCurrency';
import Logistic from '../Logistic/Logistic';
import Anomalies from '../Anomalies/Anomalies';
import Recovery from '../Recovery/Recovery';
import SendEmail from '../Recovery/SendEmail';
import EditClient from '../Clients/EditClient';
import EditOperation from '../Operations/EditOperation';
import ClientDetail from '../Clients/ClientDetail';
import PrechargedOperations from '../Operations/PrechargedOperations.jsx';
import useAuth from '../Login/useAuth.js';
import MyProfile from '../Team/MyProfile.jsx';
import OperationDetail from '../Operations/OperationDetail.jsx';
import EditPosition from '../Logistic/EditPosition.jsx';
import Brokers from '../Brokers/Brokers.jsx';
import AddBroker from '../Brokers/AddBroker.jsx';
import EditBroker from '../Brokers/EditBroker.jsx';
import BrokerDetails from '../Brokers/BrokerDetails.jsx';
import BusinessUnits from '../BusinessUnits/BusinessUnits.jsx';
import { OperationsProvider } from '../Operations/useOperations.jsx';

function App() {
  const logOut = () => {
    localStorage.removeItem('user');
  };
  const [notificationClicked, setNotificationClicked] = useState({
    isClicked: false,
    id: '0',
  });

  const user = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <SocketProvider>
        <SnackbarProvider autoHideDuration={3000}>
          <BrowserRouter>
            <OperationsProvider>
              <Routes>
                {/* Public Routes */}
                <Route
                  path="/"
                  element={
                    user ? <Navigate to="/stock" /> : <Navigate to="/login" />
                  }
                />
                <Route path="/login" element={<Login />} />
                <Route path="/recovery/:token" element={<Recovery />} />
                <Route path="/unauthorized" element={<h1>Unauthorized</h1>} />
                <Route path="/send-email" element={<SendEmail />} />

                <Route
                  element={
                    <Layout
                      notificationClicked={notificationClicked}
                      setNotificationClicked={setNotificationClicked}
                    />
                  }
                >
                  {/* Private Routes - Role Discrimination */}

                  <Route
                    element={
                      <PrivateRoute
                        notificationClicked={notificationClicked}
                        setNotificationClicked={setNotificationClicked}
                      />
                    }
                  >
                    <Route
                      path="my-profile/:id"
                      element={
                        <MyProfile
                          notificationClicked={notificationClicked}
                          setNotificationClicked={setNotificationClicked}
                        />
                      }
                    />
                    <Route
                      path="/equipo"
                      element={
                        <Users
                          notificationClicked={notificationClicked}
                          setNotificationClicked={setNotificationClicked}
                        />
                      }
                    />
                    <Route
                      path="/agregar-usuario"
                      element={
                        <AddUser
                          notificationClicked={notificationClicked}
                          setNotificationClicked={setNotificationClicked}
                        />
                      }
                    />
                    <Route
                      path="/profile/:id"
                      element={
                        <EditUser
                          notificationClicked={notificationClicked}
                          setNotificationClicked={setNotificationClicked}
                        />
                      }
                    />
                    <Route
                      path="/clientes"
                      element={
                        <Clients
                          notificationClicked={notificationClicked}
                          setNotificationClicked={setNotificationClicked}
                        />
                      }
                    />
                    <Route
                      path="/clientes/:id"
                      element={
                        <ClientDetail
                          notificationClicked={notificationClicked}
                          setNotificationClicked={setNotificationClicked}
                        />
                      }
                    />
                    <Route
                      path="/editar-cliente/:id"
                      element={
                        <EditClient
                          notificationClicked={notificationClicked}
                          setNotificationClicked={setNotificationClicked}
                        />
                      }
                    />
                    <Route
                      path="/agregar-clientes"
                      element={
                        <AddClient
                          notificationClicked={notificationClicked}
                          setNotificationClicked={setNotificationClicked}
                        />
                      }
                    />
                    <Route
                      path="/agregar-operacion"
                      element={
                        <AddOperation
                          setNotificationClicked={setNotificationClicked}
                          notificationClicked={notificationClicked}
                        />
                      }
                    />

                    <Route
                      path="/agregar-operacion/posicion/:deliveryId"
                      element={
                        <AddOperation
                          setNotificationClicked={setNotificationClicked}
                          notificationClicked={notificationClicked}
                        />
                      }
                    />

                    <Route
                      path="/agregar-operacion/quitar-posicion/:deliveryId"
                      element={
                        <AddOperation
                          setNotificationClicked={setNotificationClicked}
                          notificationClicked={notificationClicked}
                        />
                      }
                    />

                    <Route
                      path="/agregar-operacion/ingreso-cta-cte/:id"
                      element={
                        <AddOperation
                          setNotificationClicked={setNotificationClicked}
                          notificationClicked={notificationClicked}
                        />
                      }
                    />
                    <Route
                      path="/editar-operacion/:id"
                      element={
                        <EditOperation
                          setNotificationClicked={setNotificationClicked}
                          notificationClicked={notificationClicked}
                        />
                      }
                    />
                    <Route
                      path="/operation/:id"
                      element={
                        <OperationDetail
                          setNotificationClicked={setNotificationClicked}
                          notificationClicked={notificationClicked}
                        />
                      }
                    />
                    <Route
                      path="/agregar-divisa"
                      element={
                        <AddCurrency
                          setNotificationClicked={setNotificationClicked}
                          notificationClicked={notificationClicked}
                        />
                      }
                    />
                    <Route
                      path="/stock"
                      element={
                        <Stock notificationClicked={notificationClicked} />
                      }
                    />
                    <Route
                      path="/editar-divisa/:id"
                      element={
                        <EditCurrency
                          notificationClicked={notificationClicked}
                        />
                      }
                    />

                    <Route
                      path="/unidades-negocio"
                      element={
                        <BusinessUnits
                          notificationClicked={notificationClicked}
                          setNotificationClicked={setNotificationClicked}
                        />
                      }
                    />
                  </Route>

                  <Route
                    element={
                      <PrivateRoute
                        notificationClicked={notificationClicked}
                        setNotificationClicked={setNotificationClicked}
                      />
                    }
                  >
                    <Route
                      path="/logistica"
                      element={
                        <Logistic notificationClicked={notificationClicked} />
                      }
                    />

                    <Route
                      path="/transferir-entre-deliveries"
                      element={
                        <EditPosition
                          setNotificationClicked={setNotificationClicked}
                          notificationClicked={notificationClicked}
                        />
                      }
                    />
                    <Route
                      path="/anomalias"
                      element={
                        <Anomalies notificationClicked={notificationClicked} />
                      }
                    />
                  </Route>

                  <Route
                    element={
                      <PrivateRoute
                        notificationClicked={notificationClicked}
                        setNotificationClicked={setNotificationClicked}
                      />
                    }
                  >
                    <Route
                      path="/operaciones"
                      element={
                        <Operations
                          notificationClicked={notificationClicked}
                          setNotificationClicked={setNotificationClicked}
                        />
                      }
                    />

                    <Route
                      path="/comisionistas"
                      element={
                        <Brokers
                          notificationClicked={notificationClicked}
                          setNotificationClicked={setNotificationClicked}
                        />
                      }
                    />

                    <Route
                      path="/comisionistas/:id"
                      element={
                        <BrokerDetails
                          notificationClicked={notificationClicked}
                          setNotificationClicked={setNotificationClicked}
                        />
                      }
                    />

                    <Route
                      path="/agregar-comisionista"
                      element={
                        <AddBroker
                          notificationClicked={notificationClicked}
                          setNotificationClicked={setNotificationClicked}
                        />
                      }
                    />

                    <Route
                      path="/editar-comisionista/:id"
                      element={
                        <EditBroker
                          notificationClicked={notificationClicked}
                          setNotificationClicked={setNotificationClicked}
                        />
                      }
                    />

                    <Route
                      path="/operaciones/:id"
                      element={
                        <Operations
                          notificationClicked={notificationClicked}
                          setNotificationClicked={setNotificationClicked}
                        />
                      }
                    />

                    <Route
                      path="/operaciones-precarga"
                      element={
                        <PrechargedOperations
                          notificationClicked={notificationClicked}
                          setNotificationClicked={setNotificationClicked}
                        />
                      }
                    />
                  </Route>
                </Route>
              </Routes>
              <AuthVerify logOut={logOut} />
            </OperationsProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </SocketProvider>
    </ThemeProvider>
  );
}

export default App;
