import React from 'react';
import {
  ActionsContainer,
  BtnContainerModal,
  SpanLabel,
} from '../../containers/Logistic/logisticStyles';
import { BtnOutline } from '../Button/buttonStyles';
import Btn from '../Button/ButtonWidth';

const ActionsButtons = ({
  addSubOperation,
  validateSubOperations,
  handleSubmit,
  subOps,
  id,
  areAllSubOpsNotPending,
}) => {
  return (
    <ActionsContainer>
      <SpanLabel>¿Querés agregar otra suboperación?</SpanLabel>
      <BtnContainerModal>
        <BtnOutline
          disabled={areAllSubOpsNotPending()}
          onClick={addSubOperation}
        >
          Agregar
        </BtnOutline>
        <Btn
          disabled={areAllSubOpsNotPending()}
          title="Guardar"
          onClick={() => {
            if (validateSubOperations()) {
              handleSubmit(subOps, id);
            }
          }}
        />
      </BtnContainerModal>
    </ActionsContainer>
  );
};

export default ActionsButtons;
