import { Box } from '@mui/material';
import styled from 'styled-components';
import theme from '../../theme';
import TextField from '@mui/material/TextField';
import { TableCell } from '@material-ui/core';

export const BrokersContainer = styled.div`
  margin-bottom: 10px;
  width: 100%;
  padding-right: 20px;
  padding-left: 40px;
`;

export const BrokersTitle = styled.h1`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${theme.palette.title.main};
  margin: 0;
  margin-bottom: 20px;
  position: relative;
  bottom: 20px;
  margin-left: 1rem;
`;

export const AllBrokersButtons = styled.button`
  border: none;
  cursor: pointer;
  height: 50%;
  font-size: 15px;
  margin-left: 20px;
  color: #f76060;
  background-color: #ffffff;
  padding: 10px;
  &:hover {
    background-color: #f0b4b1;
    border-radius: 5px;
    color: white;
  }
`;

export const BrokersButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 40px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px 0px;
  margin-right: 30px;
`;

export const ContainerAddBroker = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const AddBrokerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 48px 0px 0px 200px;
`;
export const BoxMui = styled(Box)`
  background-color: #fff;
  border-radius: 10px;
  padding: 70px;
  width: 100%;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
`;

export const BoxTitleAddBroker = styled.div`
  background: #d43436; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 77%;
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  position: relative;
  top: 50px;
`;

export const TextFieldMui = styled(TextField)`
  color: red;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  gap: 35px;
  align-items: center;
`;

export const SubFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 25px;
  height: 180px;
`;

export const BreadcumsContainer = styled.div`
  margin-right: 70%;
  margin-bottom: 40px;
`;

export const BrokersTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 80px;
  border-radius: 10px;
  width: 70%;
  margin-top: 20px;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
`;

export const BrokerDetailsTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 90px;
  border-radius: 10px;
  width: 96.5%;
  margin-top: 10rem;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
`;

export const TotalInfo = styled.div`
  background-color: white;
  border-radius: 10px;

  width: 30%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  min-height: 150px;
  color: black;

  flex-direction: column;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
`;

export const TotalBrokerTableCell = styled.div`
  color: black;
  font-weight: 500 !important;
  font-size: 20px;
  border-bottom: 1px solid;
  border-color: #a2a2a2;

  padding-bottom: 11px;
  width: 82.97%;
`;

export const TotalInfoValuesContainer = styled.div`
  width: 82.96%;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-weight: 500;
  justify-content: start;
`;

export const BrokerTotal = styled.div`
  justify-content: center;
  width: 30%;
`;

export const TotalInfoValues = styled.div`
  margin: 15px;
`;

export const BrokerTableCellBody = styled(TableCell)`
  color: ${theme.palette.tableText.main};
  padding-left: 25px !important;
  font-weight: 500 !important;
`;

export const BtnPayOff = styled.button`
  background: #d43436; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif !important;
  box-shadow: 0px 3px 3px #b3abab;
  transition: 0.3s;
  &:hover {
    box-shadow: none;
  }
  margin-left: 0.25rem;
  width: 8.5rem;
  height: 2.35rem;
  align-text: center;
`;

export const SwitchAndButtonContainer = styled.div`
  display: flex;
  margin-right: 2rem;
  align-item: center;
  margin-bottom: 1.5rem;
  justify-content: space-around;
`;

export const TransferButton = styled.button`
  background: #364769; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #364769,
    #364769
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #364769,
    #364769
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif !important;
  box-shadow: 0px 3px 3px #b3abab;
  transition: 0.3s;
  &:hover {
    box-shadow: none;
  }

  width: 8rem;
  padding: 1rem;
  align-text: center;
`;

export const BrokerTableCell = styled(TableCell)`
  color: ${theme.palette.tableTitle.main};
  padding-left: 25px !important;
  font-weight: 500 !important;
`;

export const TotalInfoContent = styled.div`
  height: 50%;
  display: flex;
  justify-content: center;

  width: 93%;
  border-bottom: 1px solid lightGray;
`;

export const NameContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  color: 'rgba(0, 0, 0, 0.87)';
  font-weight: 700;
  font-size: 1.2rem;
`;

export const ActionsContainer = styled.div`
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 93%;
`;

export const ActionsContent = styled.div`
  font-size: 1.2rem;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  color: 'rgba(0, 0, 0, 0.87)';
  font-weight: 700;
  font-size: 1.2rem;
`;

export const CardsContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;
