import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import theme from '../../theme';
import Button from '../../components/Button/Button';
import CancelButton from '../../components/Button/CancelButton';
import { PopopverButtonsContainer } from './operationsStyles';
import Status from './FiltersBoxes/Status';
import OperationType from './FiltersBoxes/OperationType';
import LiquidatorType from './FiltersBoxes/LiquidatorType';
import CurrenciesAutocomplete from './FiltersBoxes/CurrenciesSelects';
import DateRangePicker from './FiltersBoxes/DateRangePicker';
import DeleteIcon from '@mui/icons-material/Delete';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  width: '270px',
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: '0.9rem', transform: 'rotate(90deg)' }}
      />
    }
    {...props}
  />
))(() => ({
  backgroundColor: '#FFFFFF',
  color: `${theme.palette.tableText.main}`,
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export function CustomizedAccordions({
  filters,
  setFilters,
  stock,
  startDate,
  endDate,
  setEndDate,
  setStartDate,
  context,
}) {
  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const checkboxStyle = {
    color: 'gray',
    '&.Mui-checked': {
      color: '#384764',
    },
  };

  return (
    <div
      style={{
        padding: '40px 20px',
      }}
    >
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        key="1"
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Estado</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Status
            filters={filters}
            setFilters={setFilters}
            checkboxStyle={checkboxStyle}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
        key="2"
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Tipo de operacion</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <OperationType
            filters={filters}
            setFilters={setFilters}
            checkboxStyle={checkboxStyle}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
        key="3"
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Liquidador</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LiquidatorType
            filters={filters}
            setFilters={setFilters}
            checkboxStyle={checkboxStyle}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel5'}
        onChange={handleChange('panel5')}
        key="5"
      >
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography>Divisa de entrada</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CurrenciesAutocomplete
            context="input"
            filters={filters}
            setFilters={setFilters}
            stock={stock}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel6'}
        onChange={handleChange('panel6')}
        key="6"
      >
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography>Divisa de salida</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CurrenciesAutocomplete
            context="output"
            filters={filters}
            setFilters={setFilters}
            stock={stock}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel7'}
        onChange={handleChange('panel7')}
        key="7"
      >
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <Typography>Fecha</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DateRangePicker
            filters={filters}
            setFilters={setFilters}
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default function FilterPopover({
  anchorEl,
  setAnchorEl,
  filters,
  setFilters,
  getOperations,
  setPage,
  stock,
  startDate,
  endDate,
  setEndDate,
  setStartDate,
  context,
  page,
  postSave,
  setPostSave,
  clientInfo,
}) {
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);

    setStartDate(new Date());
    setEndDate(new Date());
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleDeleteFilters = () => {
    setFilters({
      status: [],
      operationType: [],
      liquidatorType: [],
      inputCurrency: 'Todas',
      outputCurrency: 'Todas',
      dateRange: null,
      changed: false,
    });
    setStartDate(new Date());
    setEndDate(new Date());

    if (postSave) {
      setPage(1);
      getOperations(page);
      handleClose();
      setPostSave(false);
    }
  };

  useEffect(() => {
    if (
      filters.status.length ||
      filters.operationType.length ||
      filters.liquidatorType.length ||
      filters.inputCurrency !== 'Todas' ||
      filters.outputCurrency !== 'Todas' ||
      filters.dateRange
    ) {
      setFilters({ ...filters, changed: true });
    } else {
      setFilters({ ...filters, changed: false });
    }
  }, [
    filters.status.length,
    filters.operationType.length,
    filters.liquidatorType.length,
    filters.inputCurrency,
    filters.outputCurrency,
    filters.dateRange,
  ]);

  const handleSave = () => {
    handleClose();
    setPage(1);
    getOperations(filters);
    if (
      filters.status.length ||
      filters.operationType.length ||
      filters.liquidatorType.length ||
      filters.clientName ||
      filters.inputCurrency !== 'Todas' ||
      filters.outputCurrency !== 'Todas' ||
      filters.dateRange
    ) {
      setPostSave(true);
    }
  };

  return (
    <div>
      <FilterListIcon
        onClick={handleClick}
        sx={{ color: '#7C8098', cursor: 'pointer' }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          height: '600px',
        }}
      >
        <CustomizedAccordions
          setFilters={setFilters}
          filters={filters}
          stock={stock}
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          context={context}
        />

        <PopopverButtonsContainer>
          <Button title="GUARDAR" onClick={handleSave} />
          <CancelButton title="CANCELAR" onClick={handleClose} />
          {filters.changed ? (
            <DeleteIcon
              onClick={handleDeleteFilters}
              sx={{
                fontSize: '2rem',
                margin: '0.5rem',
                color: `${theme.palette.icons.main}`,
                ':hover': { cursor: 'pointer' },
              }}
            ></DeleteIcon>
          ) : null}
        </PopopverButtonsContainer>
      </Popover>
    </div>
  );
}
