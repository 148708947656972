import React from 'react';
import { BtnOutline2 } from './buttonStyles';

const ButtonOutlineComission = ({ title, onClick, disabled }) => {
  return (
    <BtnOutline2
      onClick={(e) => {
        if (!disabled) {
          onClick(e);
        }
      }}
      style={{ opacity: disabled ? 0.5 : 1 }}
    >
      <span style={{ textDecoration: 'none' }}>{title}</span>
    </BtnOutline2>
  );
};

export default ButtonOutlineComission;
