import React, { useState, useEffect } from 'react';
import {
  LogisticContainer,
  OperationAndMapContainer,
  LogisticTitle,
  TotalSpan,
  SelectContainer,
  TotalStreet,
  TotalStreetTitle,
} from './logisticStyles';
import Map from './Map/Map';
import LogisticOperations from './LogisticOperations';
import Select from '../../components/Select/SelectMui';
import { useNavigate } from 'react-router-dom';
import useLogistic from './useLogistic';
import formatNumber from '../../utils/formatNumber';

const Logistic = ({ notificationClicked }) => {
  const {
    setAllDeliveries,
    getAllDeliveries,
    allDeliveries,
    filter,
    setFilter,
  } = useLogistic();

  const [totalBalances, setTotalBalances] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate(`/operaciones/${notificationClicked.id}`);
    }
  }, [notificationClicked.isClicked]);

  useEffect(() => {
    getAllDeliveries(setAllDeliveries);
  }, []);

  useEffect(() => {
    if (allDeliveries.length > 0) {
      let balances = {};

      allDeliveries.forEach((delivery) => {
        delivery.position.forEach((pos) => {
          const { balance, currency } = pos;
          if (currency && currency.name) {
            balances[currency.name] = (balances[currency.name] || 0) + balance;
          }
        });
      });

      setTotalBalances(balances);
    }
  }, [allDeliveries]);

  return (
    <LogisticContainer>
      <div style={{ marginBottom: '20px' }}>
        <LogisticTitle>Logistica</LogisticTitle>
        <SelectContainer>
          <Select
            options={[
              { name: 'TODOS', value: 'Todos' },
              { name: 'Pendiente', value: 'Pendiente' },
              { name: 'Confirmado LYM', value: 'Confirmado LYM' },
              { name: 'Confirmado Delivery', value: 'Confirmado Delivery' },
              { name: 'Confirmado Cliente', value: 'Confirmado Cliente' },
              { name: 'Liquidado', value: 'Liquidado' },
            ]}
            onChangeFunction={(e, newValue) => {
              setFilter({ ...filter, status: newValue });
            }}
            context="logistic"
          />
        </SelectContainer>
      </div>
      {Object.keys(totalBalances).length > 0 && (
        <div>
          <TotalStreetTitle>Total calle:</TotalStreetTitle>
        </div>
      )}
      <TotalStreet>
        {Object.keys(totalBalances).map((currency) => (
          <TotalSpan key={currency}>
            {currency}: {formatNumber(totalBalances[currency])}
          </TotalSpan>
        ))}
      </TotalStreet>
      <OperationAndMapContainer>
        <LogisticOperations filter={filter} />
        <Map />
      </OperationAndMapContainer>
    </LogisticContainer>
  );
};

export default Logistic;
