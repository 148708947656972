import { TableHead, TableRow } from '@mui/material';
import React from 'react';
import { BrokerTableCell } from '../../containers/Brokers/brokerStyles';

const BrokerDetailsHead = () => {
  const columnTitles = [
    'FECHA',
    'TIPO DE COMISIONISTA',
    'MONTO DE COMISIÓN',
    'ID',
    '',
  ];

  return (
    <TableHead>
      <TableRow>
        {columnTitles.map((title, index) => (
          <BrokerTableCell key={index}>{title}</BrokerTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default BrokerDetailsHead;
