import { useState } from 'react';
import fetchFromApi from '../../utils/fetchFromApi';
import useNotistack from '../Notistack/useNotistack';
import useNotifications from '../Notifications/useNotifications';
import { useSocket } from '../../utils/socket';

const useAnomalies = () => {
  const { showNotification } = useNotistack();
  const [anomalies, setAnomalies] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [changeAmount, setChangeAmount] = useState({});
  const [save, setSave] = useState(true);
  const [search, setSearch] = useState();
  const [anomaliesCsv, setAnomaliesCsv] = useState([]);
  const { createNotificationCms } = useNotifications();

  const { socket } = useSocket();

  const getAnomalies = async (search, page, date) => {
    setLoading(true);

    try {
      let anomalies = await fetchFromApi(
        'GET',
        `anomalies/${page}?search=${search}&date=${date}`,
      );

      if (anomalies) {
        if (!anomalies.docs.length && page !== 1) {
          anomalies = await fetchFromApi(
            'GET',
            `anomalies/${page - 1}?search=${search}&date=${date}`,
          );
        }

        let allAnomaliesIdsAndMounts = {};
        let AllAnomaliesSaveController = {};

        anomalies.docs.forEach((e) => {
          allAnomaliesIdsAndMounts = {
            ...allAnomaliesIdsAndMounts,
            [e._id]: e.amount,
          };
          AllAnomaliesSaveController = {
            ...AllAnomaliesSaveController,
            [e._id]: true,
          };
        });

        setAnomalies(anomalies.docs);
        setTotalPages(anomalies.totalPages);
        setPage(anomalies.page);
        setSave(AllAnomaliesSaveController);
        setChangeAmount(allAnomaliesIdsAndMounts);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updateMountAnomaly = async (
    amount,
    page,
    id,
    type,
    stock,
    newAmount,
    search,
  ) => {
    if (newAmount !== '0') {
      const result = await fetchFromApi('PUT', `anomalies/${id}?page=${page}`, {
        stock,
        type,
        stock,
        amount,
        newAmount,
      });
      try {
        if (result) {
          getAnomalies(search, page);
          showNotification(result[1], 'success');
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      payOffAnomaly(page, id, type, stock, amount, search);
    }
  };

  const payOffAnomaly = async (
    page,
    id,
    type,
    stock,
    amount,
    search,
    clientName,
  ) => {
    setLoading(true);

    const result = await fetchFromApi(
      'PUT',
      `anomalies/payoff/${id}?page=${page}`,
      {
        stock,
        type,
        stock,
        amount,
      },
    );
    try {
      if (result) {
        showNotification(result[1], 'success');
        getAnomalies(search, page);

        createNotificationCms(
          `Se ha saldado una anomalía de ${
            type === 'input' ? 'entrada' : 'salida'
          } de ${clientName}`,
          `Monto: ${amount} ${stock.currency}`,
          id,
        );

        socket?.emit('new-operation', JSON.stringify({ _id: id }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const disableAnomaly = async (page, id, search) => {
    setLoading(true);
    const result = await fetchFromApi(
      'PUT',
      `anomalies/disable/${id}?page=${page}`,
    );
    try {
      if (result) {
        showNotification(result[1], 'success');
        getAnomalies(search, page);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnomaliesForCsv = async () => {
    setLoading(true);
    try {
      let anomalies = await fetchFromApi('GET', `anomalies/all/csv`);

      if (anomalies) {
        setAnomaliesCsv(anomalies);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    payOffAnomaly,
    updateMountAnomaly,
    getAnomalies,
    anomalies,
    loading,
    totalPages,
    page,
    setPage,
    setAnomalies,
    setChangeAmount,
    changeAmount,
    setChangeAmount,
    save,
    setSave,
    disableAnomaly,
    search,
    setSearch,
    getAnomaliesForCsv,
    anomaliesCsv,
  };
};

export default useAnomalies;
