import React from 'react';
import { TableHead, TableRow } from '@mui/material';
import { ClientTableCell } from '../../containers/Clients/clientStyles';

const ClientsHead = () => {
  const headCells = [
    'NOMBRE',
    'CC USD',
    'CC USDT',
    'CC ARS',
    'DIRECCIÓN',
    'ID',
    '',
  ];
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <ClientTableCell key={i}>{headCell}</ClientTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default ClientsHead;
