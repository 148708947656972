import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import theme from '../../theme';
import formatNumber from '../../utils/formatNumber';
import HistoryIcon from '@mui/icons-material/History';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const ActiveDelivery = ({
  name,
  handleCenter,
  object,
  position,
  handlePositionHistoryVisibility,
  handleAddPosition,
  handleRemovePosition,
}) => {
  const cellStyles = {
    color: object.selected ? 'white' : theme.palette.tableText.main,
    ':hover': { cursor: 'pointer' },
    borderBottom: 'none',
  };

  return (
    <TableRow
      sx={{
        backgroundColor: object.selected ? '#F25456' : 'transparent',
      }}
    >
      <TableCell
        component="th"
        scope="row"
        sx={cellStyles}
        onClick={() => handleCenter(object)}
      >
        {name}
      </TableCell>
      <TableCell>
        <AddCircleOutlineIcon
          sx={{
            color: 'green',
            cursor: 'pointer',
          }}
          onClick={() => handleAddPosition(object.user.id)}
        />
      </TableCell>
      <TableCell>
        <RemoveCircleOutlineIcon
          sx={{
            color: 'red',
            cursor: 'pointer',
          }}
          onClick={() => handleRemovePosition(object.user.id)}
        />
      </TableCell>
      <TableCell>
        <HistoryIcon
          sx={{
            color: theme.palette.tableText.main,
            cursor: 'pointer',
          }}
          onClick={() => handlePositionHistoryVisibility(object)}
        />
      </TableCell>

      {position?.length > 0 &&
        position.map((p, i) => (
          <TableCell key={i} component="th" scope="row" sx={cellStyles}>
            {p.currency?.name}: {formatNumber(p.balance)}
          </TableCell>
        ))}
    </TableRow>
  );
};

export default ActiveDelivery;
