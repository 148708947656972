import { TableHead, TableRow } from '@mui/material';
import React from 'react';
import { BrokerTableCell } from '../../containers/Brokers/brokerStyles';

const BrokerHead = () => {
  const titles = ['NOMBRE', 'MONTO TOTAL COMISIONES', ''];

  return (
    <TableHead>
      <TableRow>
        {titles.map((title, index) => (
          <BrokerTableCell key={index}>{title}</BrokerTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default BrokerHead;
