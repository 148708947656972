import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useStock from './useStock';
import useHistory from '../History/useHistory';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Button/Button';
import {
  LeftButtonsContainer,
  RightButtonsContainer,
  ButtonsContainer,
  StockContainer,
  StockTitle,
  StockTableContainer,
  TotalBalance,
  TotalContainer,
  TotalContainerOutline,
  TotalBalanceOutline,
} from './stockStyles';

import Searcher from '../../components/Searcher/Searcher';
import ButtonOutline from '../../components/Button/ButtonOutline';
import StockTable from '../../components/Stock/StockTable';
import { CSVLink } from 'react-csv';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import theme from '../../theme';
import SelectMui from '../../components/Select/SelectMui';
import formatNumber from '../../utils/formatNumber';
import CloseIcon from '@mui/icons-material/Close';
import useBrokers from '../Brokers/useBrokers';
import useClients from '../Clients/useClients';

const Stock = ({ notificationClicked }) => {
  const navigate = useNavigate();
  const { getClients, clients } = useClients();
  const {
    getStock,
    stock,
    setStock,
    desativateCurrency,
    loading,
    setLoading,
    stockForSearch,
    setStockForSearch,
    popperSx,
    isModalOpen,
    setIsModalOpen,
    handleCheckModal,
    selectedCurrency,
    handleChangeDifference,
    difference,
    handleClosure,
    handleCloseAll,
    checkAllClosed,
    customHandleClosure,
    currencies,
    setCurrencies,
    updateLocalStorageCurrencies,
    calculateTotalByCurrency,
    currentAccountsTotal,
    getTotalAnomalieValue,
    addUpInitialBalancesTotal,
    addUpInitialBalancesSubTotal,
    getStockHistoryByDate,
    stockHistory,
    handleOpenAll,
    isClosed,
    setIsClosed,
    handleIsClosed,
  } = useStock();

  const { getCommissions, commissions } = useBrokers();
  const { getHistoryByDate } = useHistory();

  const checkToday = () => {
    if (date === new Date().toISOString().split('T')[0]) {
      return true;
    } else {
      return false;
    }
  };

  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [flag, setFlag] = useState('opening');
  const [data, setData] = useState('real');
  const [filterTrash, setFilterTrash] = useState(false);

  const totalCommissions = () => {
    let totalCommissions = 0;

    if (Array.isArray(commissions)) {
      commissions.forEach((commission) => {
        if (typeof commission.amount === 'number') {
          totalCommissions += commission.amount;
        }
      });
    }
    return formatNumber(totalCommissions);
  };

  const pushData = (stock) => {
    const array = [];
    stock?.map((s) => {
      array.push([
        `${s.currency}`,
        `${s.exchange_rate}`,
        `${s.initial_balance}`,
        `${s.actual_balance}`,
        `${getTotalAnomalieValue(s)}`,
        `${s.actual_balance + getTotalAnomalieValue(s)}`,
      ]);
    });

    return array;
  };

  const handleFormatCsv = () => {
    const csvData = [
      [
        'Divisa',
        'Tipo de cambio',
        'Balance inicial',
        'Balance actual',
        'Anomalías',
        'Balance total',
      ],
      ...pushData(stock),
    ];

    return csvData;
  };

  const handleDateChange = (e) => {
    setDate(e);
  };

  const handleGetHistory = async () => {
    setLoading(true);
    const response = await getHistoryByDate(date);
    if (response) {
      setStock(response.message);
      setStockForSearch(response.message);
    } else {
      setStock([]);
    }
    setLoading(false);
  };

  const dateTodayAt8 = new Date().setHours(8, 0, 0, 0);
  const dateTodayAt21 = new Date().setHours(21, 0, 0, 0);

  const check8am = () => {
    if (new Date() > dateTodayAt8) {
      if (new Date() > dateTodayAt21) return false;
      return true;
    } else {
      return false;
    }
  };

  const check9pm = () => {
    if (new Date() > dateTodayAt21) {
      return true;
    } else {
      return false;
    }
  };

  const handleSelectChange = (e) => {
    const value = e;
    if (value === 'actual') return getStock();
    if (value === 'openingToday') return handleGetHistory();
    setFlag(value);
    setFilterTrash(true);
  };

  useEffect(() => {
    if (date === new Date().toISOString().split('T')[0]) {
      getStock();
    } else {
      handleGetHistory();
    }
  }, [date, flag]);

  useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate(`/operaciones/${notificationClicked.id}`);
    }
  }, [notificationClicked.isClicked]);

  const handleRefresh = () => {
    setDate(new Date().toISOString().split('T')[0]);
    if (checkToday()) {
      if (check8am()) {
        setFlag('actual');
        getStock('real');
      } else {
        setFlag('opening');
      }
    } else {
      setFlag('opening');
    }

    setData('real');
    setFilterTrash(false);
  };

  function getOptions() {
    if (checkToday()) {
      if (check8am()) {
        return [
          { name: 'ACTUAL', value: 'actual' },
          { name: 'APERTURA', value: 'openingToday' },
        ];
      } else if (check9pm()) {
        return [
          { name: 'APERTURA', value: 'opening' },
          { name: 'CIERRE', value: 'closing' },
        ];
      } else {
        return [
          { name: 'APERTURA', value: 'opening' },
          { name: 'CIERRE', value: 'closing' },
        ];
      }
    } else {
      return [
        { name: 'APERTURA', value: 'opening' },
        { name: 'CIERRE', value: 'closing' },
      ];
    }
  }

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];

    getCommissions();
    getClients();
    getStockHistoryByDate(today);
  }, []);

  useEffect(() => {
    if (clients && clients.length > 0) {
      calculateTotalByCurrency(clients);
    }
  }, [clients]);

  const options = getOptions();

  useEffect(() => {
    handleIsClosed(stock, stockHistory);
  }, [isClosed, stockHistory]);

  return (
    <StockContainer>
      <StockTitle>Stock</StockTitle>

      <ButtonsContainer>
        <LeftButtonsContainer>
          <Button
            onClick={() => navigate('/agregar-divisa')}
            title="NUEVA DIVISA"
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              value={date}
              OpenPickerButtonProps={{
                style: {
                  color: theme.palette.title.main,
                },
              }}
              disableMaskedInput={true}
              disableFuture={true}
              onChange={(newValue) => {
                setFilterTrash(true);
                handleDateChange(
                  new Date(newValue.$d).toISOString().split('T')[0],
                );
              }}
              PopperProps={{
                sx: popperSx,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    width: `${stock?.length === 0 ? '42%' : '30%'}`,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                      border: `1.5px solid ${theme.palette.title.main}`,
                      height: '40px',
                      margin: 0,
                      color: theme.palette.title.main,
                      fontWeight: '500',
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
          {stock?.length > 0 ? (
            <SelectMui
              date={date}
              context="stock-history"
              handleSelectChange={handleSelectChange}
              setFilterTrash={setFilterTrash}
              options={options}
            />
          ) : null}

          {checkAllClosed() && !loading && (
            <div
              style={{
                marginLeft: '1rem',
              }}
            >
              <Button
                onClick={() => (isClosed ? handleOpenAll() : handleCloseAll())}
                title={isClosed ? 'ABRIR STOCK' : 'CERRAR STOCK'}
              />
            </div>
          )}

          {filterTrash ? (
            <CloseIcon
              alt=""
              onClick={handleRefresh}
              sx={{ margin: '0.5rem', cursor: 'pointer', color: '#3f5071' }}
            />
          ) : null}
        </LeftButtonsContainer>
        <RightButtonsContainer>
          {date !== new Date().toISOString().split('T')[0] ? null : (
            <SelectMui
              context="stock-time"
              onChangeFunction={getStock}
              setFilterTrash={setFilterTrash}
              data={data}
              setData={setData}
              options={[
                { name: 'REAL', value: 'real' },
                { name: 'PROYECTADO', value: 'proyectada' },
              ]}
            />
          )}

          <CSVLink
            data={handleFormatCsv()}
            filename="stock.csv"
            target="_blank"
            separator={';'}
            style={{ textDecoration: 'none' }}
          >
            <ButtonOutline icon="csv" title="EXPORTAR CSV" />
          </CSVLink>
        </RightButtonsContainer>
      </ButtonsContainer>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '150px',
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <StockTableContainer>
            {checkToday() ? null : (
              <h2
                style={{
                  fontSize: '15px',
                  color: theme.palette.title.main,
                  display: 'flex',
                  justifyContent: 'center',
                  fontWeight: '400',
                }}
              >
                Historial de stock: {date.slice(8)}/{date.slice(5, 7)}/
                {date.slice(0, 4)}
              </h2>
            )}
            <Searcher
              context="stock"
              stockForSearch={stockForSearch}
              setStock={setStock}
              getStock={getStock}
              date={date}
            />
            <StockTable
              stock={stock}
              desativateCurrency={desativateCurrency}
              checkToday={checkToday}
              getTotalAnomalieValue={getTotalAnomalieValue}
              totalCommissions={totalCommissions}
              currentAccountsTotal={currentAccountsTotal}
              handleCheckModal={handleCheckModal}
              isOpen={isModalOpen}
              setIsOpen={setIsModalOpen}
              selectedCurrency={selectedCurrency}
              handleChangeDifference={handleChangeDifference}
              difference={difference}
              handleClosure={handleClosure}
              customHandleClosure={customHandleClosure}
              currencies={currencies}
              setCurrencies={setCurrencies}
              updateLocalStorageCurrencies={updateLocalStorageCurrencies}
            />
          </StockTableContainer>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <TotalContainerOutline>
              <TotalBalanceOutline>
                SUBTOTAL EN USD: ${addUpInitialBalancesSubTotal()}
              </TotalBalanceOutline>
            </TotalContainerOutline>
            <TotalContainer>
              <TotalBalance>
                TOTAL EN USD: ${addUpInitialBalancesTotal(commissions)}
              </TotalBalance>
            </TotalContainer>
          </div>
        </>
      )}
    </StockContainer>
  );
};

export default Stock;
