import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import Stock from './Stock';
import StockHead from './StockHead';
import theme from '../../theme';

const StockTable = ({
  stock,
  desativateCurrency,
  checkToday,
  getTotalAnomalieValue,
  totalCommissions,
  currentAccountsTotal,
  handleCheckModal,
  isOpen,
  setIsOpen,
  selectedCurrency,
  handleChangeDifference,
  difference,
  handleClosure,
  customHandleClosure,
  currencies,
  setCurrencies,
  updateLocalStorageCurrencies,
}) => {
  return (
    <TableContainer>
      {stock?.length === 0 ? (
        <h2 style={{ textAlign: 'center', color: theme.palette.title.main }}>
          No hay registros de stock en este día.
        </h2>
      ) : (
        <Table sx={{ width: '100%' }}>
          <StockHead />
          <TableBody>
            {stock?.map((s) => (
              <Stock
                key={s._id}
                id={s._id}
                stock={s}
                currency={s.currency}
                exchange_rate={s.exchange_rate}
                initial_balance={s.initial_balance}
                actual_balance={s.actual_balance}
                getTotalAnomalieValue={getTotalAnomalieValue}
                desativateCurrency={desativateCurrency}
                checkToday={checkToday}
                icon={s.icon}
                totalCommissions={totalCommissions}
                currentAccountsTotal={currentAccountsTotal}
                handleCheckModal={handleCheckModal}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                selectedCurrency={selectedCurrency}
                handleChangeDifference={handleChangeDifference}
                difference={difference}
                handleClosure={handleClosure}
                customHandleClosure={customHandleClosure}
                currencies={currencies}
                setCurrencies={setCurrencies}
                updateLocalStorageCurrencies={updateLocalStorageCurrencies}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default StockTable;
