import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { StyledContainer, BackgroundImage } from './loginStyles';
import axios from 'axios';

import env from '../../env';
import LogoAndRights from '../../components/LogoAndRights/LogoAndRights';
import LoginHeader from '../../components/LoginHeader/LoginHeader';
import LoginForm from '../../components/LoginForm/LoginForm';
import TwoFactor from './TwoFactor';
import { useSocket } from '../../utils/socket';

const theme = createTheme();

export default function Login() {
  const [error, setError] = React.useState('');
  const [show2FA, setShow2FA] = React.useState(false);
  const [userId, setUserId] = React.useState(null);
  const [token, setToken] = React.useState('');
  const navigate = useNavigate();
  const { connectSocket } = useSocket();

  const routesByRole = {
    superadmin: '/stock',
    operador_usdt: '/stock',
    tesoreria: '/stock',
    operador_pesos: '/stock',
    operador_cable: '/stock',
    operador_cambio: '/stock',
    operador_logistica: '/logistica',
    liquidador_caja: '/operaciones',
  };

  const getCurrentYear = () => {
    const date = new Date();
    return date.getFullYear();
  };

  const navigateToAppropriateRoute = (role) => {
    const route = routesByRole[role] || '/';
    navigate(route);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData(e.currentTarget);
    const email = form.get('email').trim();
    const password = form.get('password').trim();

    try {
      const { data } = await axios.post(`${env.API_URL}auth/login/cms`, {
        email,
        password,
      });

      if (data.status === 'Awaiting 2FA') {
        setUserId(data.userId);
        setShow2FA(true);
      } else {
        if (
          env.ENVIRONMENT === 'development' ||
          email === 'porcelhoracioo@gmail.com' ||
          email === 'micaelagcopello@gmail.com' ||
          email === 'miamorani@hotmail.com' ||
          email === 'eliseosegura3@gmail.com' ||
          email === 'evans.carolinai@gmail.com'
        ) {
          setError(data);
          localStorage.setItem('user', JSON.stringify(data));
          connectSocket();
          navigateToAppropriateRoute(data.role);
        } else {
          setError('Error al iniciar sesión.');
        }
      }
      setError('');
    } catch (error) {
      setError(error?.response?.data?.error);
    }
  };

  const handle2FASubmission = async () => {
    try {
      const { data } = await axios.post(`${env.API_URL}auth/login/cms`, {
        userId,
        token,
      });
      setError(data);
      localStorage.setItem('user', JSON.stringify(data));
      connectSocket();
      navigateToAppropriateRoute(data.role);
    } catch (error) {
      setError('Código 2FA inválido.');
    }
  };

  if (show2FA) {
    return (
      <TwoFactor
        token={token}
        setToken={setToken}
        handle2FASubmission={handle2FASubmission}
        error={error}
        getCurrentYear={getCurrentYear}
      />
    );
  }

  return (
    <BackgroundImage>
      <ThemeProvider theme={theme}>
        <LogoAndRights getCurrentYear={getCurrentYear} />
        <LoginHeader
          title="Iniciar sesión"
          subtitle="Ingrese su correo y contraseña para iniciar sesión"
          theme={theme}
        />
        <StyledContainer
          component="main"
          maxWidth="xs"
          sx={{ maxHeight: '60vh' }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <LoginForm
              onSubmit={handleSubmit}
              error={error}
              theme={theme}
              navigateFunction={() => navigate('/send-email')}
            />
          </Box>
        </StyledContainer>
      </ThemeProvider>
    </BackgroundImage>
  );
}
