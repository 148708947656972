import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import fetchFromApi from '../../utils/fetchFromApi';
import useNotistack from '../Notistack/useNotistack';
import jsDownload from 'js-file-download';
import formatNumber from '../../utils/formatNumber';
import useBrokers from '../Brokers/useBrokers';

const useStock = () => {
  const { getCommissions, commissions } = useBrokers();
  const { showNotification } = useNotistack();
  const [currentAccountsTotal, setCurrentAccountsTotal] = useState({});
  const [stock, setStock] = useState([]);
  const [stockForSearch, setStockForSearch] = React.useState([]);
  const [currency, setCurrency] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [difference, setDifference] = useState(0);
  const [currencies, setCurrencies] = useState(() => {
    const savedCurrencies = localStorage.getItem('currencies');
    return savedCurrencies ? JSON.parse(savedCurrencies) : {};
  });
  const [stockHistory, setStockHistory] = useState([]);
  const [isClosed, setIsClosed] = useState(false);
  const today = new Date().toISOString().split('T')[0];

  const navigate = useNavigate();

  const getStockHistoryByDate = async (date) => {
    try {
      const response = await fetchFromApi('GET', `stock-history/${date}`);
      if (response) {
        setStockHistory(response);
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStock = async (positionType) => {
    setLoading(true);
    try {
      const stockData = await fetchFromApi(
        'GET',
        `stock/?positionType=${positionType}`,
      );
      if (stockData) {
        setStock(stockData);
        setStockForSearch(stockData);

        const newCurrencies = {};
        stockData.forEach((s) => {
          newCurrencies[s.currency] = {
            isClosed: s.isClosed,
            difference: s.difference,
            actual_balance: s.actual_balance,
            exchange_rate: s.exchange_rate,
            initial_balance: s.initial_balance,
            isDigital: s.isDigital,
            stockId: s._id,
          };
        });

        const updatedCurrencies = Object.keys(currencies).length
          ? { ...currencies, ...newCurrencies }
          : newCurrencies;

        setCurrencies(updatedCurrencies);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const registerCurrency = async (
    currency,
    exchange_rate,
    initial_balance,
    isDigital,
    icon,
  ) => {
    setLoading(true);
    try {
      setErrors({});
      const response = await fetchFromApi('POST', 'stock', {
        currency,
        exchange_rate,
        initial_balance,
        isDigital,
        icon,
      });
      showNotification(response[1], 'success');
      getStock();
      navigate('/stock');
      setLoading(false);
    } catch (error) {
      error.response.data[0].map((e) => showNotification(e, 'error'));
      setErrors(error.response.data[1]);
      setLoading(false);
    }
  };

  const deleteCurrency = async (id) => {
    try {
      const result = await fetchFromApi('PUT', 'stock', id);
      if (result) {
        const newCurrencies = stock.filter((currency) => currency._id !== id);
        setStock(newCurrencies);
        showNotification(result[1], 'success');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrency = async (id) => {
    const effectiveId =
      typeof id === 'object' && id !== null && '_id' in id ? id._id : id;
    try {
      const response = await fetchFromApi('GET', `stock/${effectiveId}`);
      if (response) {
        setCurrency(response);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrencyByName = async (currency) => {
    try {
      const response = await fetchFromApi(
        'GET',
        `stock/getCurrencyByName/${currency}`,
      );

      return response._id;
    } catch (error) {
      console.log(error);
    }
  };

  const updateCurrency = async (id, currencyEdited) => {
    setLoading(true);
    try {
      setErrors({});
      const confirm = window.confirm('¿Estás seguro de editar esta divisa?');
      if (confirm) {
        const result = await fetchFromApi('PUT', `stock/${id}`, currencyEdited);
        if (result) {
          showNotification(result[1], 'success');
          setLoading(false);
        }
        navigate('/stock');
      }
    } catch (error) {
      error.response.data[0].map((e) => showNotification(e, 'error'));
      setErrors(error.response.data[1]);
      setLoading(false);
    }
  };

  const desativateCurrency = async (id) => {
    try {
      const result = await fetchFromApi('PUT', `stock/desactivate/${id}`);
      if (result) {
        const newCurrencies = stock.filter((currency) => currency._id !== id);
        setStock(newCurrencies);
        showNotification(result[1], 'success');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadCsv = async () => {
    const res = await fetchFromApi('GET', 'stock/csv', {
      responseType: 'blob',
    });
    if (res) {
      jsDownload(res, 'stock.csv');
    }
  };

  const popperSx = {
    '& .MuiPickersDay-today': {
      backgroundColor: '#F25456 !important',
      color: 'white',
      transition: 'none !important',
      border: '1px solid #F25456',
    },

    '& .Mui-selected': {
      backgroundColor: '#F25456 !important',
      transition: 'none',
      color: 'white',

      '&:hover': {
        backgroundColor: '#F25456 !important',
        color: 'white',
      },

      '&:focus': {
        backgroundColor: '#F25456 !important',
        color: 'white',
      },

      '&:active': {
        backgroundColor: '#F25456 !important',
        color: 'white',
      },
    },
  };

  const updateIsClosed = async (currencyId, isClosed, difference) => {
    try {
      await fetchFromApi('PUT', `stock/uppdateIsClosed/${currencyId}`, {
        isClosed,
        difference,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckModal = async (currency) => {
    setSelectedCurrency(currency);
    setIsModalOpen(true);
    setCurrencies((prev) => {
      const updatedCurrencies = {
        ...prev,
        [currency.currency]: {
          ...prev[currency.currency],
        },
      };
      return updatedCurrencies;
    });
  };

  const handleChangeDifference = (e) => {
    setDifference(e?.target?.value);
  };

  const handleClosure = (currency, difference) => {
    const updatedCurrencies = {
      ...currencies,
      [currency]: {
        ...currencies[currency],
        isClosed: true,
        difference,
        actual_balance: stock.find((s) => s.currency === currency)
          .actual_balance,
        exchange_rate: stock.find((s) => s.currency === currency).exchange_rate,
        initial_balance: stock.find((s) => s.currency === currency)
          .initial_balance,
        anomalie: stock.find((s) => s.currency === currency).anomalie,
        operation: stock.find((s) => s.currency === currency).operation,
        isDigital: stock.find((s) => s.currency === currency).isDigital,
        stockId: stock.find((s) => s.currency === currency)._id,
      },
    };
    setCurrencies(updatedCurrencies);
    setIsModalOpen(false);
  };

  const customHandleClosure = async (currency, difference) => {
    handleClosure(currency, difference);
    setCurrencies((prev) => ({
      ...prev,
      [currency]: {
        ...prev[currency],
        isClosed: true,
        difference,
      },
    }));

    const currencyName = await getCurrencyByName(currency);
    await updateIsClosed(currencyName, true, difference);
    setDifference(0);
    setIsModalOpen(false);
  };

  const checkAllClosed = () => {
    if (Object.keys(currencies).length !== stock?.length) {
      return false;
    }

    return Object.values(currencies).every(
      (currency) => currency.isClosed && currency.difference === 0,
    );
  };

  const handleCloseAll = async () => {
    if (checkAllClosed()) {
      try {
        // eslint-disable-next-line no-restricted-globals
        const confirmation = confirm(
          '¿Estás seguro de cerrar todas las divisas?',
        );
        if (confirmation) {
          const result = await fetchFromApi(
            'POST',
            'stock-history',
            currencies,
          );
          if (result) {
            showNotification(result.message, 'success');
            await getCommissions();
            alert(
              `Balance total en USD: ${formatNumber(
                addUpInitialBalancesTotal('', commissions),
              )}`,
            );
            window.location.reload();
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log('No todas las divisas están cerradas.');
    }
  };

  const calculateTotalByCurrency = (clients) => {
    const totals = {};

    clients?.forEach((client) => {
      client?.currentAccount?.forEach((account) => {
        const {
          currency: { name: currencyName },
          amount,
        } = account;
        if (!totals[currencyName]) {
          totals[currencyName] = 0;
        }
        totals[currencyName] += amount;
      });
    });

    setCurrentAccountsTotal(totals);
  };

  const getTotalAnomalieValue = (currency, data) => {
    let anomalieValue = 0;
    currency.anomalie.forEach((a) => {
      const isPendingSubOperation =
        a.operation[0]?.subOperations?.[a.index]?.status === 'Pendiente' &&
        data === 'real';
      const isPendingOperation =
        a.operation[0]?.status === 'Pendiente' && data === 'real';

      if (
        isPendingSubOperation ||
        isPendingOperation ||
        a.status !== 'active'
      ) {
        return;
      }

      if (a.type === 'input') {
        anomalieValue += a.amount;
      } else if (a.type === 'output') {
        anomalieValue -= a.amount;
      }
    });

    return anomalieValue;
  };

  const addUpInitialBalancesTotal = (data, commissions) => {
    let totalCommissions = 0;
    let subTotal = 0;

    if (Array.isArray(stock)) {
      stock.forEach((currency) => {
        if (currency.exchange_rate) {
          subTotal += currency.actual_balance / currency.exchange_rate;
          subTotal +=
            getTotalAnomalieValue(currency, data) / currency.exchange_rate;
        }
      });
    }

    if (Array.isArray(commissions)) {
      commissions.forEach((commission) => {
        if (typeof commission.amount === 'number') {
          totalCommissions += commission.amount;
        }
      });
    }

    const total = subTotal - totalCommissions;
    return formatNumber(total);
  };

  const addUpInitialBalancesSubTotal = (data) => {
    let subTotal = 0;
    stock?.forEach((currency) => {
      if (currency.exchange_rate !== 0) {
        subTotal += currency.actual_balance / currency.exchange_rate;
        subTotal +=
          getTotalAnomalieValue(currency, data) / currency.exchange_rate;
      }
    });
    return formatNumber(subTotal);
  };

  const handleOpenAll = async () => {
    try {
      const response = await fetchFromApi('POST', 'stock/updateAllIsClosed');
      if (response) {
        showNotification(response.message, 'success');
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleIsClosed = (stock, stockHistory) => {
    const isAllStockClosed = stock?.every((s) => {
      return s.isClosed === true;
    });

    if (stockHistory?.message?.length > 0 && isAllStockClosed) {
      setIsClosed(true);
    } else {
      setIsClosed(false);
    }
  };

  useEffect(() => {
    getStock();
  }, []);

  return {
    registerCurrency,
    getStock,
    deleteCurrency,
    getCurrency,
    updateCurrency,
    currency,
    stock,
    setStock,
    desativateCurrency,
    loading,
    stockForSearch,
    errors,
    setStockForSearch,
    downloadCsv,
    setLoading,
    isModalOpen,
    setIsModalOpen,
    popperSx,
    handleCheckModal,
    selectedCurrency,
    handleChangeDifference,
    difference,
    currencies,
    handleCloseAll,
    checkAllClosed,
    customHandleClosure,
    setCurrencies,
    calculateTotalByCurrency,
    currentAccountsTotal,
    getTotalAnomalieValue,
    updateIsClosed,
    getCurrencyByName,
    addUpInitialBalancesTotal,
    addUpInitialBalancesSubTotal,
    getStockHistoryByDate,
    stockHistory,
    handleOpenAll,
    isClosed,
    setIsClosed,
    handleIsClosed,
  };
};

export default useStock;
