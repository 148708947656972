/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HomeContainer } from '../Layout/homeStyles';
import OperationsTable from '../../components/Operation/OperationsTable';
import { useOperations } from './useOperations';
import useClients from '../Clients/useClients';
import useStock from '../Stock/useStock';
import {
  AllOperationsButton,
  DoubleOperationTableContainer,
  FilterAndSearcherContainer,
  LoaderContainer,
  OperationTableContentContainer,
  OperationsContainer,
  OperationsTitle,
  TableTitle,
} from './operationsStyles';
import Searcher from '../../components/Searcher/Searcher';
import Loader from '../../components/Loader/Loader';
import FilterPopover from './FilterPopover';
import { selectOperationsOptions } from '../../utils/constants';
import useUsers from '../Team/useUsers';
import useAuth from '../Login/useAuth';
import RenderButtons from '../../components/Operations/RenderButtons';
import RenderSelectAndActions from '../../components/Operations/RenderSelectAndActions';

const Operations = ({ notificationClicked, setNotificationClicked }) => {
  const navigate = useNavigate();
  const user = useAuth();
  const { getProfile } = useUsers();
  const {
    getOperations,
    deleteOperation,
    operations,
    numberOfPages,
    loading,
    getOperationsForCSV,
    operationsCSV,
    getAllOperationsWithoutPaginate,
    checkOperation,
    filters,
    setFilters,
    postSave,
    setPostSave,
    anchorEl,
    setAnchorEl,
    page,
    setPage,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selected,
    setSelected,
    checked,
    setChecked,
    filtersScopy,
    isLoading,
    getFullUrl,
    handleSelectOperation,
    handleSelectAllOperations,
    handleChangeSelect,
    handleMultipleDelete,
    operationsUnchecked,
    operationsChecked,
  } = useOperations();

  const { getStock, stock } = useStock();
  const { clients, getClients } = useClients();

  useEffect(() => {
    getStock();
    getClients();
    getOperationsForCSV();
    getAllOperationsWithoutPaginate();
  }, []);

  useEffect(() => {
    if (getFullUrl() === 'operaciones') {
      getOperations(filters, page);
    }
  }, [page]);

  useEffect(() => {
    if (user) {
      getProfile(user?.id);
    }
  }, []);

  useEffect(() => {
    if (notificationClicked.isClicked) {
      getOperations({ search: notificationClicked.id }, 1, 'notification');
      setNotificationClicked({ isClicked: false });
    }
  }, [notificationClicked.isClicked]);

  const pathAfterSecondSlash = window.location.pathname.split('/')[2];

  useEffect(() => {
    if (pathAfterSecondSlash !== undefined) {
      getOperations({ search: pathAfterSecondSlash }, 1, 'notification');
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getOperations(filters, page);
    }, 120000);

    return () => {
      clearInterval(intervalId);
    };
  }, [filters, page]);

  const renderOperationsTable = (title, operations, isDisabled) => (
    <>
      {user.role === 'tesoreria' ? <TableTitle>{title}</TableTitle> : null}
      <OperationsTable
        user={user}
        deleteOperation={deleteOperation}
        setPage={setPage}
        operations={operations}
        page={page}
        numberOfPages={numberOfPages}
        handleSelectOperation={handleSelectOperation}
        handleSelectAllOperations={handleSelectAllOperations}
        selected={selected}
        loading={loading}
        checked={checked}
        filters={filters}
        getOperations={getOperations}
        checkOperation={checkOperation}
        isDisabled={isDisabled}
      >
        <OperationTableContentContainer>
          <AllOperationsButton
            style={{
              visibility: `${selected.length === 0 ? 'hidden' : 'visible'}`,
            }}
            name="all-operations"
            onClick={handleSelectAllOperations}
          >
            Seleccionar las operaciones de todas las paginas
          </AllOperationsButton>
          <FilterAndSearcherContainer>
            <FilterPopover
              setFilters={setFilters}
              setAnchorEl={setAnchorEl}
              setPage={setPage}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              getOperations={getOperations}
              filters={filters}
              anchorEl={anchorEl}
              stock={stock}
              clients={clients}
              startDate={startDate}
              endDate={endDate}
              page={page}
              filtersScopy={filtersScopy}
              postSave={postSave}
              setPostSave={setPostSave}
            />
            <Searcher
              context={'operations'}
              setFilters={setFilters}
              getOperations={getOperations}
              setPage={setPage}
              page={page}
              filters={filters}
            />
          </FilterAndSearcherContainer>
        </OperationTableContentContainer>
      </OperationsTable>
    </>
  );

  return (
    <HomeContainer style={{ paddingLeft: '240px' }}>
      <OperationsContainer>
        <OperationsTitle>Operaciones</OperationsTitle>
        {selected.length === 0 ? (
          <RenderButtons
            operationsCSV={operationsCSV}
            navigate={navigate}
            userRole={user?.role}
          />
        ) : (
          <RenderSelectAndActions
            handleMultipleDelete={handleMultipleDelete}
            handleChangeSelect={handleChangeSelect}
            selectOperationsOptions={selectOperationsOptions}
            selected={selected}
            isLoading={isLoading}
            setSelected={setSelected}
            setChecked={setChecked}
          />
        )}
        {loading ? (
          <LoaderContainer>
            <Loader style={{ marginTop: '250px' }} />
          </LoaderContainer>
        ) : (
          <>
            {user?.role === 'tesoreria' ? (
              <DoubleOperationTableContainer>
                {renderOperationsTable(
                  'Operaciones sin asignar',
                  operationsUnchecked,
                  true,
                )}
                {renderOperationsTable(
                  'Operaciones asignadas',
                  operationsChecked,
                )}
              </DoubleOperationTableContainer>
            ) : (
              renderOperationsTable('Operaciones', operations)
            )}
          </>
        )}
      </OperationsContainer>
    </HomeContainer>
  );
};

export default Operations;
