import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import ClientsIndividual from '../../components/Clients/ClientsIndividual';
import ClientsHead from './ClientsHead';
import Paginate from '../../components/Paginate/Paginate';
import Searcher from '../Searcher/Searcher';
import { useStyles } from './ClientsTableStyles';

const ClientsTable = ({
  clients,
  page,
  search,
  setSearch,
  setPage,
  loading,
  getClientsPaginate,
  deleteClient,
  totalPages,
}) => {
  const classes = useStyles();

  const renderNoClientsMessage = () => (
    <h2 className={classes.noClientsMessage}>No hay registros de clientes.</h2>
  );

  const renderClientsTable = () => (
    <>
      <TableContainer>
        <Table sx={{ width: '100%', margin: 'auto' }}>
          <ClientsHead />
          <TableBody>
            {clients.map((client, i) => (
              <ClientsIndividual
                key={i}
                client={client}
                deleteClient={deleteClient}
                search={search}
                page={page}
              />
            ))}
          </TableBody>
        </Table>
        <Paginate
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          search={search}
          getFunction={getClientsPaginate}
        />
      </TableContainer>
    </>
  );

  return (
    <div>
      <Searcher
        setSearch={setSearch}
        search={search}
        context="clients"
        getClientsPaginate={getClientsPaginate}
        setPage={setPage}
      />
      {!clients.length && !loading
        ? renderNoClientsMessage()
        : renderClientsTable()}
    </div>
  );
};

export default ClientsTable;
