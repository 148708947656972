import React from 'react';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import theme from '../../../theme';
import { operationTypes } from '../../../utils/constants';
import { NumericFormat } from 'react-number-format';
import Loader from '../../../components/Loader/Loader';
import { FormLoaderContainer } from '../operationsStyles';

const CurrentAccountForm = ({
  stock,
  handleInputChange,
  clients,
  deliverys,
  operation,
  setOperation,
  errors,
  context,
  isCurrentOperationInstitutional,
  isClientsLoading,
  isStockLoading,
  isDeliveriesLoading,
}) => {
  const defaultValue = operation.liquidator_type === 'DELIVERY' ? true : false;

  const handleCheck = () => {
    setOperation((prevState) => {
      return {
        ...prevState,
        liquidator_type: !defaultValue ? 'DELIVERY' : '',
      };
    });
  };

  return isStockLoading && isClientsLoading && isDeliveriesLoading ? (
    <FormLoaderContainer>
      <Loader style={{ marginTop: '50px' }} />
    </FormLoaderContainer>
  ) : (
    <>
      {/* TIPO DE OPERACION */}
      <FormControl variant="standard" fullWidth>
        <InputLabel
          sx={{ color: `${theme.palette.inputText.main}` }}
          id="input"
        >
          Tipo de Operacion
        </InputLabel>
        <Select
          labelId="input"
          name="operation_type"
          onChange={(event) => handleInputChange(event)}
          value={operation.operation_type}
        >
          <ListSubheader>OPERACIONES FINANCIERAS</ListSubheader>
          {operationTypes().map((o, i) => {
            if (i > 7) return null;
            return (
              <MenuItem
                value={o}
                key={i}
                disabled={context === 'edit' && isCurrentOperationInstitutional}
              >
                {o}
              </MenuItem>
            );
          })}
          <ListSubheader>OPERACIONES INSTITUCIONALES</ListSubheader>

          {context === 'edit'
            ? ['EGRESO CTA.CORRIENTE', 'INGRESO CTA.CORRIENTE'].map((o, i) => {
                return (
                  <MenuItem value={o} key={i}>
                    {o}
                  </MenuItem>
                );
              })
            : operationTypes().map((o, i) => {
                if (i < 8) return null;
                return (
                  <MenuItem value={o} key={i}>
                    {o}
                  </MenuItem>
                );
              })}
        </Select>
      </FormControl>
      {/* {CLIENTES} */}
      {clients?.length > 0 ? (
        <Autocomplete
          options={clients}
          getOptionLabel={(client) => client.name}
          value={operation.client ? operation.client : null}
          renderInput={(params) => (
            <TextField
              error={errors?.client}
              {...params}
              label="Clientes"
              variant="standard"
              InputLabelProps={{
                style: {
                  color: `${theme.palette.inputText.main}`,
                },
              }}
              sx={{
                input: {
                  color: `${theme.palette.inputText.main}`,
                },
                '& .MuiInputLabel-root': { color: '#D3D6DA' },
              }}
            />
          )}
          onChange={(event, value) => {
            setOperation((prevState) => {
              return {
                ...prevState,
                client: value,
                address: value?.address,
              };
            });
          }}
        />
      ) : null}
      {/* {DELIVERY} */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        {deliverys?.length > 0 ? (
          <Autocomplete
            sx={{ width: '100%' }}
            options={deliverys}
            getOptionLabel={(delivery) => delivery.name}
            value={operation.assignedDelivery}
            renderInput={(params) => (
              <TextField
                error={errors?.user}
                {...params}
                label="Asignar Delivery"
                variant="standard"
                InputLabelProps={{
                  style: {
                    color: `${theme.palette.inputText.main}`,
                  },
                }}
                sx={{
                  input: {
                    color: `${theme.palette.inputText.main}`,
                  },
                  '& .MuiInputLabel-root': { color: '#D3D6DA' },
                }}
              />
            )}
            onChange={(event, value) => {
              setOperation((prevState) => {
                return {
                  ...prevState,
                  assignedDelivery: value,
                  liquidator_type: value ? 'DELIVERY' : null,
                };
              });
            }}
          />
        ) : null}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          gap: '10px',
          borderBottom: '1px solid #D3D6DA',
          paddingTop: '10px',
          paddingBottom: '0px',
        }}
      >
        <InputLabel sx={{ color: `${theme.palette.inputText.main}` }}>
          ¿La operación es de tipo liquidador delivery?
        </InputLabel>
        <Checkbox
          sx={{
            color: 'gray',
            '&.Mui-checked': {
              color: '#384764',
            },
          }}
          checked={defaultValue}
          onChange={() => {
            handleCheck();
          }}
        />
      </div>

      {/* {DIVISA DE ENTRADA} */}
      {operation.operation_type === 'INGRESO CTA.CORRIENTE' ? (
        <>
          <FormControl
            variant="standard"
            error={errors?.input_currency}
            sx={{
              marginTop: '10px',
              marginBottom: '20px',
            }}
          >
            <InputLabel
              sx={{ color: `${theme.palette.inputText.main}` }}
              id="input"
            >
              Divisa de Entrada
            </InputLabel>
            <Select
              labelId="input"
              onChange={(event) => handleInputChange(event)}
              name="input_currency"
              value={operation.input_currency}
            >
              {stock?.map((s) => {
                return (
                  <MenuItem value={s._id} key={s._id} disabled={s.isClosed}>
                    {s.currency}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* MONTO DE ENTRADA */}

          <NumericFormat
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            customInput={TextField}
            variant="standard"
            label="Monto"
            value={operation.input_amount}
            onValueChange={(values) =>
              handleInputChange({
                target: { value: values.floatValue, name: 'input_amount' },
              })
            }
            InputLabelProps={{
              style: { color: `${theme.palette.inputText.main}` },
            }}
            sx={{
              input: {
                color: `${theme.palette.inputText.main}`,
              },
              '& .MuiInputLabel-root': { color: '#D3D6DA' },
              borderBottom: '1px solid #D3D6DA',
            }}
            InputProps={{ disableUnderline: !errors?.input_amount }}
            error={errors?.input_amount}
          />
        </>
      ) : (
        <>
          <FormControl
            variant="standard"
            error={errors?.output_currency}
            sx={{
              marginTop: '10px',
              marginBottom: '20px',
            }}
          >
            <InputLabel
              sx={{ color: `${theme.palette.inputText.main}` }}
              id="output"
            >
              Divisa de Salida
            </InputLabel>
            <Select
              labelId="output"
              onChange={(event) => handleInputChange(event)}
              name="output_currency"
              value={
                operation?.output_currency?._id
                  ? operation?.output_currency?._id
                  : operation?.output_currency
              }
            >
              {stock?.map((s) => {
                return (
                  <MenuItem value={s._id} key={s._id} disabled={s.isClosed}>
                    {s.currency}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* MONTO DE SALIDA */}
          <NumericFormat
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            customInput={TextField}
            variant="standard"
            label="Monto de salida"
            value={operation.output_amount}
            onValueChange={(values) =>
              handleInputChange({
                target: { value: values.floatValue, name: 'output_amount' },
              })
            }
            InputLabelProps={{
              style: { color: `${theme.palette.inputText.main}` },
            }}
            sx={{
              input: {
                color: `${theme.palette.inputText.main}`,
              },
              '& .MuiInputLabel-root': { color: '#D3D6DA' },
              borderBottom: '1px solid #D3D6DA',
            }}
            InputProps={{ disableUnderline: !errors.output_amount }}
            error={errors.output_amount}
            name="output_amount"
          />
        </>
      )}
      {/* COMENTARIOS */}
      <TextField
        InputLabelProps={{
          style: { color: `${theme.palette.inputText.main}` },
        }}
        sx={{
          input: {
            color: `${theme.palette.inputText.main}`,
          },
          '& .MuiInputLabel-root': { color: '#D3D6DA' },
          borderBottom: '1px solid #D3D6DA',
        }}
        InputProps={{ disableUnderline: true }}
        variant="standard"
        type="text"
        name="comments"
        label="Comentarios"
        onChange={(event) => handleInputChange(event)}
        fullWidth={true}
      />
    </>
  );
};

export default CurrentAccountForm;
