import React from 'react';
import { UserTableContainer } from '../../containers/Team/userStyles';
import { Table, TableContainer, TableBody } from '@mui/material';
import BusinessHead from './BusinessHead';
import BusinessUnitsTableIndividual from './BusinessUnitsTableIndivudal';

const BusinessUnitsTable = ({ data, dataKeys, headCells }) => {
  return (
    <UserTableContainer>
      <TableContainer>
        <Table>
          <BusinessHead headCells={headCells} />
          <TableBody>
            <BusinessUnitsTableIndividual data={data} dataKeys={dataKeys} />
          </TableBody>
        </Table>
      </TableContainer>
    </UserTableContainer>
  );
};

export default BusinessUnitsTable;
