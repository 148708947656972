import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import theme from '../../theme';
import formatNumber from '../../utils/formatNumber';
import HistoryIcon from '@mui/icons-material/History';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const Delivery = ({
  name,
  position,
  object,
  handlePositionHistoryVisibility,
  handleAddPosition,
  handleRemovePosition,
}) => {
  const cellStyles = {
    color: object.selected ? 'white' : theme.palette.tableText.main,
    ':hover': { cursor: 'pointer' },
    borderBottom: 'none',
  };

  const renderPositionCells = () => {
    return position?.map((p, i) => (
      <TableCell key={i} component="th" scope="row" sx={cellStyles}>
        {p.currency.name}: {formatNumber(p.balance)}
      </TableCell>
    ));
  };

  return (
    <TableRow
      sx={{
        backgroundColor: object.selected ? '#F25456' : 'transparent',
      }}
    >
      <TableCell component="th" scope="row" sx={cellStyles}>
        {name}
      </TableCell>
      <TableCell>
        <AddCircleOutlineIcon
          sx={{
            color: 'green',
            cursor: 'pointer',
          }}
          onClick={() => handleAddPosition(object?._id)}
        />
      </TableCell>
      <TableCell>
        <RemoveCircleOutlineIcon
          sx={{
            color: 'red',
            cursor: 'pointer',
          }}
          onClick={() => handleRemovePosition(object?._id)}
        />
      </TableCell>
      <TableCell>
        <HistoryIcon
          sx={{
            color: theme.palette.tableText.main,
            cursor: 'pointer',
          }}
          onClick={() => handlePositionHistoryVisibility(object?._id)}
        />
      </TableCell>
      {renderPositionCells()}
    </TableRow>
  );
};

export default Delivery;
