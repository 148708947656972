import { useEffect, useMemo, useState } from 'react';

const useSubOperations = (operation) => {
  const initialSubOps = useMemo(() => {
    return operation?.subOperations?.length > 0
      ? operation.subOperations.map((subOp) => ({
          address: subOp.address,
          delivery: subOp.assignedDelivery,
          inputAmount: subOp.input_amount,
          outputAmount: subOp.output_amount,
          type: subOp.type,
          anomalie: subOp.anomalie,
          status: subOp.status,
          liquidatorType: subOp.liquidatorType,
        }))
      : [
          {
            address: operation.address,
            delivery: operation.assignedDelivery,
            inputAmount: operation.input_amount,
            outputAmount: operation.output_amount,
            type: '1',
            anomalie: [],
            status: 'Pendiente',
            liquidatorType: 'DELIVERY',
          },
          {
            address: operation.address,
            delivery: operation.assignedDelivery,
            inputAmount: 0,
            outputAmount: 0,
            type: '1',
            anomalie: [],
            status: 'Pendiente',
            liquidatorType: 'DELIVERY',
          },
        ];
  }, [operation]);

  const [subOps, setSubOps] = useState(initialSubOps);

  const totals = useMemo(() => {
    if (subOps.length > 0) {
      const totalInputAmount = subOps
        .slice(0, -1)
        .reduce((sum, subOp) => sum + (parseFloat(subOp.inputAmount) || 0), 0);
      const totalOutputAmount = subOps
        .slice(0, -1)
        .reduce((sum, subOp) => sum + (parseFloat(subOp.outputAmount) || 0), 0);

      return {
        totalInputAmount: operation.input_amount - totalInputAmount,
        totalOutputAmount: operation.output_amount - totalOutputAmount,
      };
    }
    return {
      totalInputAmount: operation.input_amount,
      totalOutputAmount: operation.output_amount,
    };
  }, [subOps, operation.input_amount, operation.output_amount]);

  useEffect(() => {
    if (subOps.length > 0) {
      const lastSubOp = subOps[subOps.length - 1];
      if (
        lastSubOp.inputAmount !== totals.totalInputAmount ||
        lastSubOp.outputAmount !== totals.totalOutputAmount
      ) {
        const newSubOps = [...subOps];
        newSubOps[newSubOps.length - 1].inputAmount = totals.totalInputAmount;
        newSubOps[newSubOps.length - 1].outputAmount = totals.totalOutputAmount;
        setSubOps(newSubOps);
      }
    }
  }, [totals, subOps]);

  const handleInputChange = (event, index, field) => {
    let value = event.target.value;
    const newSubOps = [...subOps];

    // Allow empty input to clear the field
    if (value === '') {
      newSubOps[index][field] = '';
      setSubOps(newSubOps);
      return;
    }

    value = parseFloat(value) || 0;

    // Update the specific field
    newSubOps[index][field] = value;

    // Recalculate totals excluding the last sub-operation
    const totalInputAmount = newSubOps.reduce((sum, subOp, i) => {
      if (i !== index && subOp.status !== 'Pendiente') {
        return sum + (parseFloat(subOp.inputAmount) || 0);
      }
      return sum;
    }, 0);

    const totalOutputAmount = newSubOps.reduce((sum, subOp, i) => {
      if (i !== index && subOp.status !== 'Pendiente') {
        return sum + (parseFloat(subOp.outputAmount) || 0);
      }
      return sum;
    }, 0);

    // Ensure no negative totals for the pending sub-operations
    if (
      (field === 'inputAmount' &&
        totalInputAmount + value > operation.input_amount) ||
      (field === 'outputAmount' &&
        totalOutputAmount + value > operation.output_amount)
    ) {
      alert(
        'Los montos de las suboperaciones pendientes no pueden superar los montos restantes de la operación original.',
      );
      return;
    }

    // Ensure no negative suboperation values
    if (value < 0) {
      alert('Los montos de las suboperaciones no pueden ser negativos.');
      return;
    }

    setSubOps(newSubOps);
  };

  const addSubOperation = () => {
    setSubOps([
      ...subOps,
      {
        address: operation.address,
        delivery: '',
        inputAmount: 0,
        outputAmount: 0,
        type: '',
        anomalie: [],
        status: 'Pendiente',
        liquidatorType: 'DELIVERY',
      },
    ]);
  };

  const removeSubOperation = (index) => {
    const pendingCount = subOps?.filter(
      (subOp) => subOp.status === 'Pendiente',
    ).length;

    if (subOps.length > 2 && pendingCount > 1) {
      const newSubOps = subOps.filter((_, i) => i !== index);
      setSubOps(newSubOps);
    } else {
      alert('Debe haber al menos dos suboperaciones.');
    }
  };

  const validateSubOperations = () => {
    let totalInputAmount = 0;
    let totalOutputAmount = 0;

    for (const subOp of subOps) {
      const inputAmount = parseFloat(subOp.inputAmount);
      const outputAmount = parseFloat(subOp.outputAmount);

      if (inputAmount === 0 && outputAmount === 0) {
        alert('No puede haber una suboperación con ambos montos en 0.');
        return false;
      }

      if (isNaN(inputAmount) && isNaN(outputAmount)) {
        alert('No puede haber una suboperación con ambos montos en 0.');
        return false;
      }

      totalInputAmount += inputAmount;
      totalOutputAmount += outputAmount;
    }

    if (
      totalInputAmount > operation.input_amount ||
      totalOutputAmount > operation.output_amount ||
      totalInputAmount < 0 ||
      totalOutputAmount < 0
    ) {
      alert(
        'Los montos de las suboperaciones no pueden superar los montos de la operación original ni ser negativos.',
      );
      return false;
    }

    return true;
  };

  const areAllSubOpsNotPending = () => {
    return subOps?.every((subOp) => subOp.status !== 'Pendiente');
  };
  return {
    subOps,
    setSubOps,
    totals,
    handleInputChange,
    addSubOperation,
    removeSubOperation,
    validateSubOperations,
    areAllSubOpsNotPending,
  };
};

export default useSubOperations;
