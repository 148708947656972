import React, { useEffect, useState } from 'react';
import LogisticOperationTable from '../../components/Logistic/LogisticOperationTable';
import { useOperations } from '../Operations/useOperations';
import { OperationContainer, LogisticSubTitle } from './logisticStyles';
import useUsers from '../Team/useUsers';
import useAuth from '../Login/useAuth';

const LogisticOperations = ({ filter }) => {
  const user = useAuth();

  const {
    getOperationsWithouDeliveries,
    operationsWithoutDeliveries,
    loading,
    numberOfPagesWithoutDeliveries,
  } = useOperations();
  const { getDeliverys, deliverys } = useUsers();
  const [page, setPage] = useState(1);
  const [filteredOperations, setFilteredOperations] = useState([]);

  useEffect(() => {
    getDeliverys();
  }, []);

  useEffect(() => {
    getOperationsWithouDeliveries(filter, page, 'logistic');
  }, [filter, page]);

  useEffect(() => {
    const updatedOperations = operationsWithoutDeliveries.filter(
      (o) => o.status !== 'Finalizado' && !o.assignedDelivery,
    );

    const usdtOperations = updatedOperations.filter(
      (o) => o.operation_type === 'COMP.VTA.USDT' && o.status !== 'Finalizado',
    );

    setFilteredOperations(
      user?.role === 'operador_usdt' ? usdtOperations : updatedOperations,
    );
  }, [operationsWithoutDeliveries]);

  return (
    <OperationContainer>
      <LogisticSubTitle>Operaciones sin asignar</LogisticSubTitle>
      <LogisticOperationTable
        operations={filteredOperations}
        deliverys={deliverys}
        loading={loading}
        page={page}
        setPage={setPage}
        numberOfPages={numberOfPagesWithoutDeliveries}
        filter={filter}
      />
    </OperationContainer>
  );
};

export default LogisticOperations;
