export default function operationFinacialOrinstitutional(operation_type) {
  //Devuelve TRUE si la operacion es institucional
  return (
    operation_type === 'PAGO SERV/PROV' ||
    operation_type === 'PAGO SUELDOS' ||
    operation_type === 'LIQ.COMISIONES' ||
    operation_type === 'INGRESO CTA.CORRIENTE' ||
    operation_type === 'ENTREGO/SALE POS.DELIVERY' ||
    operation_type === 'RECIBO/ENTRA POS.DELIVERY' ||
    operation_type === 'EGRESO CTA.CORRIENTE' ||
    operation_type === 'TRASPASO CTA.CORRIENTE'
  );
}
