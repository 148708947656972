import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Typography,
} from '@mui/material';
import React from 'react';
import formatNumber from '../../utils/formatNumber';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';

import {
  ModalPositionHistory,
  ContentModalContainer,
  ButtonAndDatePickerContainer,
  DatePickerContainer,
  NoHistory,
} from '../../containers/Logistic/logisticStyles';

const PositionHistoryModal = ({
  isPositionHistoryVisible,
  positionHistory,
  setIsPositionHistoryVisible,
  isDatePickerVisible,
  setIsDatePickerVisible,
  handleChangeDate,
  date,
  positionHistoryLoading,
}) => {
  return (
    <div>
      <ModalPositionHistory
        open={isPositionHistoryVisible}
        onClose={() => setIsPositionHistoryVisible(!isPositionHistoryVisible)}
      >
        <ContentModalContainer>
          <ButtonAndDatePickerContainer>
            <DatePickerContainer>
              <Button
                onClick={() => setIsDatePickerVisible(!isDatePickerVisible)}
                variant="text"
                color="primary"
                title="Filtrar por fecha"
              />
              {isDatePickerVisible && (
                <DatePicker
                  maxDate={new Date()}
                  onChange={(newDate) => handleChangeDate(newDate)}
                  dateFormat={'dd/MM/yyyy'}
                  selected={date.toDate()}
                />
              )}
            </DatePickerContainer>
          </ButtonAndDatePickerContainer>
          {positionHistory?.length > 0 && !positionHistoryLoading ? (
            <Table sx={{ minWidth: '700px', backgroundColor: 'white' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Monto de entrada</TableCell>
                  <TableCell>Moneda de entrada</TableCell>
                  <TableCell>Moneda de salida</TableCell>
                  <TableCell>Monto de salida</TableCell>
                  <TableCell>TC</TableCell>
                  <TableCell>Detalle</TableCell>
                  <TableCell>Fecha</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {positionHistory.map((p, i) => (
                  <TableRow key={i} >
                    <TableCell>{p?.clientName}</TableCell>
                    <TableCell>{p?.type}</TableCell>
                    <TableCell>{formatNumber(p.inputAmount)}</TableCell>
                    <TableCell>{p?.inputCurrency}</TableCell>
                    <TableCell>{p?.outputCurrency}</TableCell>
                    <TableCell>{formatNumber(p.outputAmount)}</TableCell>
                    <TableCell>{formatNumber(p?.tc)}</TableCell>
                    <TableCell>{p?.details}</TableCell>
                    <TableCell>{p?.dateCreated}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : positionHistoryLoading ? (
            <Loader />
          ) : (
            <NoHistory>
              <Typography>No hay historial de posiciones.</Typography>
            </NoHistory>
          )}
        </ContentModalContainer>
      </ModalPositionHistory>
    </div>
  );
};

export default PositionHistoryModal;
