import React, { useEffect } from 'react';
import Button from '../../components/Button/Button';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material/styles';
import themeMui from '../../theme';
import { ContainerAddUser } from '../Team/userStyles';
import useNotistack from '../Notistack/useNotistack';
import { useNavigate } from 'react-router-dom';
import {
  BoxMui,
  BoxTitleAddClient,
  TextFieldMui,
  BtnContainer,
  AddClientContainer,
  FormContainer,
  SubFormContainer,
  BreadcumsContainer,
} from './clientStyles';
import CancelButton from '../../components/Button/CancelButton';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';
import useClients from './useClients';
import { validateClientForm } from './clientValidation';
import PlaceAutoComplete from './PlaceAutoComplete';

const AddClient = ({ notificationClicked }) => {
  const { AddNewClient, loadingButtons, setLoadingButtons } = useClients();
  const navigate = useNavigate();
  const { showNotification } = useNotistack();
  const [errors, setErrors] = React.useState([]);
  const [clientInfo, setClientInfo] = React.useState({
    email: '',
    name: '',
    address: '',
    cellphone: '',
    addressDetail: '',
  });

  const handleShowErrors = (errors) => {
    errors[0]?.forEach((error) => {
      showNotification(error, 'error');
    });
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setClientInfo({ ...clientInfo, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = validateClientForm(clientInfo);
    if (response.valid) {
      AddNewClient(clientInfo, setErrors);
    } else {
      setErrors(response);

      setTimeout(() => {
        setLoadingButtons(false);
      }, 200);
    }
  };

  useEffect(() => {
    handleShowErrors(errors);
  }, [errors]);

  const setAddress = (value) => {
    setClientInfo({ ...clientInfo, address: value });
  };

  useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate(`/operaciones/${notificationClicked.id}`);
    }
  }, [notificationClicked.isClicked]);

  return (
    <ContainerAddUser>
      <ThemeProvider theme={themeMui}>
        <AddClientContainer>
          <BreadcumsContainer>
            <BreadcrumbsMui
              title="Nuevo cliente"
              prev="Clientes"
              path={'/clientes'}
            />
          </BreadcumsContainer>

          <BoxTitleAddClient>
            <h1>Nuevo cliente</h1>
          </BoxTitleAddClient>
          <BoxMui
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              width: '80%',
              height: '450px',
            }}
          >
            <FormContainer>
              <SubFormContainer>
                <TextFieldMui
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                  }}
                  InputLabelProps={{
                    style: {
                      color: `${themeMui.palette.inputText.main}
                        `,
                    },
                  }}
                  InputProps={{}}
                  autoComplete="given-name"
                  required
                  id="firstName"
                  label="Nombre"
                  autoFocus
                  name="name"
                  variant="standard"
                  onChange={handleChange}
                  error={errors[1]?.name}
                  value={clientInfo.name}
                />
                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  label="Teléfono"
                  variant="standard"
                  name="cellphone"
                  value={clientInfo.cellphone}
                  onChange={handleChange}
                  error={errors[1]?.cellphone}
                  type="cellphone"
                />
                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '100%',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  InputProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  fullWidth
                  id="addressDetail"
                  label="Detalle de la dirección"
                  variant="standard"
                  name="addressDetail"
                  value={clientInfo.addressDetail}
                  onChange={handleChange}
                  error={[1]?.addressDetail}
                />
              </SubFormContainer>

              <SubFormContainer>
                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  InputProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  fullWidth
                  id="email"
                  variant="standard"
                  label="Email"
                  autoComplete="email"
                  name="email"
                  onChange={handleChange}
                  value={clientInfo.email}
                />

                <PlaceAutoComplete setValueToSend={setAddress} />
              </SubFormContainer>
            </FormContainer>
            <BtnContainer>
              <Button type="submit" title="GUARDAR" disable={loadingButtons} />
              <CancelButton
                title="CANCELAR"
                onClick={() => navigate('/clientes')}
                disable={loadingButtons}
              />
            </BtnContainer>
          </BoxMui>
        </AddClientContainer>
      </ThemeProvider>
    </ContainerAddUser>
  );
};

export default AddClient;
