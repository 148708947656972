import React, { useEffect } from 'react';
import Button from '../../components/Button/Button';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material/styles';
import themeMui from '../../theme';
import { ContainerAddUser } from '../Team/userStyles';
import useNotistack from '../Notistack/useNotistack';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BoxMui,
  BoxTitleAddBroker,
  TextFieldMui,
  BtnContainer,
  AddBrokerContainer,
  FormContainer,
  SubFormContainer,
  BreadcumsContainer,
} from './brokerStyles';
import CancelButton from '../../components/Button/CancelButton';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';
import useBrokers from './useBrokers';
import { validateBrokerForm } from './brokerValidation';

const EditBroker = ({ notificationClicked, setNotificationClicked }) => {
  const {
    loadingButtons,
    setLoadingButtons,
    getBrokerById,
    brokerInfo,
    updateBroker,
  } = useBrokers();
  const navigate = useNavigate();
  const { showNotification } = useNotistack();
  const [errors, setErrors] = React.useState([]);
  const [brokerInfoToEdit, setBrokerInfoToEdit] = React.useState({
    name: '',
    cellphone: '',
    email: '',
  });
  const { id } = useParams();

  useEffect(() => {
    getBrokerById(id);
  }, []);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setBrokerInfoToEdit({ ...brokerInfoToEdit, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = validateBrokerForm(brokerInfoToEdit);
    if (response.valid) {
      updateBroker(brokerInfoToEdit, setErrors, id);
    } else {
      setErrors(response);
      setTimeout(() => {
        setLoadingButtons(false);
      }, 200);
    }
  };

  const handlerSetInfoToEdit = () => {
    setBrokerInfoToEdit({
      name: brokerInfo.name,
      email: brokerInfo.email ? brokerInfo.email : '',
      cellphone: brokerInfo.cellphone ? brokerInfo.cellphone : '',
      typeOfBroker: brokerInfo.typeOfBroker,
    });
  };

  useEffect(() => {
    handlerSetInfoToEdit();
  }, [brokerInfo]);

  useEffect(() => {}, [errors]);

  useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate(`/operaciones/${notificationClicked.id}`);
    }
  }, [notificationClicked.isClicked]);

  return (
    <ContainerAddUser>
      <ThemeProvider theme={themeMui}>
        <AddBrokerContainer>
          <BreadcumsContainer>
            <BreadcrumbsMui
              title="Editar comisionista"
              prev="Comisionistas"
              path={'/comisionistas'}
            />
          </BreadcumsContainer>

          <BoxTitleAddBroker>
            <h1>Editar comisionista</h1>
          </BoxTitleAddBroker>
          <BoxMui
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              width: '80%',
              height: '450px',
            }}
          >
            <FormContainer>
              <SubFormContainer>
                <TextFieldMui
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                  }}
                  InputLabelProps={{
                    style: {
                      color: `${themeMui.palette.inputText.main}
                        `,
                    },
                  }}
                  InputProps={{}}
                  autoComplete="given-name"
                  required
                  id="firstName"
                  label="Nombre"
                  autoFocus
                  name="name"
                  variant="standard"
                  onChange={handleChange}
                  error={errors[1]?.name}
                  value={brokerInfoToEdit.name}
                />
                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  label="Teléfono"
                  variant="standard"
                  name="cellphone"
                  value={brokerInfoToEdit.cellphone}
                  onChange={handleChange}
                  error={errors[1]?.cellphone}
                  type="cellphone"
                />
              </SubFormContainer>

              <SubFormContainer>
                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  InputProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  fullWidth
                  id="email"
                  variant="standard"
                  label="Email"
                  autoComplete="email"
                  name="email"
                  onChange={handleChange}
                  value={brokerInfoToEdit.email}
                />
              </SubFormContainer>
            </FormContainer>
            <BtnContainer>
              <Button type="submit" title="GUARDAR" disable={loadingButtons} />
              <CancelButton
                title="CANCELAR"
                onClick={() => navigate('/comisionistas')}
                disable={loadingButtons}
              />
            </BtnContainer>
          </BoxMui>
        </AddBrokerContainer>
      </ThemeProvider>
    </ContainerAddUser>
  );
};

export default EditBroker;
