import * as React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import formatNumber from '../../utils/formatNumber';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : 'red',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'red',
    boxSizing: 'border-box',
  },
}));

const SwitchTC = ({
  setIsMultiplying,
  setOutputAmountValue,
  setOperation,
  operation,
  isMultiplying,
  isDisabled,
}) => {
  React.useEffect(() => {
    if (operation.exchangeRateType === 'dividir') setIsMultiplying(false);
  }, []);

  const handleCheck = (e) => {
    if (isDisabled) return;
    setIsMultiplying(!e.target.checked);
    const type = e.target.checked ? 'dividir' : 'multiplicar';

    const inputAmountFloatValue = operation.input_amount;
    const exchangeRateFloatValue = operation.exchange_rate;

    if (!exchangeRateFloatValue) {
      setOperation({ ...operation, exchangeRateType: type });
    } else {
      if (type === 'dividir') {
        const calc = inputAmountFloatValue / exchangeRateFloatValue;
        setOperation({
          ...operation,
          exchangeRateType: type,
          output_amount: inputAmountFloatValue / exchangeRateFloatValue,
        });
        setOutputAmountValue(formatNumber(calc));
      }
      if (type === 'multiplicar') {
        const calc = inputAmountFloatValue * exchangeRateFloatValue;
        setOperation({
          ...operation,
          exchangeRateType: type,
          output_amount: calc,
        });
        setOutputAmountValue(formatNumber(calc));
      }
    }
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{ marginRight: '10px' }}
    >
      <Typography>×</Typography>
      <AntSwitch
        onChange={handleCheck}
        inputProps={{ 'aria-label': 'ant design' }}
        checked={!isMultiplying}
      />
      <Typography>÷</Typography>
    </Stack>
  );
};

export default SwitchTC;
