import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import useUsers from './useUsers';
import { TeamContainer } from './userStyles';
import Loader from '../../components/Loader/Loader';
import {
  Title,
  UserTableContainer,
  LeftButtonsContainer,
  RightButtonsContainer,
  Btn,
  ButtonsContainer,
} from './userStyles';
import ButtonOutline from '../../components/Button/ButtonOutline';
import UsersTable from '../../components/Team/UsersTable';

const Users = ({ notificationClicked }) => {
  const {
    getUsers,
    users,
    loading,
    deleteUser,
    userForSearch,
    setUsers,
    getUsersForCsv,
    usersCsv,
  } = useUsers();

  const navigate = useNavigate();
  const handleAddUser = () => {
    navigate('/agregar-usuario');
  };

  useEffect(() => {
    getUsers();
    getUsersForCsv();
  }, []);

  useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate('/operaciones');
    }
  }, [notificationClicked.isClicked]);

  return (
    <TeamContainer>
      <Title>Equipo</Title>
      <ButtonsContainer>
        <LeftButtonsContainer>
          <div style={{ marginTop: '0px' }}>
            <Btn onClick={() => handleAddUser()}>NUEVO USUARIO</Btn>
          </div>
        </LeftButtonsContainer>
        <RightButtonsContainer>
          <CSVLink
            data={usersCsv}
            filename="equipo.csv"
            target="_blank"
            style={{ textDecoration: 'none' }}
            separator={';'}
          >
            <ButtonOutline icon="csv" title="EXPORTAR CSV" />
          </CSVLink>
        </RightButtonsContainer>
      </ButtonsContainer>
      {loading && !users.length ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '150px',
          }}
        >
          <Loader />
        </div>
      ) : (
        <UserTableContainer>
          <UsersTable
            getUsers={getUsers}
            users={users}
            loading={loading}
            deleteUser={deleteUser}
            userForSearch={userForSearch}
            setUsers={setUsers}
          />
        </UserTableContainer>
      )}
    </TeamContainer>
  );
};

export default Users;
