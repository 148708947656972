import React, { useEffect, useState } from 'react';
import Button from '../../components/Button/Button';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material/styles';
import themeMui from '../../theme';
import { ContainerAddUser } from '../Team/userStyles';
import useNotistack from '../Notistack/useNotistack';
import { useNavigate } from 'react-router-dom';
import {
  BoxMui,
  BoxTitleAddBroker,
  TextFieldMui,
  BtnContainer,
  AddBrokerContainer,
  FormContainer,
  SubFormContainer,
  BreadcumsContainer,
} from './brokerStyles';
import CancelButton from '../../components/Button/CancelButton';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';
import useBrokers from './useBrokers';
import { validateBrokerForm } from './brokerValidation';

const AddBroker = () => {
  const { registerBroker, loadingButtons, setLoadingButtons } = useBrokers();
  const navigate = useNavigate();
  const { showNotification } = useNotistack();
  const [errors, setErrors] = React.useState([]);
  const [brokerInfo, setBrokerInfo] = React.useState({
    name: '',
    cellphone: '',
    email: '',
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setBrokerInfo({ ...brokerInfo, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = validateBrokerForm(brokerInfo);
    if (response.valid) {
      registerBroker(brokerInfo, setErrors).then((res) => {
        if (res) {
          navigate('/comisionistas');
        }
      });
    } else {
      setErrors(response);
      setTimeout(() => {
        setLoadingButtons(false);
      }, 200);
    }
  };

  const handleShowErrors = (errors) => {
    errors[0]?.forEach((error) => {
      showNotification(error, 'error');
    });
  };

  useEffect(() => {
    handleShowErrors(errors);
  }, [errors]);

  return (
    <ContainerAddUser>
      <ThemeProvider theme={themeMui}>
        <AddBrokerContainer>
          <BreadcumsContainer>
            <BreadcrumbsMui
              title="Nuevo comisionista"
              prev="Comisionistas"
              path={'/comisionistas'}
            />
          </BreadcumsContainer>

          <BoxTitleAddBroker>
            <h1>Nuevo comisionista</h1>
          </BoxTitleAddBroker>
          <BoxMui
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              width: '80%',
              height: '450px',
            }}
          >
            <FormContainer>
              <SubFormContainer>
                <TextFieldMui
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                  }}
                  InputLabelProps={{
                    style: {
                      color: `${themeMui.palette.inputText.main}
                        `,
                    },
                  }}
                  InputProps={{}}
                  autoComplete="given-name"
                  required
                  id="firstName"
                  label="Nombre"
                  autoFocus
                  name="name"
                  variant="standard"
                  onChange={handleChange}
                  error={errors[1]?.name}
                  value={brokerInfo.name}
                />
                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  label="Teléfono"
                  variant="standard"
                  name="cellphone"
                  value={brokerInfo.cellphone}
                  onChange={handleChange}
                  error={errors[1]?.cellphone}
                  type="cellphone"
                />
              </SubFormContainer>

              <SubFormContainer>
                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  InputProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  fullWidth
                  id="email"
                  variant="standard"
                  label="Email"
                  autoComplete="email"
                  name="email"
                  onChange={handleChange}
                  value={brokerInfo.email}
                />
              </SubFormContainer>
            </FormContainer>
            <BtnContainer>
              <Button type="submit" title="GUARDAR" disable={loadingButtons} />
              <CancelButton
                title="CANCELAR"
                onClick={() => navigate('/comisionistas')}
                disable={loadingButtons}
              />
            </BtnContainer>
          </BoxMui>
        </AddBrokerContainer>
      </ThemeProvider>
    </ContainerAddUser>
  );
};

export default AddBroker;
