import React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

let color;

const IOSSwitchStyle = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 22,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 4,
    color: color,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(20.5px)',
      color: '#364769',
      '& + .MuiSwitch-track': {
        backgroundColor: 'white',
        opacity: 1,
        border: 'solid 1px #e0e0e0',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    width: 14,
    height: 14,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: 'white',
    border: 'solid 1px #e0e0e0',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const IosSwitch = ({ checked, onChange, colorProp, ...props }) => {
  color = colorProp;
  return <IOSSwitchStyle checked={checked} onChange={onChange} {...props} />;
};

export default IosSwitch;
