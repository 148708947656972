import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';
import Button from '../../components/Button/Button';
import CancelButton from '../../components/Button/CancelButton';
import theme from '../../theme';
import { NumericFormat } from 'react-number-format';
import { HomeContainer } from '../Layout/homeStyles';
import {
  ChangePositionContainer,
  BoxMui,
  BoxTitle,
  BreadcumsContainer,
  BtnContainer,
  PositionFormContainer,
  CurrencyContainer,
} from './logisticStyles';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
  TextField,
} from '@mui/material';

import useLogistic from './useLogistic';

const EditPosition = ({ notificationClicked }) => {
  const navigate = useNavigate();
  const {
    getAllDeliveries,
    setAllDeliveries,
    allDeliveries,
    registerPosition,
    errors,
    loadingButtons,
  } = useLogistic();

  const [currency, setCurrency] = useState(null);
  const [balance, setBalance] = useState(null);
  const [delivery, setDelivery] = useState(null);
  const [deliveryToTransfer, setDeliveryToTransfer] = useState(null);

  useEffect(() => {
    getAllDeliveries(setAllDeliveries);
  }, []);

  useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate(`/operaciones/${notificationClicked.id}`);
    }
  }, [notificationClicked.isClicked]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await registerPosition(
      {
        userId: delivery?.newValue?._id,
        userIdToTransfer: deliveryToTransfer?.newValue?._id,
        currency: currency?.newValue.props?.value,
        balance,
        type: 'RECIBO/ENTRA POS.DELIVERY',
      },
      {
        userId: deliveryToTransfer?.newValue?._id,
        currency: currency?.newValue?.props?.value,
        balance,
        type: 'ENTREGO/SALE POS.DELIVERY',
      },
    );
  };

  const handleNumericFormatChange = (values, name, e) => {
    const { floatValue } = values;
    setBalance(floatValue);
  };
  return (
    <HomeContainer>
      <ChangePositionContainer>
        <BreadcumsContainer>
          <BreadcrumbsMui
            title="Transferir"
            prev="Logística"
            path={'/logistica'}
          />
        </BreadcumsContainer>
        <BoxTitle>
          <h1>Transferencia</h1>
        </BoxTitle>
        <BoxMui component="form" noValidate onSubmit={handleSubmit}>
          <PositionFormContainer>
            <Autocomplete
              onChange={(event, newValue) => {
                setDelivery({
                  newValue,
                });
              }}
              options={allDeliveries.filter(
                (e) => e.name !== deliveryToTransfer?.newValue?.name,
              )}
              getOptionLabel={(deliveries) => deliveries.name}
              value={delivery?.name}
              sx={{ width: '50%', marginRight: '2rem' }}
              renderInput={(params) => (
                <TextField
                  error={errors.userId}
                  {...params}
                  label="Emisor"
                  variant="standard"
                  fullWidth={true}
                  InputLabelProps={{
                    style: { color: `${theme.palette.inputText.main}` },
                  }}
                  sx={{
                    input: {
                      color: `${theme.palette.inputText.main}`,
                    },
                    '& .MuiInputLabel-root': { color: '#D3D6DA' },
                  }}
                />
              )}
            />

            <Autocomplete
              onChange={(event, newValue) => {
                setDeliveryToTransfer({
                  newValue,
                });
              }}
              options={allDeliveries.filter(
                (e) => e.name !== delivery?.newValue?.name,
              )}
              getOptionLabel={(deliveries) => deliveries.name}
              value={deliveryToTransfer?.name}
              sx={{ width: '50%' }}
              renderInput={(params) => (
                <TextField
                  error={errors.userId}
                  {...params}
                  label="Receptor"
                  variant="standard"
                  fullWidth={true}
                  InputLabelProps={{
                    style: { color: `${theme.palette.inputText.main}` },
                  }}
                  sx={{
                    input: {
                      color: `${theme.palette.inputText.main}`,
                    },
                    '& .MuiInputLabel-root': { color: '#D3D6DA' },
                  }}
                />
              )}
            />
          </PositionFormContainer>
          <CurrencyContainer>
            <FormControl
              sx={{ width: '14%' }}
              disabled={!delivery?.newValue?.position}
            >
              <InputLabel
                sx={{
                  color: `${theme.palette.inputText.main}`,
                  marginLeft: '20px',
                }}
                id="input"
                shrink={false}
              >
                {!currency ? 'Divisa' : null}
              </InputLabel>

              <Select
                labelId="input"
                onChange={(event, newValue) => {
                  setCurrency({
                    newValue,
                  });
                }}
                name="input_currency"
                value={currency?.newValue}
                sx={{
                  borderRadius: '14px',
                  backgroundColor: '#F0F2F5',
                  height: '49.5px',
                  border: 'none',
                  cursor: 'pointer',
                  width: '8rem',
                }}
              >
                {delivery?.newValue?.position?.map((d) => {
                  return (
                    <MenuItem value={d.currency?.id} key={d._id}>
                      {d.currency.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <NumericFormat
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
              onValueChange={(values, e) =>
                handleNumericFormatChange(values, 'balance', e)
              }
              customInput={TextField}
              value={balance}
              autoComplete="off"
              variant="filled"
              placeholder="Monto a envíar"
              name="balance"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                style: {
                  color: `black`,
                  height: '46px',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                  paddingBottom: '12px',
                },
              }}
              error={errors.balance}
            />
          </CurrencyContainer>

          <BtnContainer>
            <Button type="submit" title="GUARDAR" disable={loadingButtons} />
            <CancelButton
              title="CANCELAR"
              onClick={() => navigate('/logistica')}
            />
          </BtnContainer>
        </BoxMui>
      </ChangePositionContainer>
    </HomeContainer>
  );
};

export default EditPosition;
