import { useState } from 'react';
import fetchFromApi from '../../utils/fetchFromApi';
import useAuth from '../Login/useAuth';
import { operationRoles } from '../../utils/constants';

const useNotifications = () => {
  const user = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  const createNotification = async (notification) => {
    try {
      const response = await fetchFromApi('POST', 'notification', {
        ...notification,
        read: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const createNotificationCms = async (
    title,
    body,
    operationId,
    operationIndex,
    operationRole,
  ) => {
    try {
      const response = await fetchFromApi('POST', 'notification/cms', {
        title,
        body,
        senderId: user?.id,
        operationId,
        operationIndex,
        operationRole,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const markAllNotificationsAsRead = async (notifications) => {
    try {
      if (user?.id) {
        const response = await fetchFromApi(
          'PUT',
          `notification/cms/read/${user?.id}`,
          notifications,
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getNotifications = async () => {
    try {
      const response = await fetchFromApi(
        'GET',
        `notification/cms-nots/${user?.id}`,
      );

      if (response) {
        setNotifications(response);

        let unreadNotifications = 0;
        response.map((n) => {
          n.read.map((r) => {
            if (r.id.toString() === user.id.toString() && !r.read) {
              unreadNotifications = unreadNotifications + 1;
            }
          });
        });
        setUnreadNotifications(unreadNotifications);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    createNotificationCms,
    createNotification,
    markAllNotificationsAsRead,
    getNotifications,
    notifications,
    setNotifications,
    unreadNotifications,
    setUnreadNotifications,
  };
};

export default useNotifications;
