import React from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import theme from '../../theme';

const Notification = ({
  title,
  body,
  setNotificationClicked,
  read,
  operationId,
  date,
}) => {
  const navigate = useNavigate();

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'America/Argentina/Buenos_Aires',
    };
    return date.toLocaleString('es-AR', options);
  };
  const handleClick = () => {
    if (title.includes('anomalia')) return;
    if (title.includes('cotizacion')) return navigate('/operaciones-precarga');
    setNotificationClicked({ isClicked: true, id: operationId });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px ',
        padding: '10px 30px',
        cursor: `${title.includes('anomalia') ? 'default' : 'pointer'}`,
        backgroundColor: `${read[0]?.read ? '#FFFFFF' : 'aliceblue'}`,
      }}
    >
      <NotificationsIcon
        sx={{
          color: `${read[0]?.read ? 'black' : `${theme.palette.unread.main}`}`,
        }}
      />
      <div
        onClick={handleClick}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '5px',
          width: '100%',
        }}
      >
        <h4 style={{ margin: '0' }}>{title}</h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '5px',
            width: '100%',
          }}
        >
          <span>{body}</span>
          <span
            style={{ fontSize: '12px', marginLeft: '2rem', marginTop: '3px' }}
          >
            {formatCreatedAt(date)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Notification;
