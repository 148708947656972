import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import theme from '../../theme';
import formatNumber, { formatExchangeRate } from '../../utils/formatNumber';

const CollapseTable = ({
  commission,
  outputAnomalie,
  inputAnomalie,
  assignedDelivery,
  comments,
  output_currency,
  input_currency,
  exchange_rate,
  liquidatorValue,
  exchangeRateType,
  mathematicalOperation,
  calculatedExchangeRate,
  checkedByCaja,
  confirmedBy,
}) => {
  const { name } = checkedByCaja;

  const TableHeadCell = ({ children }) => (
    <TableCell
      sx={{
        fontWeight: '500',
        color: `${theme.palette.tableTitle.main}`,
        paddingTop: '20px',
        paddingRight: '50px',
        fontSize: '12px',
      }}
      align="left"
    >
      {children}
    </TableCell>
  );

  const TableCellBody = ({ children }) => (
    <TableCell
      align="left"
      sx={{
        color: `${theme.palette.tableText.main}`,
        fontWeight: '500',
        borderBottom: 'none',
        fontSize: '12px',
      }}
    >
      {children}
    </TableCell>
  );

  return (
    <div>
      <h3
        style={{
          margin: 0,
          fontSize: '16px',
          color: '#384764',
          padding: '10px',
        }}
      >
        Detalles
      </h3>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableHeadCell>TC</TableHeadCell>
            <TableHeadCell>TC CALCULADO</TableHeadCell>
            <TableHeadCell>OP MATEMÁTICA</TableHeadCell>
            <TableHeadCell>PRECIO MERCADO</TableHeadCell>
            <TableHeadCell>ANOMALÍA ENTRADA</TableHeadCell>
            <TableHeadCell>ANOMALÍA SALIDA</TableHeadCell>
            <TableHeadCell>DELIVERY</TableHeadCell>
            <TableHeadCell>COMENTARIOS</TableHeadCell>
            <TableHeadCell>LIQUIDADO POR:</TableHeadCell>
            <TableHeadCell>CHECK POR:</TableHeadCell>
            <TableHeadCell>CONFIRMADO POR:</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCellBody>
              {exchange_rate
                ? `${formatExchangeRate(exchange_rate)} ${
                    exchangeRateType === 'multiplicar' ? 'E/S' : 'S/E'
                  }`
                : '---'}
            </TableCellBody>
            <TableCellBody>
              {calculatedExchangeRate
                ? formatExchangeRate(calculatedExchangeRate)
                : '---'}
            </TableCellBody>
            <TableCellBody>
              {mathematicalOperation ? mathematicalOperation : '---'}
            </TableCellBody>
            <TableCellBody>
              {commission ? formatNumber(commission) : '---'}
            </TableCellBody>
            <TableCellBody>
              {inputAnomalie.length !== 0
                ? ` ${formatNumber(inputAnomalie[0].amount)} ${input_currency}`
                : ' ---'}
            </TableCellBody>
            <TableCellBody>
              {outputAnomalie.length !== 0
                ? ` ${formatNumber(
                    outputAnomalie[0].amount,
                  )} ${output_currency}`
                : ' ---'}
            </TableCellBody>
            <TableCellBody>
              {assignedDelivery?.name
                ? assignedDelivery.name.toUpperCase()
                : '---'}
            </TableCellBody>
            <TableCellBody>{comments ? comments : '---'}</TableCellBody>
            <TableCellBody>
              {liquidatorValue?.name
                ? `${liquidatorValue?.name} ${liquidatorValue?.email}`
                : '---'}
            </TableCellBody>
            <TableCellBody>{name ? `${name}` : '---'}</TableCellBody>
            <TableCellBody>
              {confirmedBy?.name ? `${confirmedBy.name}` : '---'}
            </TableCellBody>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default CollapseTable;
