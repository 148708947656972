import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import theme from '../../../theme';
import { NumericFormat } from 'react-number-format';
import {
  BoxMui,
  TransferCurrentAccountFormContainer,
  CurrencyContainer,
} from '../operationsStyles';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
  TextField,
  ListSubheader,
} from '@mui/material';
import { operationTypes } from '../../../utils/constants';
import Loader from '../../../components/Loader/Loader';
import { FormLoaderContainer } from '../operationsStyles';

const CurrentAccountTransferForm = ({
  stock,
  handleInputChange,
  clients,
  selectedClient,
  setSelectedClient,
  selectedClientToTransfer,
  setSelectedClientToTransfer,
  operation,
  currencyToTransfer,
  setCurrencyToTransfer,
  handleNumericFormatChange,
  errors,
  isStockLoading,
  isClientsLoading,
}) => {
  return isClientsLoading && isStockLoading ? (
    <FormLoaderContainer>
      <Loader style={{ marginTop: '50px' }} />
    </FormLoaderContainer>
  ) : (
    <>
      <FormControl variant="standard" fullWidth>
        <InputLabel
          sx={{ color: `${theme.palette.inputText.main}` }}
          id="input"
        >
          Tipo de Operacion
        </InputLabel>
        <Select
          labelId="input"
          name="operation_type"
          onChange={(event) => handleInputChange(event)}
          value={operation.operation_type}
        >
          <ListSubheader>OPERACIONES FINANCIERAS</ListSubheader>
          {operationTypes().map((o, i) => {
            if (i > 7) return null;
            return (
              <MenuItem value={o} key={i}>
                {o}
              </MenuItem>
            );
          })}
          <ListSubheader>OPERACIONES INSTITUCIONALES</ListSubheader>
          {operationTypes().map((o, i) => {
            if (i < 8) return null;
            return (
              <MenuItem value={o} key={i}>
                {o}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <TransferCurrentAccountFormContainer style={{ margin: '1rem 0' }}>
        <Autocomplete
          onChange={(event, newValue) => {
            setSelectedClient(newValue);
          }}
          options={clients.filter(
            (e) => e.name !== selectedClientToTransfer?.name,
          )}
          getOptionLabel={(clients) => clients.name}
          value={selectedClient}
          sx={{ width: '50%', marginRight: '2rem' }}
          renderInput={(params) => (
            <TextField
              error={errors.client}
              {...params}
              label="Emisor"
              variant="standard"
              fullWidth={true}
              InputLabelProps={{
                style: { color: `${theme.palette.inputText.main}` },
              }}
              sx={{
                input: {
                  color: `${theme.palette.inputText.main}`,
                },
                '& .MuiInputLabel-root': { color: '#D3D6DA' },
              }}
            />
          )}
        />

        <Autocomplete
          onChange={(event, newValue) => {
            setSelectedClientToTransfer(newValue);
          }}
          options={clients.filter((e) => e.name !== selectedClient?.name)}
          getOptionLabel={(clients) => clients.name}
          value={selectedClientToTransfer}
          sx={{ width: '50%' }}
          renderInput={(params) => (
            <TextField
              error={errors.clientToTransfer}
              {...params}
              label="Receptor"
              variant="standard"
              fullWidth={true}
              InputLabelProps={{
                style: { color: `${theme.palette.inputText.main}` },
              }}
              sx={{
                input: {
                  color: `${theme.palette.inputText.main}`,
                },
                '& .MuiInputLabel-root': { color: '#D3D6DA' },
              }}
            />
          )}
        />
      </TransferCurrentAccountFormContainer>
      <CurrencyContainer style={{ margin: '2rem 0' }}>
        <FormControl
          sx={{ width: '14%' }}
          disabled={!selectedClient?.currentAccount}
        >
          <InputLabel
            sx={{
              color: `${theme.palette.inputText.main}`,
              marginLeft: '20px',
            }}
            id="input"
            shrink={false}
          >
            {!currencyToTransfer ? 'Divisa' : null}
          </InputLabel>

          <Select
            error={errors.input_currency}
            labelId="input"
            onChange={(event, newValue) => {
              setCurrencyToTransfer(newValue.props);
            }}
            name="input_currency"
            value={currencyToTransfer?.newValue?.name}
            sx={{
              borderRadius: '14px',
              backgroundColor: '#F0F2F5',
              height: '49.5px',
              border: 'none',
              cursor: 'pointer',
              width: '8rem',
            }}
          >
            {selectedClient?.currentAccount?.map((d) => {
              return (
                <MenuItem value={d.currency?.id} key={d._id}>
                  {d.currency.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <NumericFormat
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale={true}
          onValueChange={(values, e) =>
            handleNumericFormatChange(values, 'input_amount', e)
          }
          customInput={TextField}
          autoComplete="off"
          variant="filled"
          placeholder="Monto a envíar"
          name="input_amount"
          InputProps={{
            disableUnderline: true,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            style: {
              color: `black`,
              height: '46px',
              borderRadius: '10px',
              backgroundColor: 'white',
              paddingBottom: '12px',
            },
          }}
          error={errors.input_amount}
        />
      </CurrencyContainer>
    </>
  );
};

export default CurrentAccountTransferForm;
