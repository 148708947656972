import React, { forwardRef } from 'react';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import theme from '../../theme';
import {
  ModalItem,
  ModalItemsContainer,
  SplitOperationContentModal,
  InputContainer,
  InputsContainer,
  SubopsContainer,
  Title,
  SubOpIndexContainer,
  SpanLabel,
  RemoveButton,
  AmountsContainer,
  AmountItem,
} from '../../containers/Logistic/logisticStyles';
import { useOperations } from '../../containers/Operations/useOperations';
import useSubOperations from '../../containers/Logistic/useSubOperations';
import useHandleSubmit from '../../containers/Logistic/useHandleSubmit';
import ActionsButtons from './ActionsButtons';
import { NumericFormat } from 'react-number-format';
import formatNumber from '../../utils/formatNumber';

const ModalContent = forwardRef(({ deliverys, id, setIsModalOpen }, ref) => {
  const { currentOperation: operation } = useOperations();
  const {
    subOps,
    setSubOps,
    removeSubOperation,
    handleInputChange,
    addSubOperation,
    validateSubOperations,
    areAllSubOpsNotPending,
  } = useSubOperations(operation);
  const handleSubmit = useHandleSubmit(setIsModalOpen);

  const renderAutocomplete = (index, field, deliverys) => (
    <Autocomplete
      disabled={subOps[index].status !== 'Pendiente'}
      fullWidth
      options={Array.isArray(deliverys) ? deliverys : []}
      getOptionLabel={(deliveries) => deliveries.name}
      value={subOps[index][field] || null}
      sx={{ width: '100%' }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Deliverys"
          variant="standard"
          fullWidth={true}
          InputLabelProps={{
            style: { color: `${theme.palette.inputText.main}` },
          }}
          sx={{
            input: {
              color: `${theme.palette.inputText.main}`,
            },
            '& .MuiInputLabel-root': { color: '#D3D6DA' },
          }}
        />
      )}
      onChange={(event, value) => {
        const newSubOps = [...subOps];
        newSubOps[index][field] = value;
        setSubOps(newSubOps);
      }}
    />
  );

  const renderPlaceAutoComplete = (index) => (
    <TextField
      disabled={subOps[index].status !== 'Pendiente'}
      value={subOps[index]?.address || ''}
      onChange={(event) => {
        const newSubOps = [...subOps];
        newSubOps[index].address = event.target.value;
        setSubOps(newSubOps);
      }}
      label="Dirección"
      fullWidth
    />
  );

  const renderNumericFormat = (index, field, label, value) => (
    <NumericFormat
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale={true}
      value={value}
      onValueChange={(values) =>
        handleInputChange(
          {
            target: {
              value: values.floatValue,
              name: field,
            },
          },
          index,
          field,
        )
      }
      customInput={TextField}
      disabled={subOps[index].status !== 'Pendiente'}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        disableUnderline: true,
        style: {
          color: `black`,
          height: '46px',
          borderRadius: '10px',
          backgroundColor: 'white',
        },
      }}
    />
  );

  return (
    <SplitOperationContentModal ref={ref} tabIndex={-1}>
      <Title>Fraccionar operación</Title>
      <span>Tipo de cambio: {formatNumber(operation?.exchange_rate)}</span>
      <AmountsContainer>
        <AmountItem>
          <span>Monto de entrada</span>
          <span>{formatNumber(operation?.input_amount)}</span>
        </AmountItem>
        <AmountItem>
          <span>Monto de salida</span>
          <span>{formatNumber(operation?.output_amount)}</span>
        </AmountItem>
      </AmountsContainer>
      <ModalItemsContainer>
        {subOps.map((subOp, index) => (
          <SubopsContainer key={index}>
            <SubOpIndexContainer>
              <h3>
                Suboperación {index + 1} - {subOp?.status}
              </h3>
              <RemoveButton
                onClick={() => removeSubOperation(index)}
                disabled={subOps[index].status !== 'Pendiente'}
              >
                X
              </RemoveButton>
            </SubOpIndexContainer>
            <ModalItem>
              <SpanLabel>¿A dónde va?</SpanLabel>
              {renderPlaceAutoComplete(index)}
            </ModalItem>
            <ModalItem>
              <SpanLabel>Seleccionar delivery</SpanLabel>
              {renderAutocomplete(index, 'delivery', deliverys)}
            </ModalItem>
            <InputsContainer>
              <ModalItem>
                <InputContainer>
                  <SpanLabel>Monto de entrada</SpanLabel>
                  {renderNumericFormat(
                    index,
                    'inputAmount',
                    'Monto de entrada',
                    subOp.inputAmount,
                  )}
                </InputContainer>
              </ModalItem>
              <ModalItem>
                <InputContainer>
                  <SpanLabel>Monto de salida</SpanLabel>
                  {renderNumericFormat(
                    index,
                    'outputAmount',
                    'Monto de salida',
                    subOp.outputAmount,
                  )}
                </InputContainer>
              </ModalItem>
            </InputsContainer>
          </SubopsContainer>
        ))}
        <ActionsButtons
          addSubOperation={addSubOperation}
          validateSubOperations={validateSubOperations}
          handleSubmit={handleSubmit}
          subOps={subOps}
          id={id}
          areAllSubOpsNotPending={areAllSubOpsNotPending}
        />
      </ModalItemsContainer>
    </SplitOperationContentModal>
  );
});

export default ModalContent;
