import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import useAuth from '../../Login/useAuth';
import theme from '../../../theme';
import useUsers from '../../Team/useUsers';
import formatNumber, { formatExchangeRate } from '../../../utils/formatNumber';
import {
  CurrenciesContainer,
  CurrenciesInputContainer,
  CurrencyContainer,
  ExchangeRateContainer,
  MathematicalResult,
  IconSpan,
} from '../operationsStyles';
import { NumericFormat } from 'react-number-format';
import SwitchTC from '../../../components/Button/Switch';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

export const CurrenciesInput = ({
  handleInputChange,
  stock,
  operation,
  setCurrencyChecked,
  errors,
  setOperation,
  context,
  areSubOpsNotPending,
}) => {
  const isMultiplyingByExchangeRateType =
    operation.exchangeRateType === 'multiplicar';

  const [inputAmountValue, setInputAmountValue] = useState('');
  const [outputAmountValue, setOutputAmountValue] = useState('');
  const [exchangeRateValue, setExchangeRateValue] = useState('');
  const [isMultiplying, setIsMultiplying] = useState(
    context === 'edit' ? isMultiplyingByExchangeRateType : true,
  );

  useEffect(() => {
    // console.log('isMultiplying', isMultiplying);
    // console.log(
    //   'isMultiplyingByExchangeRateType',
    //   isMultiplyingByExchangeRateType,
    // );
    console.log(areSubOpsNotPending);
  }, [isMultiplying]);

  useEffect(() => {
    if (context === 'edit') {
      if (
        operation.input_amount * operation.exchange_rate ===
          operation.output_amount ||
        operation.exchangeRateType === 'multiplicar'
      ) {
        setIsMultiplying(true);
        console.log('Multiplicando');
      } else {
        setIsMultiplying(false);
        console.log('Dividiendo');
      }
    } else {
      setIsMultiplying(true);
      console.log('Multiplicando 2');
    }
  }, []);

  const [arithmeticExpression, setArithmeticExpression] = useState('');
  const user = useAuth();
  const { getProfile, profile } = useUsers();
  const opOutputCurrencyId = operation?.output_currency?._id
    ? operation?.output_currency?._id
    : operation?.output_currency;

  const handleChangeCalculator = (e) => {
    const inputValue = e.target.value;

    try {
      const result = eval(inputValue);
      if (result !== '') {
        setArithmeticExpression(result?.toString());
        setOperation({
          ...operation,
          calculatedExchangeRate: result,
          mathematicalOperation: inputValue,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [contadorState, setContadorState] = useState({
    last: 'none',
    output: 'not_modified',
    input: 'not_modified',
    tc: 'not_modified',
  });
  const [TCMutable, setTCMutable] = useState(true);

  const tcMutableFunction = (type) => {
    let result = TCMutable;

    // Check if exchange rate is not set and all states are modified
    if (
      !operation.exchange_rate &&
      contadorState.output === 'modified' &&
      contadorState.input === 'modified' &&
      contadorState.tc === 'modified'
    ) {
      setTCMutable(true);
      result = true;
      setContadorState((contadorState) => {
        return {
          ...contadorState,
          output: 'not_modified',
          input: 'not_modified',
          tc: 'not_modified',
        };
      });
    }

    // Check for inputAmount type and if TCMutable is true
    if (type === 'inputAmount' && TCMutable) {
      setContadorState((contadorState) => {
        return { ...contadorState, input: 'modified', last: type };
      });
    }

    // Check for outputAmount type and if TCMutable is true
    if (type === 'outputAmount' && TCMutable) {
      setContadorState((contadorState) => {
        return { ...contadorState, output: 'modified', last: type };
      });
    }

    // Check for exchangeRate type and if TCMutable is true
    if (TCMutable && type === 'exchangeRate') {
      setContadorState((contadorState) => {
        return { ...contadorState, tc: 'modified' };
      });
    }

    // Check for inputAmount type, if it's modified, last type is not the same and exchange rate is set
    if (
      type === 'inputAmount' &&
      contadorState.input === 'modified' &&
      contadorState.last !== type &&
      operation.exchange_rate
    ) {
      setTCMutable(false);
      result = false;
    }

    // Check for outputAmount type, if it's modified, last type is not the same and exchange rate is set
    if (
      type === 'outputAmount' &&
      contadorState.output === 'modified' &&
      contadorState.last !== type &&
      operation.exchange_rate
    ) {
      setTCMutable(false);
      result = false;
    }

    // Check for outputAmount type, last type is exchangeRate and exchange rate is set
    if (
      type === 'outputAmount' &&
      contadorState.last === 'exchangeRate' &&
      operation.exchange_rate
    ) {
      setTCMutable(false);
      result = false;
    }

    // Check for inputAmount type, last type is exchangeRate and exchange rate is set
    if (
      type === 'inputAmount' &&
      contadorState.last === 'exchangeRate' &&
      operation.exchange_rate
    ) {
      setTCMutable(false);
      result = false;
    }

    return result;
  };

  const handleChange = async (e, name, context) => {
    const { formattedValue, floatValue } = e;
    const inputAmountFloatValue = operation.input_amount;
    const outputAmountFloatValue = operation.output_amount;
    const exchangeRateFloatValue = operation.exchange_rate;

    const tcFunctionResult = tcMutableFunction(name);

    if (name === 'inputAmount') {
      if (context.source === 'prop') return;

      // Si se cambia el monto de entrada y ya hay un tipo de cambio establecido
      if (outputAmountValue && operation.exchange_rate) {
        const calc = isMultiplying
          ? floatValue * operation.exchange_rate
          : floatValue / operation.exchange_rate;
        setOperation({
          ...operation,
          input_amount: floatValue,
          output_amount: calc,
        });
        if (calc) {
          setOutputAmountValue(formatNumber(calc));
        }
      } else if (outputAmountValue && !operation.exchange_rate) {
        // Si no hay tipo de cambio establecido, se maneja como antes
        if (isMultiplying && tcFunctionResult) {
          const calc = outputAmountFloatValue / floatValue;
          setOperation({
            ...operation,
            input_amount: floatValue,
            exchange_rate: calc,
          });
          setExchangeRateValue(formatExchangeRate(calc));
          setContadorState((contadorState) => {
            return {
              ...contadorState,
              last: name,
              tc: calc ? 'modified' : contadorState.tc,
            };
          });
        } else {
          console.log('No hay tipo de cambio.');
        }
      } else {
        // Si no hay monto de salida, solo actualizar el monto de entrada
        setOperation({
          ...operation,
          input_amount: floatValue,
        });
      }
      return setInputAmountValue(formattedValue);
    }

    if (name === 'outputAmount') {
      if (context.source === 'prop') return;
      if (inputAmountValue) {
        if (isMultiplying) {
          if (tcFunctionResult) {
            const calc = floatValue / inputAmountFloatValue;
            setOperation({
              ...operation,
              output_amount: floatValue,
              exchange_rate: calc ? calc : exchangeRateFloatValue,
            });
            if (calc) {
              setExchangeRateValue(formatExchangeRate(calc));
            }
            setContadorState((contadorState) => {
              return {
                ...contadorState,
                last: name,
                tc: calc ? 'modified' : contadorState.tc,
              };
            });
          } else {
            const calc = floatValue
              ? floatValue / exchangeRateFloatValue
              : null;
            setOperation({
              ...operation,
              output_amount: floatValue,
              input_amount: calc ? calc : inputAmountFloatValue,
            });
            if (calc) {
              setInputAmountValue(formatNumber(calc));
            }
            setContadorState((contadorState) => {
              return {
                ...contadorState,
                last: name,
                input: calc ? 'modified' : contadorState.input,
              };
            });
          }
        } else {
          if (tcFunctionResult) {
            const calc = inputAmountFloatValue / floatValue;
            setOperation({
              ...operation,
              output_amount: floatValue,
              exchange_rate: calc ? calc : exchangeRateFloatValue,
            });
            if (calc) {
              setExchangeRateValue(formatExchangeRate(calc));
            }
            setContadorState((contadorState) => {
              return {
                ...contadorState,
                last: name,
                tc: calc ? 'modified' : contadorState.tc,
              };
            });
          } else {
            const calc = floatValue
              ? floatValue * exchangeRateFloatValue
              : null;
            setOperation({
              ...operation,
              output_amount: floatValue,
              input_amount: calc ? calc : inputAmountFloatValue,
            });
            if (calc) {
              setInputAmountValue(formatNumber(calc));
            }
            setContadorState((contadorState) => {
              return {
                ...contadorState,
                last: name,
                input: calc ? 'modified' : contadorState.input,
              };
            });
          }
        }
      } else if (exchangeRateValue && !inputAmountFloatValue) {
        const calc = isMultiplying
          ? floatValue / exchangeRateFloatValue
          : floatValue * exchangeRateFloatValue;
        setOperation({
          ...operation,
          output_amount: floatValue,
          input_amount: calc ? calc : operation.input_amount,
        });
        if (calc) {
          setInputAmountValue(formatNumber(calc));
        }
      } else {
        setOperation({ ...operation, output_amount: floatValue });
      }
      return setOutputAmountValue(formattedValue);
    }

    if (name === 'exchangeRate') {
      if (context.source === 'prop') return;
      if (!exchangeRateFloatValue) {
        setTCMutable(true);
      }
      if (inputAmountFloatValue && !outputAmountFloatValue) {
        const calc = isMultiplying
          ? floatValue * inputAmountFloatValue
          : inputAmountFloatValue / floatValue;
        setOperation({
          ...operation,
          output_amount: calc ? calc : outputAmountFloatValue,
          exchange_rate: floatValue,
        });
        if (calc) {
          setOutputAmountValue(formatNumber(calc));
        }
        setContadorState((contadorState) => {
          return {
            ...contadorState,
            last: name,
            output: calc ? 'modified' : contadorState.output,
          };
        });
      } else if (outputAmountFloatValue && !inputAmountFloatValue) {
        const calc = isMultiplying
          ? outputAmountFloatValue / floatValue
          : outputAmountFloatValue * floatValue;
        setOperation({
          ...operation,
          input_amount: calc ? calc : inputAmountFloatValue,
          exchange_rate: floatValue,
        });
        if (calc) {
          setInputAmountValue(formatNumber(calc));
        }
        setContadorState((contadorState) => {
          return {
            ...contadorState,
            last: name,
            input: calc ? 'modified' : contadorState.input,
          };
        });
      } else if (outputAmountFloatValue && inputAmountFloatValue) {
        const calc = isMultiplying
          ? floatValue
            ? floatValue * inputAmountFloatValue
            : null
          : floatValue
          ? inputAmountFloatValue / floatValue
          : null;
        setOperation({
          ...operation,
          output_amount: calc ? calc : outputAmountFloatValue,
          exchange_rate: floatValue,
        });
        if (calc) {
          setOutputAmountValue(formatNumber(calc));
        }
        setContadorState((contadorState) => {
          return {
            ...contadorState,
            last: name,
            output: calc ? 'modified' : contadorState.output,
          };
        });
      } else {
        setOperation({ ...operation, exchange_rate: floatValue });
        setTCMutable(false);
      }
      return setExchangeRateValue(formattedValue);
    }
  };

  const handleSwitch = () => {
    if (areSubOpsNotPending) return;
    setOperation({
      ...operation,
      input_currency: operation.output_currency._id
        ? operation.output_currency._id
        : operation.output_currency,
      output_currency: operation.input_currency,
      // input_amount: operation.output_amount,
      // output_amount: operation.input_amount,
    });

    // setInputAmountValue(formatNumber(operation.output_amount));
    // setOutputAmountValue(formatNumber(operation.input_amount));
  };

  const handleSetTc = (tc) => {
    if (areSubOpsNotPending) return;

    const exchangeRate = parseFloat(tc);
    if (!isNaN(exchangeRate)) {
      setExchangeRateValue(formatExchangeRate(exchangeRate));
      setOperation((prevOperation) => ({
        ...prevOperation,
        exchange_rate: exchangeRate,
      }));
      setArithmeticExpression('');

      // Recalcular el monto de salida en base al nuevo tipo de cambio.
      const newOutputAmount = isMultiplying
        ? operation.input_amount * exchangeRate
        : operation.input_amount / exchangeRate;
      setOutputAmountValue(formatNumber(newOutputAmount));
      setOperation((prevOperation) => ({
        ...prevOperation,
        output_amount: newOutputAmount,
      }));
    }
  };

  useEffect(() => {
    if (user) {
      getProfile(user?.id);
    }
  }, []);

  useEffect(() => {
    if (context === 'edit') {
      if (operation.input_amount) {
        setInputAmountValue(formatNumber(operation.input_amount));
      }
      if (operation.output_amount) {
        setOutputAmountValue(formatNumber(operation.output_amount));
      }
      if (operation.exchange_rate) {
        setExchangeRateValue(formatExchangeRate(operation.exchange_rate));
      }
    }
  }, []);

  useEffect(() => {
    const USD = stock?.find((item) => item.currency === 'USD');
    const ARS = stock?.find((item) => item.currency === 'ARS');
    const USDT = stock?.find((item) => item.currency === 'USDT');
    const isArsClosed = ARS?.isClosed;
    const isUsdClosed = USD?.isClosed;
    const isUsdtClosed = USDT?.isClosed;

    if (context !== 'edit') {
      if (operation.operation_type === 'COMP.VTA.BILLETE') {
        if (
          USD !== 'undefined' &&
          ARS !== 'undefined' &&
          !isArsClosed &&
          !isUsdClosed
        ) {
          setOperation({
            ...operation,
            input_currency: USD?._id,
            output_currency: ARS?._id,
          });
        }
      } else if (operation.operation_type === 'COMP.VTA.USDT') {
        if (
          USD !== 'undefined' &&
          ARS !== 'undefined' &&
          !isUsdClosed &&
          !isUsdtClosed
        ) {
          setOperation({
            ...operation,
            input_currency: USD?._id,
            output_currency: USDT?._id,
          });
        }
      } else if (operation.operation_type === 'CARACHICA/MANCHADO/CAMBIO') {
        if (USD !== 'undefined' && !isUsdClosed) {
          setOperation({
            ...operation,
            input_currency: USD?._id,
            output_currency: USD?._id,
          });
        }
      } else {
        setOperation({
          ...operation,
          input_currency: '',
          output_currency: '',
        });
      }
    }
  }, [operation.operation_type]);

  useEffect(() => {
    if (operation.input_amount && operation.exchange_rate) {
      const calculatedOutputAmount = isMultiplying
        ? operation.input_amount * operation.exchange_rate
        : operation.input_amount / operation.exchange_rate;

      setOperation((prevOperation) => ({
        ...prevOperation,
        output_amount: calculatedOutputAmount,
      }));
      setOutputAmountValue(formatNumber(calculatedOutputAmount));
    }
  }, [isMultiplying, operation.input_amount, operation.exchange_rate]);

  return (
    <CurrenciesInputContainer>
      <CurrenciesContainer>
        <AddIcon
          sx={{
            color: 'green',
            margin: '0',
            padding: '0',
            position: 'relative',
            right: '50px',
            top: '75px',
            fontSize: '20px',
          }}
        />
        <CurrencyContainer>
          {/* {DIVISA DE ENTRADA} */}
          <FormControl sx={{ width: '30%' }} error={errors.input_currency}>
            <InputLabel
              sx={{
                color: `${theme.palette.inputText.main}`,
                marginLeft: '20px',
                display: `${operation.input_currency ? 'none' : ''}`,
              }}
              id="input"
              shrink={false}
            >
              Divisa
            </InputLabel>

            <Select
              disabled={profile.role === 'liquidador_caja' ? true : false}
              labelId="input"
              onChange={(event) => {
                handleInputChange(event);
                setCurrencyChecked(null);
              }}
              name="input_currency"
              value={operation.input_currency}
              sx={{
                borderRadius: '14px',
                backgroundColor: '#F0F2F5',
                height: '49.5px',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              {stock?.map((s) => {
                return (
                  <MenuItem
                    disabled={s.isClosed}
                    value={s._id}
                    key={s._id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {s.icon && <IconSpan>{s.icon}</IconSpan>} {s?.currency}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* MONTO DE ENTRADA */}
          <NumericFormat
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            onValueChange={(values, e) =>
              handleChange(values, 'inputAmount', e)
            }
            customInput={TextField}
            value={inputAmountValue}
            variant="filled"
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              style: {
                color: `black`,
                height: '46px',
                borderRadius: '10px',
                backgroundColor: 'white',
                paddingBottom: '12px',
              },
            }}
            error={errors.input_amount}
            disabled={profile.role === 'liquidador_caja' || areSubOpsNotPending}
            placeholder="Monto de entrada"
          />
        </CurrencyContainer>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            onClick={() => {
              handleSwitch();
            }}
          >
            <AutorenewIcon
              sx={{
                color: `${theme.palette.inputText.main}`,
                margin: '0',
                padding: '0',
                fontSize: '40px',
                cursor: 'pointer',
              }}
            />
          </div>
          <ExchangeRateContainer>
            {/* TIPO DE CAMBIO */}
            <NumericFormat
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={20}
              fixedDecimalScale={true}
              onValueChange={(values, e) =>
                handleChange(values, 'exchangeRate', e)
              }
              customInput={TextField}
              value={
                context === 'edit' ? operation.exchange_rate : exchangeRateValue
              }
              error={errors.exchange_rate}
              disabled={
                profile.role === 'liquidador_caja' || areSubOpsNotPending
              }
              placeholder="Tipo de cambio"
              InputLabelProps={{
                style: {
                  color: `${theme.palette.inputText.main}`,
                },
              }}
              InputProps={{
                style: {
                  height: '50px',
                  borderRadius: '10px',
                  marginRight: '10px',
                },
              }}
            />
            <SwitchTC
              setIsMultiplying={setIsMultiplying}
              setOperation={setOperation}
              setExchangeRateValue={setExchangeRateValue}
              setOutputAmountValue={setOutputAmountValue}
              operation={operation}
              isMultiplying={isMultiplying}
              isDisabled={areSubOpsNotPending}
            />
            <TextField
              disabled={areSubOpsNotPending}
              name="arithmeticExpression"
              placeholder="Calcular"
              InputLabelProps={{
                style: {
                  color: `${theme.palette.inputText.main}`,
                },
              }}
              InputProps={{
                style: {
                  height: '50px',
                  borderRadius: '10px',
                  marginRight: '10px',
                },
              }}
              onChange={(e) => handleChangeCalculator(e)}
              security="off"
            />

            <div
              onClick={() => {
                handleSetTc(arithmeticExpression);
              }}
            >
              <KeyboardReturnIcon
                sx={{
                  color: `${theme.palette.inputText.main}`,
                  cursor: 'pointer',
                }}
              />
            </div>
          </ExchangeRateContainer>
        </div>
        {arithmeticExpression !== '' && (
          <MathematicalResult>{arithmeticExpression}</MathematicalResult>
        )}
        <RemoveIcon
          sx={{
            color: 'red',
            margin: '0',
            padding: '0',
            position: 'relative',
            right: '50px',
            top: '75px',
            fontSize: '20px',
          }}
        />
        <CurrencyContainer>
          {/* {DIVISA DE SALIDA} */}
          <FormControl sx={{ width: '30%' }} error={errors.output_currency}>
            <InputLabel
              sx={{
                color: `${theme.palette.inputText.main}`,
                marginLeft: '20px',
                display: `${operation.output_currency ? 'none' : ''}`,
              }}
              id="input"
              shrink={false}
            >
              Divisa
            </InputLabel>

            <Select
              disabled={profile.role === 'liquidador_caja' ? true : false}
              labelId="output"
              onChange={(event) => {
                handleInputChange(event);
              }}
              name="output_currency"
              value={
                context === 'edit'
                  ? opOutputCurrencyId
                  : operation?.output_currency
              }
              sx={{
                borderRadius: '14px',
                backgroundColor: '#F0F2F5',
                height: '49.5px',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              {stock?.map((s) => {
                return (
                  <MenuItem
                    disabled={s.isClosed}
                    value={s._id}
                    key={s._id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {s.icon && <IconSpan>{s.icon}</IconSpan>} {s?.currency}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* MONTO DE SALIDA */}
          <NumericFormat
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            onValueChange={(values, e) =>
              handleChange(values, 'outputAmount', e)
            }
            customInput={TextField}
            value={
              context === 'edit' ? operation.output_amount : outputAmountValue
            }
            error={errors.output_amount}
            disabled={
              profile.role === 'liquidador_caja' || areSubOpsNotPending
                ? true
                : false
            }
            placeholder="Monto de salida"
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              style: {
                color: `black`,
                height: '46px',
                borderRadius: '10px',
                backgroundColor: 'white',
                paddingBottom: '12px',
              },
            }}
            variant="filled"
          />
        </CurrencyContainer>
      </CurrenciesContainer>
    </CurrenciesInputContainer>
  );
};
