import styled from 'styled-components';

export const BusinessUnitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: 260px;
  margin-top: 100px;
`;
