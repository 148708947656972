import React from 'react';
import { BusinessUnitsContainer } from './businessUnitsStyles';
import { Title } from '../Team/userStyles';
import BusinessUnitsTable from '../../components/BusinessUnits/BusinessUnitsTable';
import useBusinessUnits from './useBusinessUnits';
import {
  headCells,
  headCellsCdj,
  headCellsCdjArs,
} from '../../components/BusinessUnits/items';
import Loader from '../../components/Loader/Loader';
import { CenteredLoader } from '../../components/Loader/loaderStyles';
import useAuth from '../Login/useAuth';

const BusinessUnits = ({ notificationClicked }) => {
  const user = useAuth();

  const {
    gains,
    currentAccountsTotalUSD,
    currentAccountsTotalARS,
    totalAnomalieValueUSD,
    loading,
    actualBalanceUsd,
    actualBalanceArs,
    debts,
    totalAnomalieValueArs,
  } = useBusinessUnits(notificationClicked);

  const cdjValue =
    actualBalanceUsd -
    debts.usdtDebt -
    debts.cableDebt -
    currentAccountsTotalUSD -
    totalAnomalieValueUSD;

  const cdjValueArs =
    actualBalanceArs - currentAccountsTotalARS - totalAnomalieValueArs;

  const getValue = (value) => {
    if (user?.role === 'operador_usdt') {
      return '-';
    }
    return value;
  };

  if (loading) {
    return (
      <BusinessUnitsContainer>
        <Title>Unidades de Negocio</Title>
        <CenteredLoader>
          <Loader />
        </CenteredLoader>
      </BusinessUnitsContainer>
    );
  }

  return (
    <BusinessUnitsContainer>
      <Title>Unidades de Negocio</Title>
      <BusinessUnitsTable
        data={{
          gainUSD: getValue(gains.gainUSD),
          gainCambio: getValue(gains.gainCambio),
          gainUSDT: gains.gainUSDT,
          gainCable: getValue(gains.gainCable),
          gainSmallFace: getValue(gains.gainSmallFace),
        }}
        dataKeys={[
          'gainUSD',
          'gainCambio',
          'gainUSDT',
          'gainCable',
          'gainSmallFace',
        ]}
        headCells={headCells}
      />
      <BusinessUnitsTable
        data={{
          CDJ: getValue(cdjValue),
          balance: getValue(actualBalanceUsd),
          usdtDebt: debts.usdtDebt,
          cableDebt: getValue(debts.cableDebt),
          CC: getValue(currentAccountsTotalUSD),
          anomalies: getValue(totalAnomalieValueUSD),
        }}
        dataKeys={[
          'CDJ',
          'balance',
          'usdtDebt',
          'cableDebt',
          'CC',
          'anomalies',
        ]}
        headCells={headCellsCdj}
      />
      {user?.role !== 'operador_usdt' ? (
        <BusinessUnitsTable
          data={{
            CDJ: cdjValueArs,
            balance: actualBalanceArs,
            CCARS: currentAccountsTotalARS,
            anomaliesArs: totalAnomalieValueArs,
          }}
          dataKeys={['CDJ', 'balance', 'CCARS', 'anomaliesArs']}
          headCells={headCellsCdjArs}
        />
      ) : null}
    </BusinessUnitsContainer>
  );
};

export default BusinessUnits;
