import React from 'react';
import Notification from './Notification';
import { NotificationsContainer, NoNotsText } from './notificationStyles';

const Notifications = ({
  notifications,
  notificationClicked,
  setNotificationClicked,
  id,
}) => {
  return (
    <NotificationsContainer>
      {notifications.length > 0 ? (
        notifications?.map((n, index) => (
          <Notification
            date={n.createdAt}
            key={index}
            operationId={n.operationIndex}
            title={n.title}
            body={n.body}
            notificationClicked={notificationClicked}
            setNotificationClicked={setNotificationClicked}
            read={n.read.filter((r) => r.id === id)}
          />
        ))
      ) : (
        <NoNotsText>No hay notificaciones.</NoNotsText>
      )}
    </NotificationsContainer>
  );
};
export default Notifications;
