import { makeStyles } from '@mui/styles';
import theme from '../../theme';

export const useStyles = makeStyles({
  emptyMessage: {
    textAlign: 'center',
    marginBottom: '7rem',
    color: theme.palette.title.main,
  },
});
