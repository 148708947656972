import React from 'react';
import { OperationsButtonsContainer } from '../../containers/Operations/operationsStyles';
import Button from '../Button/Button';
import { CSVLink } from 'react-csv';
import ButtonOutline from '../Button/ButtonOutline';
import env from '../../env';

const RenderButtons = ({ operationsCSV, navigate, userRole }) => {
  return (
    <OperationsButtonsContainer>
      <div style={{ display: 'flex' }}>
        <Button
          disable={userRole === 'liquidador_caja'}
          onClick={() => {
            navigate('/agregar-operacion');
          }}
          title="CARGAR OPERACION"
        />
        <Button
          onClick={() => {
            window.open(env.GOOGLE_SHEET_URL, '_blank');
          }}
          title="ABRIR PLANILLA"
        />
      </div>

      <CSVLink
        separator={';'}
        data={operationsCSV}
        filename="operations.csv"
        target="_blank"
        style={{ textDecoration: 'none' }}
      >
        <ButtonOutline icon="csv" title="EXPORTAR CSV" />
      </CSVLink>
    </OperationsButtonsContainer>
  );
};

export default RenderButtons;
