import React from 'react';
import { UserTableCellBody } from '../../containers/Team/userStyles';
import { TableRow } from '@mui/material';
import formatNumber from '../../utils/formatNumber';

const BusinessUnitsTableIndividual = ({ data, dataKeys }) => {
  const renderBusinessTableCell = (content, i) => (
    <UserTableCellBody align="left" width={'300px'} key={i}>
      {formatNumber(content)}
    </UserTableCellBody>
  );

  return (
    <TableRow>
      {dataKeys?.map((key, i) => renderBusinessTableCell(data[key], i))}
    </TableRow>
  );
};

export default BusinessUnitsTableIndividual;
