import { Box, Typography } from '@mui/material';
import CustomTextField from '../CustomTextField/CustomTextField';
import ButtonWidth from '../Button/ButtonWidth';
import ForgotAndRecoverButtons from '../ForgotRecoverButtons/ForgotRecoverButtons';

const LoginForm = ({ onSubmit, error, theme, navigateFunction }) => (
  <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 20 }}>
    <CustomTextField
      id="email"
      label="Email"
      name="email"
      autoComplete="email"
      autoFocus
    />
    <CustomTextField
      name="password"
      label="Contraseña"
      type="password"
      id="password"
      autoComplete="current-password"
    />
    <Typography sx={{ color: 'red', fontWeight: '600' }}> 
      {error?.length > 0 ? error : null}
    </Typography>
    <div
      style={{
        width: '100%',
        marginTop: '30px',
      }}
    >
      <ButtonWidth type="submit" title="INICIAR SESIÓN" />
    </div>
    <ForgotAndRecoverButtons
      navigateFunction={navigateFunction}
      theme={theme}
    />
  </Box>
);

export default LoginForm;
