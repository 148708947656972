const {
  REACT_APP_API,
  REACT_APP_SOCKET_URL,
  REACT_APP_GOOGLE_SHEET_URL,
  REACT_APP_ENVIRONMENT,
} = process.env;

const env = {
  API_URL: REACT_APP_API,
  SOCKET_URL: REACT_APP_SOCKET_URL,
  GOOGLE_SHEET_URL: REACT_APP_GOOGLE_SHEET_URL,
  ENVIRONMENT: REACT_APP_ENVIRONMENT,
};
export default env;
