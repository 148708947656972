import React, { useState, useEffect } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Select } from '../../containers/Logistic/logisticStyles';
import { useOperations } from '../../containers/Operations/useOperations';
import theme from '../../theme';
import { useNavigate } from 'react-router-dom';

export const LogisticOperation = ({
  id,
  address,
  input,
  output,
  deliverys,
  assignedDelivery,
  client,
  exchangeRate,
  subOp,
  subOpId,
  operation,
}) => {
  const { changeDeliveryOperation, setIsModalOpen, setCurrentOperation } =
    useOperations();
  const navigate = useNavigate();

  const [selectedDelivery, setSelectedDelivery] = useState('');

  useEffect(() => {
    if (assignedDelivery) {
      setSelectedDelivery(assignedDelivery._id);
    }
  }, [assignedDelivery]);

  const handleChangeDelivery = async (e) => {
    const delId = e.target.value;
    setSelectedDelivery(delId);
    await changeDeliveryOperation(id, delId, subOpId);
  };

  const handleEdit = () => {
    navigate(`/editar-operacion/${id}`);
  };

  const handleOpenModal = () => {
    setCurrentOperation(operation);
    setIsModalOpen(true);
  };

  const cellStyles = {
    color: theme.palette.tableText.main,
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row" sx={cellStyles}>
        {subOp && (
          <span
            style={{
              height: '25px',
              width: '25px',
              backgroundColor: 'rgb(243, 86, 88)',
              borderRadius: '50%',
              display: 'inline-block',
            }}
          />
        )}
      </TableCell>
      <TableCell
        onClick={handleEdit}
        component="th"
        scope="row"
        sx={{
          ...cellStyles,
          cursor: 'pointer',
          ':hover': { color: theme.palette.tableDarkText.main },
        }}
      >
        {client}
      </TableCell>
      <TableCell component="th" scope="row" sx={cellStyles}>
        {address}
      </TableCell>
      <TableCell component="th" scope="row" sx={cellStyles}>
        <span style={{ color: '#21c432' }}>+</span> {input}
      </TableCell>
      <TableCell component="th" scope="row" sx={cellStyles}>
        {exchangeRate}
      </TableCell>
      <TableCell component="th" scope="row" sx={cellStyles}>
        <span style={{ color: 'red' }}>-</span> {output}
      </TableCell>
      <TableCell component="th" scope="row" sx={cellStyles}>
        <Select onChange={handleChangeDelivery} value={selectedDelivery}>
          <option disabled value="">
            Seleccionar Liq.
          </option>
          {deliverys.map((d, i) => (
            <option key={i} value={d._id}>
              {d.name}
            </option>
          ))}
        </Select>
      </TableCell>
      <TableCell component="th" scope="row" sx={cellStyles}>
        <span
          style={{
            color: theme.palette.tableText.main,
            cursor: 'pointer',
            ':hover': { color: theme.palette.tableDarkText.main },
          }}
          onClick={handleOpenModal}
        >
          Fraccionar
        </span>
      </TableCell>
    </TableRow>
  );
};
