import styled from 'styled-components';
import theme from '../../theme';

export const LoaderSpinner = styled.span`
  width: 38px;
  height: 38px;
  border: 5px solid ${theme.palette.btn.main};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const LoaderContainer = styled.div`
  display: flex;
  padding: 20px;
`;

export const CenteredLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
