import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { io } from 'socket.io-client';
import env from '../env/index';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const eventQueue = useRef([]); // Cola para almacenar eventos cuando el socket está desconectado

  const sendEvent = (event, data) => {
    if (socket && socket.connected) {
      socket.emit(event, data);
    } else {
      // Encola el evento si el socket no está conectado
      eventQueue.current.push({ event, data });
    }
  };

  const processQueue = () => {
    // Procesa la cola de eventos una vez que el socket se reconecta
    while (eventQueue.current.length > 0) {
      const { event, data } = eventQueue.current.shift(); // Obtiene y elimina el primer evento de la cola
      socket.emit(event, data);
    }
  };

  const connectSocket = () => {
    if (!env.SOCKET_URL) return;

    // Crea un nuevo socket si no existe o si se desconectó previamente
    let newSocket = socket;
    if (!newSocket || !newSocket.connected) {
      newSocket = io(env.SOCKET_URL, {
        transports: ['websocket', 'polling'],
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
      });

      newSocket.on('connect', () => {
        console.log('Conectado al WebSocket');
        processQueue(); // Procesa eventos encolados después de reconectar
      });
      newSocket.on('disconnect', (reason) =>
        console.log(`Desconectado del WebSocket debido a ${reason}`),
      );
      newSocket.on('connect_error', (error) =>
        console.error('Error de conexión:', error),
      );

      setSocket(newSocket);
    }
  };

  const disconnectSocket = () => {
    if (socket) {
      console.log('DESCONECTANDO SOCKET.');
      socket.disconnect();
      setSocket(null);
    }
  };

  useEffect(() => {
    connectSocket();
    return () => {
      disconnectSocket();
    };
  }, []);

  const value = { socket, connectSocket, disconnectSocket, sendEvent };

  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
};
