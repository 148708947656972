import React, { useState } from 'react';
import { InputSearch, SearchContainer } from './searcherStyles';
import RefreshIcon from '../../img/refresh.png';
import SearchIcon from '@mui/icons-material/Search';

const Searcher = ({
  context,
  stockForSearch,
  setStock,
  getStock,
  date,
  userForSearch,
  setUsers,
  getUsers,
  setFilters,
  filters,
  getOperations,
  setPage,
  page,
  getAnomalies,
  setSearch,
  search,
  getClientsPaginate,
  clientInfo,
  setBrokers,
  brokersForSearch,
  getBrokers,
  getCommissionsSearch,
  getCommissionsEmpty,
}) => {
  const [value, setValue] = useState('');

  const handleSearch = (e) => {
    if (context === 'stock') {
      const search = e.target.value.toLowerCase();

      if (search.length === 0) return setStock(stockForSearch);

      const filter = stockForSearch.filter((s) =>
        s.currency.toLowerCase().startsWith(search),
      );
      setStock(filter);
    } else if (context === 'users') {
      const search = e.target.value.toLowerCase();

      if (search.length === 0) {
        return setUsers(userForSearch);
      }

      const filter = userForSearch.filter((s) =>
        s.name.toLowerCase().startsWith(search),
      );

      setUsers(filter);
    } else if (context === 'brokers') {
      const search = e.target.value.toLowerCase();

      if (search.length === 0) {
        return setBrokers(brokersForSearch);
      }

      const filter = brokersForSearch.filter((s) =>
        s.name.toLowerCase().startsWith(search),
      );

      setBrokers(filter);
    }
  };

  const handleSearchIcon = (e) => {
    e.preventDefault();

    if (context === 'operations' || context === 'operations-clients') {
      setPage(1);
      getOperations(filters, page);
    }
    if (context === 'anomalies') {
      if (search) {
        getAnomalies(search, 1, date);
        setPage(1);
      }
    }
    if (context === 'clients') {
      if (search) {
        getClientsPaginate(
          search?.replace(/\s+/g, ' ')?.trim().toUpperCase(),
          1,
        );
        setPage(1);
      }
    }
    if (context === 'commissions') {
      if (search) {
        getCommissionsSearch();
      }
    }
  };

  const handleRefresh = () => {
    if (context === 'stock') {
      getStock();
    } else if (context === 'users') {
      setValue('');
      getUsers();
    } else if (context === 'brokers') {
      setValue('');
      getBrokers();
    } else if (context === 'operations') {
      setFilters({
        status: [],
        operationType: [],
        liquidatorType: [],
        search: null,
        inputCurrency: 'Todas',
        outputCurrency: 'Todas',
        dateRange: null,
        changed: false,
      });
      setPage(1);
      getOperations({
        inputCurrency: 'Todas',
        outputCurrency: 'Todas',
        search: null,
      });
    } else if (context === 'operations-clients') {
      setFilters({
        status: [],
        operationType: [],
        liquidatorType: [],
        clientName: clientInfo.name,
        inputCurrency: 'Todas',
        outputCurrency: 'Todas',
        dateRange: null,
        changed: false,
      });
      setPage(1);
      getOperations({
        inputCurrency: 'Todas',
        outputCurrency: 'Todas',
        clientName: clientInfo.name,
      });
      setValue('');
    } else if (context === 'anomalies') {
      getAnomalies(undefined, 1, date);
      setSearch(undefined);
      setPage(1);
    } else if (context === 'clients') {
      getClientsPaginate(undefined, 1);
      setSearch(undefined);
      setPage(1);
    } else if (context === 'commissions') {
      setSearch(undefined);
      getCommissionsEmpty();
    }
  };

  const handlerChange = (e) => {
    if (context === 'operations') {
      handleSearch(e);
      setValue(e.target.value);
      setFilters((prevFilters) => {
        return {
          ...prevFilters,
          search: e.target.value,
        };
      });
    } else if (context === 'operations-clients') {
      if (/^[0-9]*$/.test(e.target.value)) {
        setValue(e.target.value);
        setFilters((prevFilters) => {
          return {
            ...prevFilters,
            search: e.target.value,
          };
        });
      } else {
        return;
      }
    } else if (context === 'anomalies') {
      setSearch(e.target.value);
    } else if (context === 'clients') {
      setSearch(e.target.value);
    } else if (context === 'commissions') {
      setSearch(e.target.value);
    } else {
      handleSearch(e);
      setValue(e.target.value);
    }
  };

  return (
    <form
      onSubmit={(e) => handleSearchIcon(e)}
      onChange={(e) => handlerChange(e)}
    >
      <SearchContainer
        style={
          context === 'operations' || context === 'operations-clients'
            ? { margin: '0' }
            : {}
        }
      >
        {context === 'operations' ||
        context === 'operations-clients' ||
        context === 'users' ||
        context === 'anomalies' ||
        context === 'clients' ||
        context === 'brokers' ||
        context === 'commissions' ? (
          <img
            src={RefreshIcon}
            alt="refresh-icon"
            onClick={handleRefresh}
            style={{ width: '25px', cursor: 'pointer' }}
          />
        ) : null}

        <InputSearch
          value={search ? search : value}
          onChange={(e) => {
            handlerChange(e);
          }}
          placeholder={
            context === 'commissions' ? 'Nombre del cliente...' : 'Buscador...'
          }
        />
        {context !== 'stock' && context !== 'users' && context !== 'brokers' ? (
          <SearchIcon
            onClick={(e) => {
              handleSearchIcon(e);
            }}
            sx={{
              color: 'white',
              backgroundColor: '#D43436',
              borderRadius: '50px',
              width: '35px',
              height: '35px',
              padding: '8px',
              cursor: 'pointer',
            }}
          />
        ) : null}
      </SearchContainer>
    </form>
  );
};

export default Searcher;
