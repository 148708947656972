/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '../../components/Button/Button';
import {
  BoxMui,
  MyProfileBtnContainer as BtnContainer,
  TextFieldMui,
  MyProfileFormContainer as FormContainer,
  MyProfileContainer,
} from './userStyles';
import useUsers from './useUsers';
import themeMui from '../../theme';
import { validateUserForm } from './usersValidation';
import useAuth from '../Login/useAuth';
import useNotistack from '../Notistack/useNotistack';

const MyProfile = ({ notificationClicked }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { getProfile, updateUser, profile } = useUsers();
  const { showNotification } = useNotistack();
  const user = useAuth();

  const [errors, setErrors] = useState([]);

  const [userEdited, setUserEdited] = useState({
    name: null,
    email: null,
    cellphone: null,
    userRole: null,
    password: '',
    repeatPassword: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = validateUserForm(userEdited, 'EditUser');
    if (response.valid) {
      updateUser(userEdited, setErrors, id, 'my-profile');
    } else {
      setErrors(response);
      response[0]?.forEach((error) => {
        showNotification(error, 'error');
      });
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setUserEdited({ ...userEdited, [name]: value });
  };

  const handleUserInfo = async () => {
    await getProfile(id);
  };

  useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate('/operaciones');
    }
  }, [notificationClicked.isClicked]);

  useEffect(() => {
    handleUserInfo();
    setUserEdited({
      ...userEdited,
      name: profile?.name,
      cellphone: profile?.cellphone,
      email: profile?.email,
      userRole: profile?.role,
    });
  }, [profile?.name, profile?.cellphone, profile?.email, profile?.role]);

  return (
    <MyProfileContainer>
      <h1>Mi perfil</h1>
      <h3>{user.role}</h3>
      <BoxMui
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
          height: '700px',
        }}
      >
        <FormContainer>
          <TextFieldMui
            sx={{
              input: {
                color: `${themeMui.palette.inputText.main}`,
              },
              width: '497px',
            }}
            InputLabelProps={{
              style: {
                color: `${themeMui.palette.inputText.main}
                        `,
              },
            }}
            id="firstName"
            label={userEdited.name === '' ? 'Nombre' : false}
            autoFocus
            name="name"
            step="0.01"
            variant="standard"
            onChange={handleChange}
            error={errors[1]?.name}
            value={userEdited.name}
            helperText="Nombre"
          />
          <TextField
            sx={{
              input: {
                color: `${themeMui.palette.inputText.main}`,
              },
              width: '497px',
            }}
            InputLabelProps={{
              style: { color: `${themeMui.palette.inputText.main}` },
            }}
            fullWidth
            id="phone"
            variant="standard"
            label={userEdited.cellhpone === '' ? 'Teléfono' : false}
            autoComplete="telefono"
            name="cellphone"
            onChange={handleChange}
            error={errors[1]?.cellphone}
            value={userEdited.cellphone}
            helperText="Telefono"
          />
          <TextField
            sx={{
              input: {
                color: `${themeMui.palette.inputText.main}`,
              },
              width: '497px',
            }}
            InputLabelProps={{
              style: { color: `${themeMui.palette.inputText.main}` },
            }}
            InputProps={{
              style: { color: `${themeMui.palette.inputText.main}` },
            }}
            fullWidth
            id="email"
            variant="standard"
            label={userEdited.email === '' ? 'Email' : false}
            autoComplete="email"
            name="email"
            onChange={handleChange}
            error={errors[1]?.email}
            value={userEdited.email}
            helperText="Email"
          />
          <TextField
            sx={{
              input: {
                color: `${themeMui.palette.inputText.main}`,
              },
              width: '497px',
            }}
            InputLabelProps={{
              style: { color: `${themeMui.palette.inputText.main}` },
            }}
            fullWidth
            label={'Contraseña'}
            type="password"
            variant="standard"
            id="password"
            autoComplete="new-password"
            name="password"
            onChange={handleChange}
            error={errors[1]?.password}
            value={userEdited.password}
          />

          <TextField
            sx={{
              input: {
                color: `${themeMui.palette.inputText.main}`,
              },
              width: '497px',
            }}
            InputLabelProps={{
              style: { color: `${themeMui.palette.inputText.main}` },
            }}
            fullWidth
            id="repeatPassword"
            variant="standard"
            label={'Repetir contraseña'}
            autoComplete="password"
            name="repeatPassword"
            onChange={handleChange}
            type="password"
            error={errors[1]?.repeatPassword}
            value={userEdited.repeatPassword}
          />
        </FormContainer>
        <BtnContainer>
          <Button type="submit" title="GUARDAR" />
        </BtnContainer>
      </BoxMui>
    </MyProfileContainer>
  );
};

export default MyProfile;
