import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import React from 'react';
import { BtnPayCotization } from '../../containers/Operations/operationsStyles';
import theme from '../../theme';

const titles = ['CLIENTE', 'SOLICITUD', 'PRECIO', 'TELEFONO', 'FECHA'];

const CotizationsTable = ({ cotizations, handleSubmitCotization }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: '2px 4px 12px -2px rgba(0, 0, 0, 0.26)',
        borderRadius: '10px',
        height: '750px',
        width: '98%',
      }}
    >
      <h2 style={{ marginLeft: '20px' }}>Cotizaciones solicitadas</h2>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {titles.map((t) => (
              <TableCell
                key={t}
                sx={{
                  fontWeight: '500',
                  color: `${theme.palette.tableTitle.main}`,
                  fontSize: '14px',
                  whiteSpace: 'nowrap',
                }}
                align="left"
              >
                {t}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {cotizations.map((c) => (
            <TableRow>
              <TableCell sx={{ color: `${theme.palette.tableText.main}` }}>
                {c.client.name.toUpperCase()}
              </TableCell>
              <TableCell sx={{ color: `${theme.palette.tableText.main}` }}>
                Venta de {c.output_currency.currency} por{' '}
                {c.input_currency.currency}
              </TableCell>
              <TableCell
                sx={{
                  color: `${theme.palette.tableText.main}`,
                  width: '500px',
                }}
              >
                <form
                  onSubmit={(e) => {
                    handleSubmitCotization(e, c._id);
                  }}
                >
                  <TextField
                    name="cotization"
                    placeholder="Cotizacion"
                    variant="standard"
                  />

                  <BtnPayCotization type="submit">
                    CARGAR COTIZACION
                  </BtnPayCotization>
                </form>
              </TableCell>
              <TableCell sx={{ color: `${theme.palette.tableText.main}` }}>
                {c.client.cellphone}
              </TableCell>
              <TableCell sx={{ color: `${theme.palette.tableText.main}` }}>
                {c.created}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CotizationsTable;
