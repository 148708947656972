import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import theme from '../../theme';

const LogisticOperationHead = () => {
  const headerCellStyles = {
    fontWeight: '600',
    color: theme.palette.tableTitle.main,
  };

  const columnTitles = [
    'SUBOP',
    'Cliente',
    'Dirección',
    'Entrada',
    'TC',
    'Salida',
    'Delivery asignado',
  ];

  return (
    <TableHead>
      <TableRow>
        {columnTitles.map((title, index) => (
          <TableCell key={index} sx={headerCellStyles}>
            {title}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default LogisticOperationHead;
