import React, { useEffect } from 'react';
import {
  Pagination,
  Stack,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';

import LogisticOperationHead from './LogisticOperationHead';
import { LogisticOperation } from './LogisticOperation';
import Loader from '../Loader/Loader';
import theme from '../../theme';
import { PaginationContainer } from '../../containers/Operations/operationsStyles';
import { makeStyles } from '@material-ui/core/styles';
import { useOperations } from '../../containers/Operations/useOperations';
import formatNumber from '../../utils/formatNumber';
import CustomModal from '../CustomModal/CustomModal';
import ModalContent from './Modal';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaginationItem-root': {
      color: '#7C8198',
    },
    '& .Mui-selected': {
      background: `linear-gradient(to left, #f35658, #d43436)`,
      border: '0',
      color: '#FFFFFF',
    },
  },
}));

const LogisticOperationTable = ({
  operations,
  deliverys,
  loading,
  setPage,
  page,
  numberOfPages,
  filter,
}) => {
  const {
    getOperationsWithouDeliveries,
    isModalOpen,
    setIsModalOpen,
    currentOperation,
  } = useOperations();
  const classes = useStyles();

  useEffect(() => {
    getOperationsWithouDeliveries(filter, page, 'logistic');
  }, [page]);

  const renderOperations = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan="100%">
            <Loader />
          </td>
        </tr>
      );
    }

    if (!operations.length && !loading) {
      return (
        <tr>
          <td
            colSpan="100%"
            style={{
              textAlign: 'center',
              color: theme.palette.title.main,
              padding: '1rem',
            }}
          >
            No hay operaciones.
          </td>
        </tr>
      );
    }

    return operations
      .filter((o) =>
        o.subOperations.length > 0
          ? o.subOperations.some((subOp) => !subOp.assignedDelivery)
          : !o.assignedDelivery,
      )
      .map((o) =>
        o.subOperations.length > 0
          ? o.subOperations
              .filter(
                (subOp) =>
                  subOp.liquidator_type === 'DELIVERY' &&
                  !subOp.assignedDelivery &&
                  subOp.status !== 'Finalizado' &&
                  subOp.status !== 'Liquidado' &&
                  subOp.status !== 'Confirmado Delivery',
              )
              .map((subOp) => (
                <LogisticOperation
                  id={o._id}
                  address={subOp.address}
                  input={`${o.input_currency?.currency} ${formatNumber(
                    subOp.input_amount,
                  )}`}
                  output={`${o.output_currency?.currency} ${formatNumber(
                    subOp.output_amount,
                  )}`}
                  deliverys={deliverys}
                  assignedDelivery={subOp.assignedDelivery}
                  client={o.client?.name}
                  key={subOp._id}
                  subOpId={subOp._id}
                  exchangeRate={o.exchange_rate}
                  marketPrice={o.marketPrice}
                  subOp={true}
                  page={page}
                  operation={o}
                />
              ))
          : !o.assignedDelivery && (
              <LogisticOperation
                key={o._id}
                id={o._id}
                address={o.address}
                input={
                  o.input_currency
                    ? `${o.input_currency.currency} ${formatNumber(
                        o.input_amount,
                      )}`
                    : null
                }
                output={
                  o.output_currency
                    ? `${o.output_currency.currency} ${formatNumber(
                        o.output_amount,
                      )}`
                    : null
                }
                assignedDelivery={o.assignedDelivery}
                deliverys={deliverys}
                client={o.client?.name}
                exchangeRate={o.exchange_rate}
                marketPrice={o.marketPrice}
                subOp={false}
                page={page}
                operation={o}
              />
            ),
      );
  };

  return (
    <>
      {isModalOpen && (
        <CustomModal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
          <ModalContent
            deliverys={deliverys}
            id={currentOperation?._id}
            setIsModalOpen={setIsModalOpen}
          />
        </CustomModal>
      )}
      <TableContainer
        sx={{
          boxShadow: '0px 3px 6px #00000029',
          borderRadius: '16px',
        }}
      >
        <Table
          sx={{
            width: '100%',
          }}
          aria-label="Logistic Operations Table"
        >
          <LogisticOperationHead />
          <TableBody>{renderOperations()}</TableBody>
        </Table>
        <PaginationContainer>
          <Stack
            spacing={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'end',
              marginTop: '1rem',
            }}
          >
            <Pagination
              hideNextButton={page === numberOfPages}
              hidePrevButton={page === 1}
              count={numberOfPages}
              page={page}
              size="large"
              onChange={(e, value) => setPage(value)}
              color="primary"
              variant="outlined"
              classes={{ ul: classes.root }}
            />
          </Stack>
        </PaginationContainer>
      </TableContainer>
    </>
  );
};

export default LogisticOperationTable;
