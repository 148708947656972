import styled, { keyframes } from 'styled-components';
import React from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';

const swing = keyframes`
  0% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(-20deg);
  }
`;

export const AnimatedIcon = styled(NotificationsIcon)`
  animation: ${swing} 0.5s infinite;
`;
