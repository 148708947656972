import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import Icon from '../../img/notification.png';
import close from '../../img/close.png';
import { BtnCancelDialog, BtnSaveDialog, Title } from './alertDialogStyles.js';
import contextMap from './contextMap.js';

export default function AlertDialog({
  handlerAction,
  page,
  objectId,
  open,
  context,
  onClose,
  object,
}) {
  const { title, message, action } = contextMap[context] || {
    title: 'Eliminar usuario',
    message: '¿Está seguro que desea eliminar este usuario?',
    action: 'ELIMINAR',
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
        sx={{}}
      >
        <div style={{ margin: '4rem 10rem' }}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 30,
              top: 25,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={close} alt="" />
          </IconButton>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={Icon} alt="" style={{ width: '47px', height: '56px' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Title>{title}</Title>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span style={{ fontSize: '16px', color: 'rgb(56,71,100,0.55)' }}>
              {message}
            </span>
          </div>
          {object?.currentAccount?.length ? (
            <span style={{ fontSize: '16px', color: 'rgb(56,71,100,0.55)' }}>
              Este cliente posee una cuenta corriente con divisas que alteraron
              stock, de eliminarse el monto quedará allí.
            </span>
          ) : null}
          {action ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '3.7rem',
                marginBottom: '2.2rem',
              }}
            >
              <BtnCancelDialog onClick={() => onClose()}>
                CANCELAR
              </BtnCancelDialog>
              <BtnSaveDialog onClick={() => handlerAction(objectId)}>
                {action}
              </BtnSaveDialog>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '3.7rem',
                marginBottom: '2.2rem',
              }}
            >
              <BtnSaveDialog onClick={() => onClose()}>ENTENDIDO</BtnSaveDialog>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
}
