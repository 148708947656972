import { useOperations } from '../Operations/useOperations';

const useHandleSubmit = (setIsModalOpen) => {
  const { createSuboperations } = useOperations();

  const handleSubmit = async (subOps, id) => {
    await createSuboperations(subOps, id);
    setIsModalOpen(false);
  };

  return handleSubmit;
}; 

export default useHandleSubmit;
