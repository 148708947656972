import React, { forwardRef } from 'react';
import {
  ActualBalance,
  CloseCurrency,
  DifferenceContainer,
  DifferenceSpan,
  ModalContent,
  ModalContentContainer,
  RecordDifference,
  YourActualBalance,
} from '../../containers/Stock/stockStyles';
import { TextField } from '@mui/material';
import formatNumber from '../../utils/formatNumber';
import Button from '../Button/Button';
import useStock from '../../containers/Stock/useStock';

const ModalContentComponent = forwardRef(
  (
    {
      selectedCurrency,
      handleChangeDifference,
      difference,
      thereIsDifference,
      actualBalanceMinusDifference,
      customHandleClosure,
      setIsOpen,
      currencies,
      setCurrencies,
    },
    ref,
  ) => {
    const { updateIsClosed } = useStock();

    const handleCancel = async () => {
      setIsOpen(false);
      await updateIsClosed(selectedCurrency._id, false, difference);
      const updatedCurrencies = {
        ...currencies,
        [selectedCurrency.currency]: {
          ...currencies[selectedCurrency.currency],
          isClosed: false,
          difference
          
        },
      };
      setCurrencies(updatedCurrencies);
    };

    return (
      <ModalContent ref={ref}>
        <h2>Estás cerrando {selectedCurrency.currency}.</h2>
        <ModalContentContainer>
          <YourActualBalance>
            <h3>¿Tu balance actual es el siguiente?</h3>
            <ActualBalance>
              <h3>${formatNumber(selectedCurrency.actual_balance)}</h3>
            </ActualBalance>
          </YourActualBalance>
          <RecordDifference>
            <h4>En caso de haber diferencias, ingresa tu balance actual.</h4>
            <CloseCurrency>
              <TextField
                autoFocus
                type="number"
                placeholder="Registrar diferencia"
                onChange={(e) => handleChangeDifference(e)}
              />

              <DifferenceContainer>
                <DifferenceSpan>
                  {difference?.length > 0
                    ? `Vas a cerrar ${selectedCurrency.currency} con esta diferencia:`
                    : ''}
                </DifferenceSpan>
                <span>
                  {difference?.length > 0 ? `$${thereIsDifference()}` : ''}
                </span>
              </DifferenceContainer>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Button
                  title="Cerrar divisa"
                  onClick={() =>
                    customHandleClosure(
                      selectedCurrency.currency,
                      actualBalanceMinusDifference,
                    )
                  }
                >
                  Cerrar divisa
                </Button>
                <Button title="Cancelar" onClick={() => handleCancel()}>
                  Cancelar
                </Button>
              </div>
            </CloseCurrency>
          </RecordDifference>
        </ModalContentContainer>
      </ModalContent>
    );
  },
);

export default ModalContentComponent;
