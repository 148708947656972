import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';

const DeliveryTable = ({
  deliveries,
  DeliveryComponent,
  handleCenter,
  positionHistory,
  isPositionHistoryVisible,
  handlePositionHistoryVisibility,
  setIsPositionHistoryVisible,
  setDate,
  setIsDatePickerVisible,
  isDatePickerVisible,
  handleAddPosition,
  handleRemovePosition,
}) => {
  return (
    <TableContainer
      sx={{
        padding: '10px 30px',
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '16px',
        marginTop: '15px',
        marginBottom: '15px',
      }}
    >
      <Table sx={{ width: '100%' }}>
        <TableBody>
          {deliveries?.map((delivery, i) => (
            <DeliveryComponent
              key={i}
              object={delivery}
              name={delivery.name || delivery.user?.name}
              position={delivery.position}
              handleCenter={handleCenter}
              id={delivery._id}
              positionHistory={positionHistory}
              isPositionHistoryVisible={isPositionHistoryVisible}
              handlePositionHistoryVisibility={handlePositionHistoryVisibility}
              setIsPositionHistoryVisible={setIsPositionHistoryVisible}
              setDate={setDate}
              setIsDatePickerVisible={setIsDatePickerVisible}
              isDatePickerVisible={isDatePickerVisible}
              handleAddPosition={handleAddPosition}
              handleRemovePosition={handleRemovePosition}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DeliveryTable;
