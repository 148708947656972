export const validateBrokerForm = (brokerData) => {
  const validateEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
  const validateNum = /^[0-9]+$/;

  if (brokerData.name === '') {
    return [['El campo "Nombre" no puede estar vacío.'], { name: true }];
  }

  if (brokerData.cellphone !== '') {
    if (!validateNum.test(brokerData.cellphone)) {
      return [
        ['El campo "Teléfono" solo debe tener números.'],
        { cellphone: true },
      ];
    }
  }

  if (brokerData.email !== '') {
    if (!validateEmail.test(brokerData.email)) {
      return [['Correo electronico inválido.'], { email: true }];
    }
  }

  return { valid: true };
};
