import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BackgroundImage, StyledContainer } from './loginStyles';
import { Box, CssBaseline, Typography } from '@mui/material';
import ButtonWidth from '../../components/Button/ButtonWidth';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import LogoAndRights from '../../components/LogoAndRights/LogoAndRights';
import LoginHeader from '../../components/LoginHeader/LoginHeader';

const TwoFactor = ({
  setToken,
  handle2FASubmission,
  error,
  getCurrentYear,
}) => {
  const theme = createTheme();
  return (
    <BackgroundImage>
      <ThemeProvider theme={theme}>
        <LogoAndRights getCurrentYear={getCurrentYear} />
        <LoginHeader
          title="Iniciar sesión"
          subtitle="Verificación de dos pasos"
          theme={theme}
        />
        <StyledContainer
          component="main"
          maxWidth="xs"
          sx={{
            minHeight: '60vh',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="subtitle1" align="center">
              Introduce el código que aparece en la aplicación:
            </Typography>
            <CustomTextField
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handle2FASubmission();
                }
              }}
              margin="normal"
              type="text"
              label="Código 2FA"
              onChange={(e) => setToken(e.target.value)}
            />
            <ButtonWidth onClick={handle2FASubmission} title="Verificar" />
            <Typography sx={{ color: 'red', fontWeight: '600', marginTop: 2 }}>
              {error}
            </Typography>
          </Box>
        </StyledContainer>
      </ThemeProvider>
    </BackgroundImage>
  );
};

export default TwoFactor;
