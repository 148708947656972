import {
  ContainerLogoAndRights,
  RightsReserved,
} from '../../containers/Login/loginStyles';
import Logo from '../Logo/Logo';

const LogoAndRights = ({ getCurrentYear }) => (
  <ContainerLogoAndRights>
    <Logo />
    <RightsReserved>
      © {getCurrentYear()} LYM, made by{' '}
      <a
        style={{ color: '#fff', textDecoration: 'none' }}
        href="https://www.jrc.dev/"
        target={'_blank'}
      >
        JRC Studio
      </a>
    </RightsReserved> 
  </ContainerLogoAndRights>
);

export default LogoAndRights;
