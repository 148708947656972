import { TableCell, TableHead, TableRow } from '@mui/material';
import theme from '../../theme';
import React from 'react';

const CustomTableCell = ({ children, align = 'left' }) => (
  <TableCell
    sx={{
      fontWeight: '600',
      color: theme.palette.tableTitle.main,
    }}
    align={align}
  >
    {children}
  </TableCell>
);

const AnomaliesHead = () => {
  const columnTitles = [
    'CLIENTE',
    'MONTO',
    'ENTRADA O SALIDA',
    'ID OPERACIÓN',
    '',
    '',
  ];

  return (
    <TableHead>
      <TableRow>
        {columnTitles.map((title, index) => (
          <CustomTableCell key={index} align={index > 0 ? 'left' : 'inherit'}>
            {title}
          </CustomTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default AnomaliesHead;
