import React, { useState, useEffect } from 'react';
import {
  BreadcumsContainerClient,
  ClientInfo,
  ContainerClientDetail,
  ContainerClientDetailInfo,
  BoxTitle,
  ClientCC,
  BoxMui,
  TotalInfo,
  ClientTableCell,
  ClientTableCellBody,
  RightButtonsContainer,
  TotalClientTableCell,
  TotalInfoValuesContainer,
  TotalInfoValues,
  TotalClientTableCellContainer,
  AddCurrencyButton,
  JustDiv,
  JustDiv2,
  JustDiv3,
  LoaderContainer,
  JustDiv4,
} from './clientStyles';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import OperationsTable from '../../components/Operation/OperationsTable';
import FilterPopover from '../Operations/FilterPopover';
import Searcher from '../../components/Searcher/Searcher';
import { useOperations } from '../Operations/useOperations';
import useClients from './useClients';
import useStock from '../Stock/useStock';
import { CSVLink } from 'react-csv';
import ButtonOutline from '../../components/Button/ButtonOutline';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import formatNumber from '../../utils/formatNumber';
import { handleFormatCsv } from './helpers';

const ClientDetail = ({ notificationClicked }) => {
  const { clients, getClientInfo, clientInfo, loading } = useClients();
  const { stock } = useStock();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    status: [],
    operationType: [],
    liquidatorType: [],
    search: null,
    clientName: null,
    inputCurrency: 'Todas',
    outputCurrency: 'Todas',
    dateRange: 'null',
  });

  const { id } = useParams();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const {
    getOperations,
    operations,
    numberOfPages,
    deleteOperation,
    loading: isLoadingOperations,
  } = useOperations();

  const cleanFormatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formatCreatedAt = `${day}/${month}/${year}`;
    return formatCreatedAt;
  };

  useEffect(() => {
    getClientInfo(id);
  }, []);

  useEffect(() => {
    if (clientInfo.name) {
      getOperations({ filters, clientName: clientInfo?.name }, page);
    }
    setFilters({ ...filters, clientName: clientInfo?.name });
  }, [clientInfo, page]);

  useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate(`/operaciones/${notificationClicked.id}`);
    }
  }, [notificationClicked.isClicked]);

  const handleAddCurrentAccount = () => {
    navigate(`/agregar-operacion/ingreso-cta-cte/${clientInfo._id}`);
  };

  const order = [
    'USD',
    'ARS',
    'EURO GRANDE',
    'EURO CHICO',
    'LIBRAS',
    'BRL',
    'LEMAN OCEAN (CABLE) USD',
    'USDT',
  ];

  const orderedAccounts = order.map(
    (currency) =>
      clientInfo?.currentAccount?.find(
        (account) => account.currency.name === currency,
      ) || { currency: { name: currency }, amount: 0 },
  );

  return (
    <ContainerClientDetail>
      {loading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <BreadcumsContainerClient>
            <BreadcrumbsMui
              path="/clientes"
              title={`${clientInfo?.name}`}
              prev="Clientes"
            />
          </BreadcumsContainerClient>
          <ContainerClientDetailInfo>
            <ClientInfo>
              <JustDiv>
                <BoxTitle
                  style={{
                    zIndex: 1,
                  }}
                >
                  <h1>{`${clientInfo?.name}`}</h1>
                </BoxTitle>
                <BoxMui
                  component="form"
                  noValidate
                  sx={{
                    zIndex: 0,
                  }}
                >
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <ClientTableCell align="left">
                            FECHA DE CREACIÓN
                          </ClientTableCell>
                          <ClientTableCell align="left">
                            NOMBRE COMPLETO
                          </ClientTableCell>
                          <ClientTableCell align="left">EMAIL</ClientTableCell>
                          <ClientTableCell align="left">
                            TELÉFONO
                          </ClientTableCell>
                          <ClientTableCell align="left">
                            DIRECCIÓN
                          </ClientTableCell>
                          <ClientTableCell align="left">
                            DETALLE
                          </ClientTableCell>
                          <ClientTableCell align="left">ID</ClientTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <ClientTableCellBody
                            align="left"
                            sx={{
                              color: `#7C8098`,
                            }}
                          >
                            {`${cleanFormatCreatedAt(clientInfo?.createdAt)}`}
                          </ClientTableCellBody>
                          <ClientTableCellBody align="left">
                            {`${clientInfo?.name}`}
                          </ClientTableCellBody>
                          <ClientTableCellBody align="left">
                            {`${clientInfo?.email ? clientInfo.email : '-'}`}
                          </ClientTableCellBody>
                          <ClientTableCellBody align="left">
                            {`${
                              clientInfo?.cellphone ? clientInfo.cellphone : '-'
                            }`}
                          </ClientTableCellBody>
                          <ClientTableCellBody align="left">
                            {`${
                              clientInfo?.address ? clientInfo.address : '-'
                            }`}
                          </ClientTableCellBody>
                          {`${clientInfo?.addressDetail}` !== undefined &&
                          null ? (
                            <ClientTableCellBody align="left">
                              {`${clientInfo?.addressDetail}`}
                            </ClientTableCellBody>
                          ) : (
                            <ClientTableCellBody align="left">
                              {`-`}
                            </ClientTableCellBody>
                          )}
                          <ClientTableCellBody align="left" width={'300px'}>
                            {`${clientInfo._id}`}
                          </ClientTableCellBody>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </BoxMui>
              </JustDiv>
            </ClientInfo>
            <ClientCC>
              <TotalInfo>
                <TotalClientTableCellContainer>
                  <TotalClientTableCell>SALDO:</TotalClientTableCell>
                  <AddCurrencyButton onClick={handleAddCurrentAccount}>
                    +
                  </AddCurrencyButton>
                </TotalClientTableCellContainer>

                <TotalInfoValuesContainer>
                  {orderedAccounts?.map((e, i) => {
                    return (
                      <TotalInfoValues key={i}>
                        {e.currency.name}:{' '}
                        <span
                          style={{
                            color:
                              e.amount > 0
                                ? 'lightgray'
                                : e.amount < 0
                                ? '#cb2d3e'
                                : 'darkgray',
                          }}
                        >
                          {formatNumber(e.amount)}
                        </span>
                      </TotalInfoValues>
                    );
                  })}
                </TotalInfoValuesContainer>
              </TotalInfo>
            </ClientCC>
          </ContainerClientDetailInfo>
          <JustDiv2>
            <JustDiv3>
              <RightButtonsContainer>
                <CSVLink
                  data={handleFormatCsv(operations)}
                  filename={`${clientInfo?.name}_operations `}
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                  separator=";"
                >
                  <ButtonOutline icon="csv" title="EXPORTAR CSV" />
                </CSVLink>
              </RightButtonsContainer>
            </JustDiv3>

            <OperationsTable
              deleteOperation={deleteOperation}
              setPage={setPage}
              operations={operations}
              page={page}
              numberOfPages={numberOfPages}
              loading={isLoadingOperations}
            >
              <JustDiv4>
                <FilterPopover
                  setFilters={setFilters}
                  setAnchorEl={setAnchorEl}
                  setPage={setPage}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  getOperations={getOperations}
                  filters={filters}
                  anchorEl={anchorEl}
                  stock={stock}
                  clients={clients}
                  startDate={startDate}
                  endDate={endDate}
                  context="clientDetail"
                />
                <Searcher
                  context={'operations-clients'}
                  clientInfo={clientInfo}
                  setFilters={setFilters}
                  getOperations={getOperations}
                  setPage={setPage}
                  page={page}
                  filters={filters}
                />
              </JustDiv4>
            </OperationsTable>
          </JustDiv2>
        </>
      )}
    </ContainerClientDetail>
  );
};

export default ClientDetail;
