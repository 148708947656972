import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { UserTableCellBody } from '../../containers/Team/userStyles';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../Dialog/AlertDialog';
import formatNumber from '../../utils/formatNumber';

const ActionIcons = ({ brokerId, setOpenAlert, navigate }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '34px',
      }}
    >
      <EditIcon
        onClick={() => navigate(`/editar-comisionista/${brokerId}`)}
        style={{ cursor: 'pointer' }}
      >
        Editar
      </EditIcon>

      <DeleteIcon
        onClick={() => setOpenAlert(true)}
        style={{ cursor: 'pointer' }}
      >
        Eliminar
      </DeleteIcon>
    </div>
  );
};

const Broker = ({ broker, deleteBroker }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const navigate = useNavigate();

  const handlerDeleteBroker = useCallback(() => {
    deleteBroker(broker._id);
    setOpenAlert(false);
  }, [broker._id, deleteBroker]);

  const totalAmount = useMemo(() => {
    return broker?.commissions?.reduce(
      (acc, c) => (c.status === 'active' ? acc + c.amount : acc),
      0,
    );
  }, [broker]);

  return (
    <TableRow>
      <UserTableCellBody
        align="left"
        width={'30%'}
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(`/comisionistas/${broker._id}`)}
      >
        {broker.name}
      </UserTableCellBody>

      <UserTableCellBody align="left" width={'300px'}>
        {formatNumber(totalAmount)} USD
      </UserTableCellBody>

      <UserTableCellBody align="left" width={'300px'}>
        <ActionIcons
          brokerId={broker._id}
          setOpenAlert={setOpenAlert}
          navigate={navigate}
        />

        <AlertDialog
          handlerAction={handlerDeleteBroker}
          page={1}
          objectId={broker._id}
          open={openAlert}
          onClose={() => setOpenAlert(false)}
          context={'brokers'}
        />
      </UserTableCellBody>
    </TableRow>
  );
};

export default Broker;
