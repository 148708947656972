import React from 'react';
import { Modal } from '@mui/material';

const CustomModal = ({ isOpen, setIsOpen, children }) => {
  return (
    <Modal
      disableAutoFocus
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
