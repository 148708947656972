import { useState } from 'react';
import fetchFromApi from '../../utils/fetchFromApi';
import useNotistack from '../Notistack/useNotistack';
import { useNavigate } from 'react-router-dom';
import validateOperation from '../Operations/operationsValidations';
import moment from 'moment-timezone';

const useLogistic = () => {
  const [filter, setFilter] = useState({
    liquidatorType: ['DELIVERY', 'multiple'],
    status: 'Todos',
  });

  const [loadingButtons, setLoadingButtons] = useState(false);
  const [allDeliveries, setAllDeliveries] = useState([]);
  const [deliveryPosition, setDeliveryPosition] = useState();
  const [delivery, setDelivery] = useState({});
  const [errors, setErrors] = useState({
    userId: false,
    currency: false,
    balance: false,
  });
  const { showNotification } = useNotistack();
  const [positionHistory, setPositionHistory] = useState([]);
  const [positionHistoryLoading, setPositionHistoryLoading] = useState(false);
  const [isPositionHistoryVisible, setIsPositionHistoryVisible] =
    useState(false);
  const [currentUserId, setCurrentUserId] = useState('');
  const navigate = useNavigate();

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [date, setDate] = useState(
    moment().tz('America/Argentina/Buenos_Aires'),
  );

  const handlePositionHistoryVisibility = async (o) => {
    let deliveryId = o?.user?.id ? o?.user?.id : o;

    if (deliveryId && date) {
      setIsPositionHistoryVisible(!isPositionHistoryVisible);
      setCurrentUserId(deliveryId);
      await getPositionHistoryById(deliveryId, date);
    }
  };

  const getAllDeliveries = async (set) => {
    try {
      let result = await fetchFromApi('GET', `/profile/getdeliveries/all`);

      set(result);
    } catch (error) {
      showNotification('Error al obtener todos los deliveries.', 'error');
    }
  };

  const getProfile = async (id) => {
    try {
      let result = await fetchFromApi('GET', `/profile/${id}`);
      if (result) {
        setDelivery(result);
      }
    } catch (error) {
      showNotification(
        'Error al obtener los datos del operador delivery.',
        'error',
      );
    }
  };

  const getPositionByProfile = async (id) => {
    try {
      let result = await fetchFromApi('GET', `/position/${id}`);
      if (result) setDeliveryPosition(result);
    } catch (error) {
      showNotification(
        'Error al obtener los datos del operador delivery.',
        'error',
      );
    }
  };

  const registerPosition = async (position, positionToTransfer) => {
    if (
      validateOperation({
        userId: position.userId,
        userId: position.userIdToTransfer,
        currency: position.currency,
        balance: position.balance,
        operation_type: position.type,
      })
    ) {
      const [errors, message] = validateOperation({
        userId: position.userId,
        userId: position.userIdToTransfer,
        currency: position.currency,
        balance: position.balance,
        operation_type: position.type,
      });

      setErrors(errors);

      return showNotification(message, 'error');
    }

    let response;

    try {
      setLoadingButtons(true);
      response = await fetchFromApi('POST', `position/`, { position });

      response = await fetchFromApi('POST', `position/`, {
        position: positionToTransfer,
      });

      showNotification('Transferencia realizada con éxito.', 'success');
      navigate('/logistica');
    } catch (error) {
      showNotification(error.response.data.message, 'error');
      setLoadingButtons(false);
    }
  };

  const handleChangeDate = async (newDate) => {
    setDate(newDate);
    console.log('Updating date for user ID:', currentUserId);
    if (currentUserId) {
      await getPositionHistoryById(currentUserId, date);
    }
  };

  const getPositionHistoryById = async (id, newDate) => {
    setPositionHistoryLoading(true);
    const cms = 'cms';
    try {
      if (id && newDate) {
        const formattedDate = newDate.format('YYYY-MM-DD');
        let result = await fetchFromApi(
          'GET',
          `position-history/${id}?cms=${cms}&cmsDate=${formattedDate}`,
        );

        setPositionHistory(result);
        setPositionHistoryLoading(false);
        return result;
      }
    } catch (error) {
      showNotification(
        'Error al obtener el historial de posiciones del operador delivery.',
        'error',
      );
      setPositionHistoryLoading(false);
    }
  };

  const handleAddPosition = async (id) => {
    try {
      navigate(`/agregar-operacion/posicion/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemovePosition = async (id) => {
    try {
      navigate(`/agregar-operacion/quitar-posicion/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    getAllDeliveries,
    allDeliveries,
    setAllDeliveries,
    getProfile,
    registerPosition,
    getPositionByProfile,
    deliveryPosition,
    errors,
    loadingButtons,
    positionHistory,
    getPositionHistoryById,
    handlePositionHistoryVisibility,
    isPositionHistoryVisible,
    setDate,
    isDatePickerVisible,
    setIsDatePickerVisible,
    currentUserId,
    setCurrentUserId,
    setIsPositionHistoryVisible,
    handleChangeDate,
    positionHistoryLoading,
    date,
    handleAddPosition,
    filter,
    setFilter,
    handleRemovePosition,
  };
};

export default useLogistic;
