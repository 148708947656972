import PropTypes from 'prop-types';

import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import theme from '../../../theme';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../Login/useAuth';
import usePanic from './usePanic';

import AlertDialog from '../../../components/Dialog/AlertDialog';
import Notifications from '../../Notifications/Notifications';
import useNotifications from '../../Notifications/useNotifications';
import { AnimatedIcon } from './headerStyles';
import { useSocket } from '../../../utils/socket';

const Header = ({ notificationClicked, setNotificationClicked }) => {
  const user = useAuth();
  const { socket, disconnectSocket } = useSocket();

  const {
    notifications,
    setNotifications,
    getNotifications,
    markAllNotificationsAsRead,
    unreadNotifications,
    setUnreadNotifications,
  } = useNotifications();
  const { id } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const { handlePanic } = usePanic();

  const panicLocalStorage = localStorage.getItem('panic');

  const [showPanicAlert, setShowPanicAlert] = useState(false);
  const [menuContext, setMenuContext] = useState('');
  const [animation, setAnimation] = useState(false);

  const handlePanicAlert = () => {
    handlePanic();
    setShowPanicAlert(false);
  };

  const navigate = useNavigate();

  const handleMenu = (event, ctx) => {
    setMenuContext(ctx);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    handleClose();
    localStorage.removeItem('user');
    navigate('/login');
    disconnectSocket();
  };

  const anomationBell = () => {
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
    }, 3000);
  };

  // useEffect(() => {
  //   if (!socket) return;

  //   socket?.on('notifications', (notification) => {
  //     getNotifications();
  //     anomationBell();
  //     const not = notification;

  //     if (
  //       not.senderId !== user?.id &&
  //       !not.operationRole.includes(user?.role)
  //     ) {
  //       setNotifications((prevNotifications) => [...prevNotifications, not]);
  //     }
  //     setUnreadNotifications(unreadNotifications + 1);
  //   });

  //   return () => {
  //     socket?.off('notifications');
  //   };
  // }, []);

  // useEffect(() => {
  //   getNotifications();
  // }, []);

  const handleMarkNotificationsAsRead = async () => {
    await markAllNotificationsAsRead(notifications);
    setUnreadNotifications(0);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={(e) => handleMenu(e, 'menu')}
        sx={{
          color: theme.palette.icons.main,
        }}
      >
        <AccountCircleIcon />
      </IconButton>
      {/* <IconButton
        size="large"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        sx={{
          color: theme.palette.icons.main,
        }}
        onClick={(e) => handleMenu(e, 'notification')}
      >
        {unreadNotifications > 0 ? (
          <div>
            <span
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                fontSize: '10px',
                color: 'white',
                backgroundColor: `${theme.palette.unread.main}`,
                borderRadius: '50%',
                padding: '2px 5px',
                fontWeight: 'bold',
              }}
            >
              {unreadNotifications}
            </span>
            {animation ? (
              <AnimatedIcon />
            ) : (
              <NotificationsIcon onClick={handleMarkNotificationsAsRead} />
            )}
          </div>
        ) : (
          <NotificationsIcon />
        )}
      </IconButton> */}
      <Menu
        id="menu-appbar"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
      >
        {menuContext === 'menu' ? (
          <>
            <MenuItem onClick={() => navigate(`/my-profile/${id}`)}>
              Mi perfil
            </MenuItem>
            <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
            {user?.role === 'superadmin' && (
              <>
                <AlertDialog
                  handlerAction={handlePanicAlert}
                  open={showPanicAlert}
                  context={panicLocalStorage === 'true' ? 'disabled' : 'panic'}
                  onClose={() => setShowPanicAlert(false)}
                />
                <MenuItem onClick={() => setShowPanicAlert(true)}>
                  Botón de pánico
                </MenuItem>
              </>
            )}
          </>
        ) : (
          <Notifications
            notifications={notifications}
            notificationClicked={notificationClicked}
            setNotificationClicked={setNotificationClicked}
            id={id}
          />
        )}
      </Menu>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
