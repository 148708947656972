import { AppBar, Toolbar } from '@mui/material';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Header from '../Layout/Header/index.jsx';
import useAuth from '../Login/useAuth.js';
import useUsers from '../Team/useUsers';

const PrivateRoute = ({ notificationClicked, setNotificationClicked }) => {
  const { id, deliveryId } = useParams();
  const { profile, getProfile } = useUsers();

  const HeaderComponent = () => {
    return (
      <AppBar
        enableColorOnDark
        color="inherit"
        sx={{
          bgcolor: 'transparent',
          boxShadow: 'none',
          top: '30px',
          right: '30px',
          width: '70%',
          position: 'absolute',
        }}
      >
        <Toolbar>
          <Header
            notificationClicked={notificationClicked}
            setNotificationClicked={setNotificationClicked}
          />
        </Toolbar>
      </AppBar>
    );
  };

  const newCanAccess = {
    superadmin: [
      '/stock',
      '/operaciones',
      `/operaciones/${id}`,
      '/operaciones-precarga',
      '/logistica',
      '/anomalias',
      '/clientes',
      `/clientes/${id}`,
      '/equipo',
      '/agregar-usuario',
      `/editar-usuario/`,
      '/agregar-divisa',
      `/profile/${id}`,
      `/editar-cliente/${id}`,
      '/agregar-clientes',
      '/agregar-operacion',
      `/editar-operacion/${id}`,
      `/operation/${id}`,
      `/editar-divisa/${id}`,
      `/my-profile/${id}`,
      `/transferir-entre-deliveries/`,
      '/comisionistas',
      `/editar-comisionista/${id}`,
      ` /comisionistas/${id}`,
      `/agregar-operacion/ingreso-cta-cte/${id}`,
      `/agregar-operacion/posicion/${deliveryId}`,
      `/agregar-operacion/quitar-posicion/${deliveryId}`,
    ],
    operador_usdt: [],
    operador_pesos: [],
    operador_cable: [],
    operador_cambio: [],
    operador_logistica: [],
    liquidador_caja: [],
    tesoreria: [],
  };

  const canAccess = {
    superadmin: [
      '/stock',
      '/operaciones',
      `/operaciones/${id}`,
      '/operaciones-precarga',
      '/logistica',
      '/anomalias',
      '/clientes',
      `/clientes/${id}`,
      '/equipo',
      '/agregar-usuario',
      `/editar-usuario/`,
      `/profile/${id}`,
      `/editar-cliente/${id}`,
      '/agregar-clientes',
      '/agregar-operacion',
      `/editar-operacion/${id}`,
      `/operation/${id}`,
      '/agregar-divisa',
      `/editar-divisa/${id}`,
      `/my-profile/${id}`,
      `/transferir-entre-deliveries/`,
      '/comisionistas',
      '/agregar-comisionista',
      `/editar-comisionista/${id}`,
      `/comisionistas/${id}`,
      `/agregar-operacion/ingreso-cta-cte/${id}`,
      '/unidades-negocio',
      `/agregar-operacion/posicion/${deliveryId}`,
      `/agregar-operacion/quitar-posicion/${deliveryId}`,
    ],
    operador_usdt: [
      '/stock',
      '/logistica',
      '/anomalias',
      '/clientes',
      `/clientes/${id}`,
      '/agregar-clientes',
      `/editar-cliente/${id}`,
      '/agregar-clientes',
      '/operaciones',
      `/operaciones/${id}`,
      `/operation/${id}`,
      '/agregar-operacion',
      `/editar-operacion/${id}`,
      `/my-profile/${id}`,
      '/agregar-divisa',
      `/editar-divisa/${id}`,
      '/comisionistas',
      '/agregar-comisionista',
      `/editar-comisionista/${id}`,
      `/comisionistas/${id}`,
      `/agregar-operacion/ingreso-cta-cte/${id}`,
      '/unidades-negocio',
    ],
    operador_pesos: [
      '/clientes',
      `/clientes/${id}`,
      '/operaciones',
      `/operaciones/${id}`,
      '/agregar-operacion',
      '/stock',
      '/comisionistas',
      '/agregar-comisionista',
      `/editar-comisionista/${id}`,
      `/comisionistas/${id}`,
      `/editar-operacion/${id}`,
      `/agregar-operacion/ingreso-cta-cte/${id}`,
      '/anomalias',
    ],
    operador_cable: [
      '/clientes',
      `/clientes/${id}`,
      '/stock',
      '/anomalias',
      '/operaciones',
      `/operaciones/${id}`,
      `/operation/${id}`,
      `/editar-operacion/${id}`,
      '/agregar-operacion',
      `/my-profile/${id}`,
      '/agregar-divisa',
      `/editar-divisa/${id}`,
      `/editar-cliente/${id}`,
      '/agregar-clientes',
      '/comisionistas',
      '/agregar-comisionista',
      `/editar-comisionista/${id}`,
      `/comisionistas/${id}`,
      `/agregar-operacion/ingreso-cta-cte/${id}`,
      '/anomalias',
    ],
    operador_cambio: [
      '/clientes',
      `/clientes/${id}`,
      '/stock',
      `/editar-divisa/${id}`,
      '/operaciones',
      `/operaciones/${id}`,
      '/agregar-operacion',
      `/my-profile/${id}`,
      '/operaciones-precarga',
      '/comisionistas',
      '/agregar-comisionista',
      `/editar-comisionista/${id}`,
      `/comisionistas/${id}`,
      `/editar-operacion/${id}`,
      `/agregar-operacion/ingreso-cta-cte/${id}`,
      '/anomalias',
    ],
    operador_logistica: [
      '/logistica',
      '/operaciones',
      '/agregar-operacion',
      `/operaciones/${id}`,
      `/operation/${id}`,
      `/my-profile/${id}`,
      `/transferir-entre-deliveries/`,
      '/comisionistas',
      '/agregar-comisionista',
      `/editar-comisionista/${id}`,
      `/comisionistas/${id}`,
      `/editar-operacion/${id}`,
      `/agregar-operacion/ingreso-cta-cte/${id}`,
      `/agregar-operacion/posicion/${deliveryId}`,
      `/agregar-operacion/quitar-posicion/${deliveryId}`,
      '/anomalias',
    ],
    liquidador_caja: [
      '/operaciones',
      '/anomalias',
      `/editar-operacion/${id}`,
      `/operaciones/${id}`,
      `/my-profile/${id}`,
      '/comisionistas',
      '/agregar-comisionista',
      `/editar-comisionista/${id}`,
      `/comisionistas/${id}`,
      `/agregar-operacion/ingreso-cta-cte/${id}`,
      '/anomalias',
    ],
    tesoreria: [
      '/operaciones',
      `/operaciones/${id}`,
      '/agregar-operacion',
      '/anomalias',
      '/stock',
      '/clientes',
      `/clientes/${id}`,
      `/my-profile/${id}`,
      '/comisionistas',
      '/agregar-comisionista',
      `/editar-comisionista/${id}`,
      `/comisionistas/${id}`,
      `/editar-operacion/${id}`,
      `/agregar-operacion/ingreso-cta-cte/${id}`,
      '/anomalias',
    ],
  };
  const user = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      getProfile(user.id);
    }
  }, []);

  if (!user) {
    return <Navigate to="/login" replace />;
  }
  if (
    profile?.panic
      ? newCanAccess[user.role]?.some((route) => route === location.pathname)
      : canAccess[user.role]?.some((route) => route === location.pathname)
  ) {
    return (
      <>
        <HeaderComponent />
        <Outlet />
      </>
    );
  } else {
    return <Navigate to="/unauthorized" replace />;
  }
};

export { PrivateRoute };
