import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
} from '@mui/material';
import theme from '../../theme';
import formatNumber from '../../utils/formatNumber';
import { operationStatus } from '../../utils/constants';
import { useOperations } from '../../containers/Operations/useOperations';

const SubOpsCollapseTable = ({
  subOp,
  index,
  input_currency,
  output_currency,
  opId,
  filters,
  page,
  getOperations,
}) => {
  const [statusValue, setStatusValue] = useState(subOp.status);
  const { changeSubOperationStatus } = useOperations();

  const userLocal = JSON.parse(localStorage.getItem('user'));

  const handleStatusSubOperation = async (e) => {
    setStatusValue(e.target.value);

    await changeSubOperationStatus(opId, index, {
      statusValue: e.target.value,
    });
    await getOperations(filters, page);
  };

  const getOptions = (userRole, statusValue) =>
    operationStatus.map((option) => ({
      value: option,
      label: option.toUpperCase(),
      disabled: isOptionDisabled(userRole, statusValue, option),
    }));

  const isOptionDisabled = (userRole, statusValue, option) => {
    if (userRole === 'tesoreria') {
      return [
        'Confirmado Cliente',
        'Confirmado LYM',
        'Pendiente',
        'Confirmado Delivery',
      ].includes(option);
    }
    if (userRole === 'liquidador_caja') {
      return ['Confirmado Delivery', 'Finalizado'].includes(option);
    }
    return (
      ((statusValue === 'Confirmado LYM' ||
        statusValue === 'Confirmado Cliente') &&
        ['Confirmado Cliente', 'Confirmado LYM'].includes(option)) ||
      option === 'Confirmado Delivery'
    );
  };

  const TableHeadCell = ({ children }) => (
    <TableCell
      sx={{
        fontWeight: '500',
        color: `${theme.palette.tableTitle.main}`,
        paddingTop: '20px',
        paddingRight: '50px',
        fontSize: '12px',
      }}
      align="left"
    >
      {children}
    </TableCell>
  );

  const TableCellBody = ({ children }) => (
    <TableCell
      align="left"
      sx={{
        color: `${theme.palette.tableText.main}`,
        fontWeight: '500',
        borderBottom: 'none',
        fontSize: '12px',
      }}
    >
      {children}
    </TableCell>
  );

  return (
    <div>
      <h3
        style={{
          margin: 0,
          padding: '16px',
          fontSize: '14px',
          color: '#384764',
        }}
      >
        SubOperacion {index + 1}
      </h3>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableHeadCell>Divisa de entrada</TableHeadCell>
            <TableHeadCell>Monto de entrada</TableHeadCell>
            <TableHeadCell>Divisa de salida</TableHeadCell>
            <TableHeadCell>Monto de salida</TableHeadCell>
            <TableHeadCell>Anomalía de entrada</TableHeadCell>
            <TableHeadCell>Anomalía de salida</TableHeadCell>
            <TableHeadCell>Liquidador</TableHeadCell>
            <TableHeadCell>Estado</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCellBody>{input_currency}</TableCellBody>
            <TableCellBody>
              {subOp.input_amount
                ? '$' + formatNumber(subOp.input_amount)
                : '---'}
            </TableCellBody>
            <TableCellBody>{output_currency}</TableCellBody>
            <TableCellBody>
              {subOp.output_amount
                ? '$' + formatNumber(subOp.output_amount)
                : '---'}
            </TableCellBody>
            <TableCellBody>
              {subOp.anomalie.length &&
              subOp?.anomalie?.find((e) => {
                return e.type === 'input';
              })?.amount
                ? '$' +
                  formatNumber(
                    subOp?.anomalie?.find((e) => {
                      return e.type === 'input';
                    })?.amount,
                  )
                : '---'}
            </TableCellBody>
            <TableCellBody>
              {subOp.anomalie.length &&
              subOp?.anomalie?.find((e) => {
                return e.type === 'output';
              })?.amount
                ? '$' +
                  formatNumber(
                    subOp?.anomalie?.find((e) => {
                      return e.type === 'output';
                    })?.amount,
                  )
                : '---'}
            </TableCellBody>
            <TableCellBody>
              {subOp.liquidator_type}
              {'  '}
              {subOp.liquidator_type === 'DELIVERY'
                ? subOp.assignedDelivery?.name?.toUpperCase()
                : null}
            </TableCellBody>
            <TableCellBody>
              <Select
                variant="standard"
                disableUnderline
                value={statusValue}
                onChange={handleStatusSubOperation}
                sx={{
                  color: `${theme.palette.tableText.main}`,
                  fontSize: '14px',
                }}
              >
                {getOptions(userLocal.role, statusValue).map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </TableCellBody>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default SubOpsCollapseTable;
