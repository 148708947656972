import {
  LoginSubtitleText,
  LoginTitle,
  LoginTitleText,
} from '../../containers/Login/loginStyles';

const LoginHeader = ({ title, subtitle, theme }) => (
  <LoginTitle>
    <LoginTitleText>{title}</LoginTitleText>
    <div style={{ width: '63%' }}>
      <LoginSubtitleText
        style={{ fontFamily: `${theme.typography.fontWeightRegular}` }}
      >
        {subtitle}
      </LoginSubtitleText> 
    </div>
  </LoginTitle>
);

export default LoginHeader;
