export default function formatNumber(number) {
  return number?.toLocaleString('de-DE', {
    useGrouping: true,
    minimumFractionDigits: 2,
  });
}

export function formatInputNumber(e) {
  const rawNumber = e.target.value.replace(/\./g, '');
  const formattedNumber = Number(rawNumber)?.toLocaleString();
  return formattedNumber;
}

export function formatCalcNumber(num) {
  num = num.toString();
  const rawNumber = num.replace(/\./g, '');
  const formattedNumber = Number(rawNumber)?.toLocaleString();
  return formattedNumber;
}

export function formatExchangeRate(number) {
  return number?.toString().replace('.', ',');
}
