export const headCells = [
  'GANANCIA TOTAL',
  'CAMBIO',
  'USDT',
  'CABLE',
  'CARA CHICA',
];
export const headCellsCdj = [
  'CDJ',
  'BALANCE USD',
  'SALDO USDT',
  'SALDO CABLE',
  'CC',
  'ANOMALIAS',
];

export const headCellsCdjArs = [
  'CDJ ARS',
  'BALANCE ARS',
  'CC ARS',
  'ANOMALIAS',
];
