/* eslint-disable no-unused-vars*/
import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import useLogistic from '../useLogistic';
import useConfig from './useConfig';
import icon from './map_icon.png';
import selectedIcon from './map_icon_selected.png';

import {
  MapContainer,
  LogisticSubTitle,
  TransferButton,
  SwitchAndButtonContainer,
  RetryContainer,
  RetryButton,
  StackContainer,
  NoRegisteredDelivery,
} from '../logisticStyles';

import DeliveryTable from '../../../components/Logistic/DeliveryTable';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IosSwitch from '../../../components/Brokers/IosSwitch';
import { useSocket } from '../../../utils/socket';
import Delivery from '../../../components/Logistic/Delivery';
import ActiveDelivery from '../../../components/Logistic/ActiveDelivery';
import PositionHistoryModal from '../../../components/Logistic/PositionHistoryModal';
import TableWithDeliveries from '../../../components/Logistic/TableWithDeliveries';
import { useOperations } from '../../Operations/useOperations';
import useUsers from '../../Team/useUsers';

const containerStyle = {
  width: '100%',
  height: '370px',
  borderRadius: '16px',
  marginLeft: '5px',
  marginRight: '5px',
};

function Map() {
  const [onlineDeliverys, setOnlineDeliverys] = useState([]);
  const [zoom, setZoom] = useState(11);
  const [selected, setSelected] = useState(false);
  const [switcher, setSwitcher] = useState(false);
  const [selectedDeliveryId, setSelectedDeliveryId] = useState(null);
  const [onlyOnline, setOnlyOnline] = useState(true);
  const [showMap, setShowMap] = useState(false);

  const { socket } = useSocket();
  const navigate = useNavigate();
  const [isLoaded, map, setMap, center, setCenter, onLoad, onUnmount] =
    useConfig();
  const {
    getAllDeliveries,
    allDeliveries,
    setAllDeliveries,
    positionHistory,
    isPositionHistoryVisible,
    handlePositionHistoryVisibility,
    setIsPositionHistoryVisible,
    setIsDatePickerVisible,
    isDatePickerVisible,
    date,
    setDate,
    currentUserId,
    getPositionHistoryById,
    positionHistoryLoading,
    handleAddPosition,
    handleRemovePosition,
  } = useLogistic();

  const [filter, setFilter] = useState({
    liquidatorType: ['DELIVERY', 'multiple'],
    status: 'Todos',
  });

  const {
    getOperationsWithDeliveries,
    operationsWithDeliveries,
    loadingWithDeliveries,
    numberOfPagesWithDeliveries,
    setOperationsWithDeliveries,
    setOperationsWithoutDeliveries,
  } = useOperations();

  const { getDeliverys, deliverys } = useUsers();
  const [page, setPage] = useState(1);

  const filteredOperations = operationsWithDeliveries?.filter(
    (o) => o.status !== 'Finalizado',
  );

  useEffect(() => {
    getDeliverys();
  }, []);

  useEffect(() => {
    getOperationsWithDeliveries(filter, page, 'logistic');
  }, [filter, page]);

  const handleChangeDate = async (newDate) => {
    const momentDate = moment(newDate).tz('America/Argentina/Buenos_Aires');
    setDate(momentDate);
    await getPositionHistoryById(currentUserId, momentDate);
  };

  const handleCenter = async (o) => {
    setCenter({
      lat: o.location.coords.latitude,
      lng: o.location.coords.longitude,
    });
    setZoom(30);
  };

  const handleSelect = (o) => {
    o.selected = o.selected ? false : true;
    setSelected(o.selected ? false : true);
    setSelectedDeliveryId(o.user.id);
  };

  const HandleAllDeliveries = () => {
    setOnlyOnline(false);
    getAllDeliveries(setAllDeliveries);
  };

  const handleOnlyOnline = () => {
    setOnlyOnline(true);
  };

  const HandlerswitcherChange = () => {
    setSwitcher(!switcher);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleToggleMapVisibility = () => {
    setShowMap(!showMap);
  };

  useEffect(() => {
    if (!socket) return;

    const handleLocationEvent = (location) => {
      const pos = JSON.parse(location);
      setOnlineDeliverys(pos);
    };

    socket.on('location', handleLocationEvent);

    return () => {
      socket.off('location', handleLocationEvent);
    };
  }, [socket]);

  useEffect(() => {
    if (switcher) {
      handleOnlyOnline();
    } else {
      HandleAllDeliveries();
    }
  }, [switcher]);

  useEffect(() => {
    getOperationsWithDeliveries({}, page, 'Logistic');
  }, []);

  useEffect(() => {
    return () => {
      setOperationsWithDeliveries([]);
      setOperationsWithoutDeliveries([]);
    };
  }, []);

  return isLoaded ? (
    <MapContainer>
      <LogisticSubTitle>Operaciones asignadas y mapa</LogisticSubTitle>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        {showMap && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {onlineDeliverys?.map((o) => (
              <Marker
                onClick={() => handleSelect(o)}
                key={o.user.id}
                position={{
                  lat: o?.location?.coords?.latitude,
                  lng: o?.location?.coords?.longitude,
                }}
                icon={o.user.id === selectedDeliveryId ? selectedIcon : icon}
              />
            ))}
          </GoogleMap>
        )}

        <TableWithDeliveries
          operations={filteredOperations}
          deliverys={deliverys}
          loading={loadingWithDeliveries}
          setPage={setPage}
          page={page}
          numberOfPages={numberOfPagesWithDeliveries}
          filter={filter}
        />
      </div>

      <SwitchAndButtonContainer>
        <StackContainer>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              sx={
                !switcher
                  ? { color: '#21c432', transition: '200ms' }
                  : { color: '#e0e0e0', transition: '200ms' }
              }
            >
              Online
            </Typography>
            <div>
              <IosSwitch
                onClick={(e) => {
                  HandlerswitcherChange(e);
                }}
                checked={!switcher}
                colorProp="#21c432"
                inputProps={{ 'aria-label': 'ant design' }}
              />
            </div>
            <Typography
              sx={
                !switcher
                  ? { color: '#e0e0e0', transition: '200ms' }
                  : { transition: '200ms' }
              }
            >
              Todos
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              sx={
                showMap
                  ? { color: '#21c432', transition: '200ms' }
                  : { color: '#e0e0e0', transition: '200ms' }
              }
            >
              Mapa
            </Typography>
            <IosSwitch
              onClick={handleToggleMapVisibility}
              checked={!showMap}
              colorProp="#21c432"
              inputProps={{ 'aria-label': 'control de visibilidad del mapa' }}
            />
          </Stack>
        </StackContainer>

        <TransferButton
          onClick={() => {
            navigate(`/transferir-entre-deliveries/`);
          }}
        >
          TRANSFERIR
        </TransferButton>
      </SwitchAndButtonContainer>
      <div>
        {onlineDeliverys.length === 0 && onlyOnline ? (
          <RetryContainer>
            <NoRegisteredDelivery>
              No hay liquidadores en línea.
            </NoRegisteredDelivery>
            <RetryButton onClick={() => handleRefresh()}>
              Reintentar
            </RetryButton>
          </RetryContainer>
        ) : onlyOnline ? (
          <div>
            {isPositionHistoryVisible ? (
              <PositionHistoryModal
                isPositionHistoryVisible={isPositionHistoryVisible}
                positionHistory={positionHistory}
                setIsPositionHistoryVisible={setIsPositionHistoryVisible}
                isDatePickerVisible={isDatePickerVisible}
                setIsDatePickerVisible={setIsDatePickerVisible}
                currentUserId={currentUserId}
                handleChangeDate={handleChangeDate}
                date={date}
                positionHistoryLoading={positionHistoryLoading}
              />
            ) : (
              <DeliveryTable
                deliveries={onlineDeliverys}
                DeliveryComponent={ActiveDelivery}
                handleCenter={handleCenter}
                positionHistory={positionHistory}
                isPositionHistoryVisible={isPositionHistoryVisible}
                handlePositionHistoryVisibility={
                  handlePositionHistoryVisibility
                }
                setIsPositionHistoryVisible={setIsPositionHistoryVisible}
                setDate={setDate}
                setIsDatePickerVisible={setIsDatePickerVisible}
                isDatePickerVisible={isDatePickerVisible}
                currentUserId={currentUserId}
                handleChangeDate={handleChangeDate}
                date={date}
                handleAddPosition={handleAddPosition}
                handleRemovePosition={handleRemovePosition}
              />
            )}
          </div>
        ) : isPositionHistoryVisible ? (
          <PositionHistoryModal
            isPositionHistoryVisible={isPositionHistoryVisible}
            positionHistory={positionHistory}
            setIsPositionHistoryVisible={setIsPositionHistoryVisible}
            isDatePickerVisible={isDatePickerVisible}
            setIsDatePickerVisible={setIsDatePickerVisible}
            currentUserId={currentUserId}
            handleChangeDate={handleChangeDate}
            date={date}
            positionHistoryLoading={positionHistoryLoading}
          />
        ) : allDeliveries.length && !onlyOnline ? (
          <DeliveryTable
            deliveries={allDeliveries}
            DeliveryComponent={Delivery}
            handleCenter={handleCenter}
            positionHistory={positionHistory}
            isPositionHistoryVisible={isPositionHistoryVisible}
            handlePositionHistoryVisibility={handlePositionHistoryVisibility}
            setIsPositionHistoryVisible={setIsPositionHistoryVisible}
            setDate={setDate}
            setIsDatePickerVisible={setIsDatePickerVisible}
            isDatePickerVisible={isDatePickerVisible}
            currentUserId={currentUserId}
            handleChangeDate={handleChangeDate}
            date={date}
            handleAddPosition={handleAddPosition}
            handleRemovePosition={handleRemovePosition}
          />
        ) : (
          <NoRegisteredDelivery>
            No hay liquidadores registrados.
          </NoRegisteredDelivery>
        )}
      </div>
    </MapContainer>
  ) : (
    <></>
  );
}

export default Map;
