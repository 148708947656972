import React, { useEffect, useState } from 'react';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';
import useStock from './useStock';
import TextField from '@mui/material/TextField';
import { ThemeProvider, Checkbox, FormControlLabel } from '@mui/material';
import Button from '../../components/Button/Button';
import CancelButton from '../../components/Button/CancelButton';
import { NumericFormat } from 'react-number-format';
import themeMui from '../../theme';
import {
  BoxMui,
  BoxTitle,
  BtnContainer,
  AddStockContainer,
  BreadcumsContainer,
} from './stockStyles';
import { useNavigate } from 'react-router-dom';

const AddCurrency = ({ notificationClicked }) => {
  const { registerCurrency, errors, loading } = useStock();
  const navigate = useNavigate();

  const [currencyState, setCurrencyState] = useState({
    currency: '',
    exchange_rate: undefined,
    initial_balance: undefined,
    isDigital: false,
    icon: '',
  });

  const handleChange = (e) => {
    setCurrencyState({
      ...currencyState,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (event) => {
    setCurrencyState({
      ...currencyState,
      isDigital: event.target.checked,
    });
  };

  const handleChangeBalance = (values) => {
    setCurrencyState({
      ...currencyState,
      initial_balance: values.floatValue,
    });
  };

  const handleChangeTc = (values) => {
    setCurrencyState({
      ...currencyState,
      exchange_rate: values.floatValue,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    registerCurrency(
      currencyState.currency,
      currencyState.exchange_rate,
      currencyState.initial_balance,
      currencyState.isDigital,
      currencyState.icon
    );
  };

  useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate(`/operaciones/${notificationClicked.id}`);
    }
  }, [navigate, notificationClicked]);

  return (
    <ThemeProvider theme={themeMui}>
      <AddStockContainer>
        <BreadcumsContainer>
          <BreadcrumbsMui title="Agregar divisa" prev="Stock" path={'/stock'} />
        </BreadcumsContainer>
        <BoxTitle>
          <h1>Agregar divisa</h1>
        </BoxTitle>
        <BoxMui component="form" noValidate onSubmit={handleSubmit}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '100px',
              gap: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                gap: '10px',
              }}
            >
              <TextField
                sx={{
                  input: {
                    color: `${themeMui.palette.inputText.main}`,
                  },
                  width: 'calc(50% - 5px)',
                }}
                InputLabelProps={{
                  style: { color: `${themeMui.palette.inputText.main}` },
                }}
                required
                label="Divisa"
                autoFocus
                name="currency"
                variant="standard"
                onChange={handleChange}
                error={errors?.currency}
              />

              <NumericFormat
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
                required
                customInput={TextField}
                sx={{
                  input: {
                    color: `${themeMui.palette.inputText.main}`,
                  },
                  width: 'calc(50% - 5px)',
                }}
                InputLabelProps={{
                  style: { color: `${themeMui.palette.inputText.main}` },
                }}
                label="Tipo de cambio"
                variant="standard"
                name="exchange_rate"
                value={currencyState.exchange_rate}
                onValueChange={handleChangeTc}
                error={errors?.exchange_rate}
                allowNegative={false}
              />
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                gap: '10px',
              }}
            >
              <NumericFormat
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
                required
                customInput={TextField}
                sx={{
                  input: {
                    color: `${themeMui.palette.inputText.main}`,
                  },
                  width: 'calc(50% - 5px)',
                }}
                InputLabelProps={{
                  style: { color: `${themeMui.palette.inputText.main}` },
                }}
                label="Balance Inicial"
                variant="standard"
                name="initial_balance"
                value={currencyState.initial_balance}
                onValueChange={handleChangeBalance}
                error={errors?.initial_balance}
              />

              <TextField
                sx={{
                  input: {
                    color: `${themeMui.palette.inputText.main}`,
                  },
                  width: 'calc(50% - 5px)',
                }}
                InputLabelProps={{
                  style: { color: `${themeMui.palette.inputText.main}` },
                }}
                label="Icono"
                variant="standard"
                name="icon"
                onChange={handleChange}
                error={errors?.icon}
              />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={currencyState.isDigital}
                  onChange={handleCheckboxChange}
                  name="isDigital"
                  color="primary"
                  sx={{
                    color: 'grey',
                    '&.Mui-checked': {
                      color: 'green',
                    },
                  }}
                />
              }
              label="¿Es digital?"
              sx={{
                marginTop: '15px',
                color: `${themeMui.palette.inputText.main}`,
              }}
            />
          </div>
          <BtnContainer>
            <Button type="submit" title="GUARDAR" disable={loading} />
            <CancelButton title="CANCELAR" onClick={() => navigate('/stock')} />
          </BtnContainer>
        </BoxMui>
      </AddStockContainer>
    </ThemeProvider>
  );
};

export default AddCurrency;
