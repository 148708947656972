import React, { useEffect, useState } from 'react';
import {
  Pagination,
  Stack,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';

import LogisticOperationHead from './LogisticOperationHead';
import { LogisticOperation } from './LogisticOperation';
import Loader from '../Loader/Loader';
import theme from '../../theme';
import { PaginationContainer } from '../../containers/Operations/operationsStyles';
import { makeStyles } from '@material-ui/core/styles';
import formatNumber from '../../utils/formatNumber';
import useAuth from '../../containers/Login/useAuth';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaginationItem-root': {
      color: '#7C8198',
    },
    '& .Mui-selected': {
      background: `linear-gradient(to left, #f35658, #d43436)`,
      border: '0',
      color: '#FFFFFF',
    },
  },
}));

const TableWithDeliveries = ({
  operations,
  deliverys,
  loading,
  setPage,
  page,
  numberOfPages,
  filter,
}) => {
  const classes = useStyles();
  const user = useAuth();
  const [filteredOperations, setFilteredOperations] = useState([]);

  useEffect(() => {
    if (!loading && operations) {
      const filtered = operations
        .filter((o) =>
          user?.role === 'operador_usdt'
            ? o.operation_type === 'COMP.VTA.USDT'
            : true,
        )
        .flatMap((o) => {
          if (o.subOperations.length > 0) {
            return o.subOperations
              .filter(
                (subOp) =>
                  subOp.liquidator_type === 'DELIVERY' &&
                  subOp.assignedDelivery &&
                  subOp.status !== 'Finalizado' &&
                  subOp.status !== 'Liquidado' &&
                  subOp.status !== 'Confirmado Delivery',
              )
              .map((subOp) => ({
                ...subOp,
                mainOp: o,
              }));
          } else {
            return o.assignedDelivery ? [o] : [];
          }
        });
      setFilteredOperations(filtered);
    }
  }, [loading, operations, user?.role]);

  const renderOperations = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan="100%">
            <Loader />
          </td>
        </tr>
      );
    }

    if (!loading && filteredOperations.length === 0) {
      return (
        <tr>
          <td
            colSpan="100%"
            style={{
              textAlign: 'center',
              color: theme.palette.title.main,
              padding: '1rem',
            }}
          >
            No hay operaciones.
          </td>
        </tr>
      );
    }

    return filteredOperations.map((o) => {
      if (o.mainOp) {
        return (
          <LogisticOperation
            key={o._id}
            id={o.mainOp._id}
            subOpId={o._id}
            address={o.address}
            input={`${o.mainOp.input_currency?.currency} ${formatNumber(
              o.input_amount,
            )}`}
            output={`${o.mainOp.output_currency?.currency} ${formatNumber(
              o.output_amount,
            )}`}
            assignedDelivery={o.assignedDelivery}
            deliverys={deliverys}
            client={o.mainOp.client?.name}
            exchangeRate={o.mainOp.exchange_rate}
            marketPrice={o.mainOp.marketPrice}
            subOp={true}
            page={page}
            operation={o.mainOp}
          />
        );
      } else {
        return (
          <LogisticOperation
            key={o._id}
            id={o._id}
            address={o.address}
            input={
              o.input_currency
                ? `${o.input_currency.currency} ${formatNumber(o.input_amount)}`
                : null
            }
            output={
              o.output_currency
                ? `${o.output_currency.currency} ${formatNumber(
                    o.output_amount,
                  )}`
                : null
            }
            assignedDelivery={o.assignedDelivery}
            deliverys={deliverys}
            client={o.client?.name}
            exchangeRate={o.exchange_rate}
            marketPrice={o.marketPrice}
            page={page}
            operation={o}
          />
        );
      }
    });
  };

  return (
    <TableContainer
      sx={{
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '16px',
        height: '300px !important',
      }}
    >
      <Table
        sx={{
          width: '100%',
        }}
        aria-label="Logistic Operations Table"
      >
        <LogisticOperationHead />
        <TableBody>{renderOperations()}</TableBody>
      </Table>
      <PaginationContainer>
        <Stack
          spacing={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'end',
            marginTop: '1rem',
          }}
        >
          <Pagination
            hideNextButton={page === numberOfPages}
            hidePrevButton={page === 1}
            count={numberOfPages}
            page={page}
            size="large"
            onChange={(e, value) => setPage(value)}
            color="primary"
            variant="outlined"
            classes={{ ul: classes.root }}
          />
        </Stack>
      </PaginationContainer>
    </TableContainer>
  );
};

export default TableWithDeliveries;
