import React, { useEffect, useState, useRef } from 'react';
import { HomeContainer } from '../Layout/homeStyles';
import { useNavigate } from 'react-router-dom';
import BrokersTable from '../../components/Brokers/BrokersTable';
import useBrokers from './useBrokers';
import Loader from '../../components/Loader/Loader';
import {
  BrokersContainer,
  BrokersTitle,
  BrokersButtonsContainer,
  BrokersTableContainer,
} from '../Brokers/brokerStyles';

import Button from '../../components/Button/Button';
import { CSVLink } from 'react-csv';
import ButtonOutline from '../../components/Button/ButtonOutline';

const Brokers = ({ notificationClicked, setNotificationClicked }) => {
  const navigate = useNavigate();
  const {
    brokers,
    loading,
    page,
    setPage,
    search,
    deleteBroker,
    brokersForSearch,
    getBrokers,
    setBrokers,
    getBrokersForCsv,
    brokersForCsv,
  } = useBrokers();

  useEffect(() => {
    getBrokers(search);
    getBrokersForCsv();
  }, []);

  useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate(`/operaciones/${notificationClicked.id}`);
    }
  }, [notificationClicked.isClicked]);

  return (
    <HomeContainer style={{ marginLeft: '10px' }}>
      <BrokersContainer>
        <BrokersTitle>Comisionistas</BrokersTitle>
        <BrokersButtonsContainer>
          <>
            <div style={{ display: 'flex' }}>
              <Button
                onClick={() => {
                  navigate('/agregar-comisionista');
                }}
                title="NUEVO COMISIONISTA"
              />
            </div>
          </>

          <CSVLink
            separator={';'}
            data={brokersForCsv}
            filename="comisionistas.csv"
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            <ButtonOutline icon="csv" title="EXPORTAR CSV" />
          </CSVLink>
        </BrokersButtonsContainer>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader style={{ marginTop: '250px' }} />
          </div>
        ) : (
          <BrokersTableContainer>
            <BrokersTable
              brokers={brokers}
              loading={loading}
              deleteBroker={deleteBroker}
              brokersForSearch={brokersForSearch}
              getBrokers={getBrokers}
              setBrokers={setBrokers}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    justifyItems: 'center',
                    gap: '15px',
                    margin: '25px 0px',
                  }}
                ></div>
              </div>
            </BrokersTable>
          </BrokersTableContainer>
        )}
      </BrokersContainer>
    </HomeContainer>
  );
};

export default Brokers;
