import { Grid } from '@mui/material';
import {
  ButtonsContainer,
  ForgotPassword,
  Recover,
} from '../../containers/Login/loginStyles';

const ForgotAndRecoverButtons = ({ navigateFunction, theme }) => (
  <Grid 
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <ButtonsContainer>
      <ForgotPassword
        style={{
          fontFamily: `${theme.typography.fontWeightRegular}`,
        }}
      >
        ¿Olvidaste tu contraseña?
      </ForgotPassword>
      <Recover onClick={navigateFunction}>Recuperar</Recover>
    </ButtonsContainer>
  </Grid>
);

export default ForgotAndRecoverButtons;
