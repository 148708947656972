import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  createFilterOptions,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Loader from '../../../components/Loader/Loader';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import theme from '../../../theme';
import { operationTypes } from '../../../utils/constants';
import formatNumber from '../../../utils/formatNumber';
import { NumericFormat } from 'react-number-format';
import useUsers from '../../Team/useUsers';
import {
  BottomOperationFormContainer,
  ClientBalanceContainer,
  SubOperationFormContainer,
  SubOperationContainer,
  SuboperationContainerInputs,
  GridContainer,
  ItemContainer,
  ContentContainer,
  StyledSpan,
} from '../operationsStyles';
import { CurrenciesInput } from './CurrenciesInput';
import useAuth from '../../Login/useAuth';
import useClients from '../../Clients/useClients';
import ComissionAgentsForm from './ComissionAgentsForm';
import useBrokers from '../../Brokers/useBrokers';

const filter = createFilterOptions();

const MainForm = ({
  handleInputChange,
  handleNumericFormatChange,
  operation,
  stock,
  usersDelivery,
  selectedUser,
  setSelectedUser,
  clients,
  selectedClient,
  setSelectedClient,
  inputAnomalie,
  outputAnomalie,
  context,
  setOperation,
  setCurrencyChecked,
  currencyChecked,
  createIncomeCurrentAccount,
  setCreateIncomeCurrentAccount,
  handleAddSubOperation,
  handleSubtractSubOperation,
  handleSubOperationsInputChange,
  setSubOperations,
  handleSubOperationAmounts,
  subOperationsAnomalies,
  errors,
  handleSubOperationAnomaliesChange,
  isCurrentOperationInstitutional,
  onCheckboxChange,
  isClientsLoading,
  isStockLoading,
  isDeliveriesLoading,
}) => {
  const [loading, setLoading] = useState(true);
  const [hasDolars, setHasDolars] = useState(false);
  const [hasUsdt, setHasUsdt] = useState(false);
  const [checkDolarOrUsdtBase, setCheckDolarOrUsdtBase] = useState(null);
  const [areSubOpsNotPending, setAreSubOpsNotPending] = useState(false);

  const { subOperations } = operation;

  const user = useAuth();
  const { getProfile, profile } = useUsers();
  const { AddNewClient } = useClients();
  const { registerBroker } = useBrokers();

  useEffect(() => {
    getProfile(user?.id);
  }, []);

  useEffect(() => {
    if (
      operation.operation_type === 'ENTREGO/SALE POS.DELIVERY' ||
      operation.operation_type === 'RECIBO/ENTRA POS.DELIVERY'
    ) {
      setLoading(false);
    }
  }, [operation]);

  useEffect(() => {
    if (context !== 'edit') setLoading(false);
    if (selectedClient === undefined || selectedClient === null) return;
    if (operation.liquidator_type === 'DELIVERY') {
      setLoading(false);
      if (selectedUser === undefined || selectedUser === null) return;
    }

    setLoading(false);
  }, [selectedClient, selectedUser]);

  const handleSelectCurrencyAccount = (e) => {
    const selectedCurrency = JSON.parse(e.target.value).currency;
    const currencyName = selectedCurrency.name;
    const currencyId = selectedCurrency.id;

    const previousCurrencyId = operation.input_currency;

    if (e.target.checked) {
      setCurrencyChecked(JSON.parse(e.target.value));
      setOperation((prevOperation) => ({
        ...prevOperation,
        useCurrentAccount: e.target.checked,
        currencyChecked: {
          currency: {
            id: currencyId,
            name: currencyName,
          },
          amount: prevOperation.input_amount,
        },
        input_currency: currencyId,
        output_currency:
          previousCurrencyId !== currencyId
            ? previousCurrencyId
            : prevOperation.output_currency,
      }));
      setCheckDolarOrUsdtBase(null);
    } else {
      setCurrencyChecked(null);
      setOperation((prevOperation) => ({
        ...prevOperation,
        useCurrentAccount: e.target.checked,
        currencyChecked: null,
      }));
    }
  };

  const handleSelectUsdOrUsdt = (e) => {
    const currencyId = stock.filter((currency) => {
      return currency.currency === e.target.value;
    })[0]._id;

    if (e.target.checked) {
      setCheckDolarOrUsdtBase(e.target.value);
      setCurrencyChecked(null);
    } else {
      setCheckDolarOrUsdtBase(null);
    }
    setOperation((operation) => {
      if (e.target.checked) {
        return {
          ...operation,
          useDolarOrUsdtBase: e.target.value,
          useCurrentAccount: true,
          input_currency: currencyId,
        };
      } else {
        return {
          ...operation,
          useDolarOrUsdtBase: '',
          useCurrentAccount: false,
        };
      }
    });
  };

  const handleAddClient = async (name) => {
    const response = await AddNewClient(
      {
        name: name,
      },
      '',
      'operations',
    );
    setSelectedClient({
      name: name,
      _id: response[0]._id,
    });
  };

  const handleAddBroker = async (name) => {
    try {
      const response = await registerBroker({ name: name });
      if (response && response._id) {
        return response._id;
      } else {
        console.error('registerBroker did not return the expected object.');
        return null;
      }
    } catch (error) {
      console.error('Error in handleAddBroker:', error);
      return null;
    }
  };

  const handleCreateIncomeCurrentAccount = () => {
    const newCheckedState = !createIncomeCurrentAccount;
    setCreateIncomeCurrentAccount(newCheckedState);
    setOperation({
      ...operation,
      createIncomeCurrentAccount: newCheckedState,
    });
    onCheckboxChange(newCheckedState); // Llamar al callback
  };

  useEffect(() => {
    const checkCurrency = (currency) => {
      const hasCurrencyInStock = stock?.find((e) => e.currency === currency);
      const hasCurrencyInAccount = selectedClient?.currentAccount?.find(
        (e) => e.currency.name === currency,
      );
      return hasCurrencyInStock && !hasCurrencyInAccount;
    };

    setHasDolars(checkCurrency('USD'));
    setHasUsdt(checkCurrency('USDT'));
  }, [selectedClient, stock]);

  useEffect(() => {
    if (operation.client && context === 'edit') {
      setSelectedClient(operation.client);
    }
  }, [operation.client]);

  useEffect(() => {
    if (operation.currencyChecked && context === 'edit') {
      setCurrencyChecked(operation.currencyChecked);
    }
  }, [operation.currencyChecked]);

  useEffect(() => {
    const allNotPending = subOperations.every(
      (subOp) => subOp.status !== 'Pendiente',
    );

    if (
      operation?.subOperations?.length > 0 &&
      allNotPending &&
      context === 'edit'
    ) {
      setAreSubOpsNotPending(true);
    } else {
      setAreSubOpsNotPending(false);
    }
  }, [subOperations, context]);

  const order = [
    'USD',
    'ARS',
    'EURO GRANDE',
    'EURO CHICO',
    'LIBRAS',
    'BRL',
    'LEMAN OCEAN (CABLE) USD',
    'USDT',
  ];

  const orderedCurrentAccountSelectedClient =
    selectedClient?.currentAccount?.sort((a, b) => {
      return order.indexOf(a.currency.name) - order.indexOf(b.currency.name);
    });

  return (
    <>
      {loading && isClientsLoading && isDeliveriesLoading && isStockLoading ? (
        <Loader
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            marginTop: '50px',
          }}
        />
      ) : (
        <>
          <SubOperationFormContainer>
            {/* TIPO DE OPERACION */}
            <FormControl
              variant="standard"
              fullWidth
              disabled={profile?.role === 'liquidador_caja' ? true : false}
            >
              <InputLabel
                sx={{ color: `${theme.palette.inputText.main}` }}
                id="input"
              >
                Tipo de Operacion
              </InputLabel>
              <Select
                labelId="input"
                name="operation_type"
                onChange={(event) => handleInputChange(event)}
                value={operation.operation_type}
              >
                <ListSubheader>OPERACIONES FINANCIERAS</ListSubheader>
                {operationTypes()
                  .slice(0, 8)
                  .map((o, i) => (
                    <MenuItem
                      value={o}
                      key={i}
                      disabled={
                        context === 'edit' && isCurrentOperationInstitutional
                      }
                    >
                      {o}
                    </MenuItem>
                  ))}

                <ListSubheader>OPERACIONES INSTITUCIONALES</ListSubheader>
                {operationTypes()
                  .slice(8)
                  .map((o, i) => (
                    <MenuItem
                      value={o}
                      key={i}
                      disabled={
                        context === 'edit' && !isCurrentOperationInstitutional
                      }
                    >
                      {o}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {/* {CLIENTES} */}
            <Autocomplete
              value={selectedClient}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setSelectedClient({
                    name: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  handleAddClient(newValue.inputValue);
                } else {
                  setSelectedClient(newValue);
                  setOperation({
                    ...operation,
                    client: newValue,
                    address: newValue.address,
                    addressDetail: newValue.addressDetail,
                  });
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;

                const isExisting = options.filter(
                  (option) =>
                    option.name.toLowerCase() === inputValue.toLowerCase(),
                );

                if (inputValue !== '' && isExisting.length === 0) {
                  filtered.push({
                    inputValue,
                    name: `Agregar "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={clients}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }

                if (option.inputValue) {
                  return option.inputValue;
                }

                return option.name;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.name}</li>
              )}
              freeSolo
              fullWidth
              disabled={profile?.role === 'liquidador_caja' ? true : false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errors.client}
                  label="Clientes"
                  variant="standard"
                  InputLabelProps={{
                    style: {
                      color: `${theme.palette.inputText.main}`,
                    },
                  }}
                  sx={{
                    input: {
                      color: `${theme.palette.inputText.main}`,
                    },
                    '& .MuiInputLabel-root': { color: '#D3D6DA' },
                  }}
                />
              )}
            />
          </SubOperationFormContainer>
          {operation.useCurrentAccount && <h3>USA CUENTA CORRIENTE.</h3>}

          {selectedClient ? (
            <ClientBalanceContainer>
              <h3 style={{ margin: '0' }}>
                Cuenta corriente de {selectedClient.name}
              </h3>
              <GridContainer>
                {orderedCurrentAccountSelectedClient?.map((c, i) => (
                  <ItemContainer key={i}>
                    <ContentContainer>
                      <StyledSpan>{c.currency.name}</StyledSpan>
                      <span>{formatNumber(c.amount)}</span>
                    </ContentContainer>
                    <Checkbox
                      value={JSON.stringify(c)}
                      onChange={handleSelectCurrencyAccount}
                      sx={{
                        color: 'gray',
                        '&.Mui-checked': {
                          color: 'black',
                        },
                      }}
                      disabled={false}
                      checked={currencyChecked?.currency?.id === c.currency.id}
                    />
                  </ItemContainer>
                ))}
              </GridContainer>

              {hasDolars ? (
                <div>
                  <span>Usar cuenta corriente en USD para la operación</span>
                  <Checkbox
                    value={'USD'}
                    onChange={handleSelectUsdOrUsdt}
                    sx={{
                      color: 'gray',
                      '&.Mui-checked': {
                        color: 'black',
                      },
                    }}
                    disabled={false}
                    checked={checkDolarOrUsdtBase === 'USD'}
                  />
                </div>
              ) : null}
              {hasUsdt ? (
                <div>
                  <span>Usar cuenta corriente en USDT para la operación</span>
                  <Checkbox
                    value={'USDT'}
                    onChange={handleSelectUsdOrUsdt}
                    sx={{
                      color: 'gray',
                      '&.Mui-checked': {
                        color: 'black',
                      },
                    }}
                    disabled={false}
                    checked={checkDolarOrUsdtBase === 'USDT'}
                  />
                </div>
              ) : null}
              {selectedClient.currentAccount?.length === 0 ? (
                <span style={{ fontSize: '20px' }}>
                  El cliente seleccionado no posee ningún saldo.
                </span>
              ) : (
                <span>
                  La cuenta corriente del cliente quedará en negativo si el
                  saldo es inferior al monto de entrada.
                </span>
              )}
            </ClientBalanceContainer>
          ) : null}

          <CurrenciesInput
            handleInputChange={handleInputChange}
            stock={stock}
            operation={operation}
            setOperation={setOperation}
            setCurrencyChecked={setCurrencyChecked}
            errors={errors}
            context={context}
            areSubOpsNotPending={areSubOpsNotPending}
          />

          <ComissionAgentsForm
            setOperation={setOperation}
            operation={operation}
            filter={filter}
            profile={profile}
            errors={errors}
            theme={theme}
            handleAddBroker={handleAddBroker}
            context={context}
          />

          {subOperations?.length > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '30px',
              }}
            >
              <h3
                style={{
                  color: theme.palette.title.main,
                }}
              >
                Liquidadores
              </h3>
              {subOperations.map((subOperation, index) => (
                <SubOperationContainer key={index}>
                  <span
                    style={{
                      color: 'red',
                      marginTop: '20px',
                      marginBottom: '20px',
                    }}
                  >
                    ---------------- SubOperacion N {index + 1}
                    ---------------- {subOperation.status}
                  </span>
                  {/* LIQUIDADOR */}
                  <FormControl
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      alignItems: 'center',
                      gap: '30px',
                    }}
                    variant="standard"
                    fullWidth
                    error={errors.liquidator_type}
                  >
                    <InputLabel
                      sx={{ color: `${theme.palette.inputText.main}` }}
                      id={subOperations[index]?.liquidator_type}
                    >
                      Liquidador
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId={subOperations[index]?.liquidator_type}
                      name="liquidator_type"
                      onChange={(event) =>
                        handleSubOperationsInputChange(event, index)
                      }
                      value={subOperations[index]?.liquidator_type}
                      disabled={
                        profile?.role === 'liquidador_caja' ||
                        (context === 'edit' &&
                          subOperations[index].status !== 'Pendiente')
                          ? true
                          : false
                      }
                    >
                      <MenuItem value="CAJA A">CAJA A</MenuItem>
                      <MenuItem value="CAJA C">CAJA C</MenuItem>
                      <MenuItem value="DELIVERY">DELIVERY</MenuItem>
                    </Select>

                    {index === 0 && (
                      <AddCircleRoundedIcon
                        sx={{
                          color: 'green',
                          margin: '0',
                          padding: '0',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleAddSubOperation()}
                      />
                    )}

                    <CancelRoundedIcon
                      sx={{
                        color: 'red',
                        margin: '0',
                        padding: '0',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleSubtractSubOperation(index)}
                    />
                  </FormControl>

                  {/* DELIVERY */}
                  <Autocomplete
                    fullWidth
                    options={usersDelivery}
                    getOptionLabel={(usersDelivery) => usersDelivery.name}
                    value={subOperations[index]?.assignedDelivery || null}
                    sx={{ width: '100%' }}
                    disabled={
                      context === 'edit' &&
                      subOperations[index].status !== 'Pendiente'
                        ? true
                        : false
                    }
                    renderInput={(params) => (
                      <TextField
                        error={errors.assignedDelivery}
                        {...params}
                        label="Deliverys"
                        variant="standard"
                        fullWidth={true}
                        InputLabelProps={{
                          style: { color: `${theme.palette.inputText.main}` },
                        }}
                        sx={{
                          input: {
                            color: `${theme.palette.inputText.main}`,
                          },
                          '& .MuiInputLabel-root': { color: '#D3D6DA' },
                        }}
                      />
                    )}
                    onChange={(event, value) => {
                      setSubOperations((prevSubOperations) => {
                        const updatedSubOperations = [...prevSubOperations];
                        updatedSubOperations[index] = {
                          ...updatedSubOperations[index],
                          assignedDelivery: value,
                        };
                        return updatedSubOperations;
                      });

                      setOperation((prevOperation) => {
                        const updatedSubOperations = [
                          ...prevOperation.subOperations,
                        ];
                        updatedSubOperations[index] = {
                          ...updatedSubOperations[index],
                          assignedDelivery: value,
                        };
                        return {
                          ...prevOperation,
                          subOperations: updatedSubOperations,
                        };
                      });
                    }}
                    style={{
                      display: `${
                        subOperations[index]?.liquidator_type === 'DELIVERY'
                          ? 'block'
                          : 'none'
                      }`,
                    }}
                  />
                  {subOperation?.liquidator_type === 'DELIVERY' && (
                    <>
                      {' '}
                      {/* DIRECCION */}
                      <TextField
                        error={errors.address}
                        label="Dirección"
                        variant="standard"
                        type="text"
                        name="address"
                        fullWidth={true}
                        onChange={(event) =>
                          handleSubOperationsInputChange(event, index)
                        }
                        style={{
                          visibility: `${
                            operation.liquidator_type === 'DELIVERY'
                          }`,
                        }}
                        InputLabelProps={{
                          style: { color: `${theme.palette.inputText.main}` },
                        }}
                        sx={{
                          input: {
                            color: `${theme.palette.inputText.main}`,
                          },
                          '& .MuiInputLabel-root': { color: '#D3D6DA' },
                          borderBottom: '1px solid #D3D6DA',
                        }}
                        InputProps={{ disableUnderline: !errors.address }}
                        value={subOperations[index]?.address}
                        defaultValue={selectedClient?.address}
                        disabled={
                          context === 'edit' &&
                          subOperations[index].status !== 'Pendiente'
                            ? true
                            : false
                        }
                      />
                      <TextField
                        error={errors.addressDetail}
                        label="Detalle de la dirección"
                        variant="standard"
                        type="text"
                        name="addressDetail"
                        fullWidth={true}
                        onChange={(event) =>
                          handleSubOperationsInputChange(event, index)
                        }
                        style={{
                          visibility: `${
                            operation.liquidator_type === 'DELIVERY'
                          }`,
                        }}
                        InputLabelProps={{
                          style: { color: `${theme.palette.inputText.main}` },
                        }}
                        sx={{
                          input: {
                            color: `${theme.palette.inputText.main}`,
                          },
                          '& .MuiInputLabel-root': { color: '#D3D6DA' },
                          borderBottom: '1px solid #D3D6DA',
                        }}
                        InputProps={{ disableUnderline: !errors.address }}
                        value={subOperations[index].addressDetail}
                        defaultValue={selectedClient?.addressDetail}
                        disabled={
                          context === 'edit' &&
                          subOperations[index].status !== 'Pendiente'
                            ? true
                            : false
                        }
                      />
                    </>
                  )}
                  <SuboperationContainerInputs>
                    {/* MONTO DE ENTRADA */}
                    <NumericFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale={true}
                      onValueChange={(values) => {
                        handleSubOperationAmounts(
                          values,
                          'input_amount',
                          index,
                        );
                      }}
                      customInput={TextField}
                      value={subOperations[index]?.input_amount}
                      variant="standard"
                      InputProps={{
                        disableUnderline: false,
                        style: {
                          color: `black`,
                        },
                      }}
                      error={errors.input_amount}
                      disabled={
                        profile.role === 'liquidador_caja' ||
                        (context === 'edit' &&
                          subOperations[index].status !== 'Pendiente')
                          ? true
                          : subOperations.length - 1 === index
                          ? true
                          : false
                      }
                      placeholder="Monto de entrada"
                    />

                    {/* MONTO DE SALIDA */}

                    <NumericFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale={true}
                      onValueChange={(values) =>
                        handleSubOperationAmounts(
                          values,
                          'output_amount',
                          index,
                        )
                      }
                      value={subOperations[index]?.output_amount}
                      customInput={TextField}
                      variant="standard"
                      InputProps={{
                        disableUnderline: false,
                        style: {
                          color: `black`,
                          borderRadius: '10px',
                          backgroundColor: 'white',
                        },
                      }}
                      error={errors.input_amount}
                      disabled={
                        profile.role === 'liquidador_caja' ||
                        (context === 'edit' &&
                          subOperations[index].status !== 'Pendiente')
                          ? true
                          : subOperations.length - 1 === index
                          ? true
                          : false
                      }
                      placeholder="Monto de salida"
                    />

                    <NumericFormat
                      disabled={
                        profile.role === 'liquidador_caja'
                          ? true
                          : context === 'edit' &&
                            subOperationsAnomalies?.find((e) => {
                              return e.index === index && e.type === 'input';
                            })?.status !== 'active'
                          ? true
                          : profile.role === 'liquidador_caja' ||
                            (context === 'edit' &&
                              subOperations[index].status !== 'Pendiente')
                          ? true
                          : false
                      }
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale={true}
                      onValueChange={(values) =>
                        context === 'edit'
                          ? handleSubOperationAnomaliesChange(
                              values,
                              index,
                              'input',
                            )
                          : handleSubOperationAmounts(
                              values,
                              'input_anomaly',
                              index,
                            )
                      }
                      customInput={TextField}
                      value={
                        context === 'edit'
                          ? subOperationsAnomalies?.find((e) => {
                              return e.index === index && e.type === 'input';
                            })?.amount
                          : subOperations[index]?.input_anomaly
                      }
                      variant="standard"
                      InputProps={{
                        disableUnderline: false,
                        style: {
                          color: `black`,
                          borderRadius: '10px',
                          backgroundColor: 'white',
                        },
                      }}
                      id={
                        context === 'edit' &&
                        subOperationsAnomalies?.find((e) => {
                          return e.index === index && e.type === 'input';
                        })?.id
                      }
                      error={errors.input_amount}
                      placeholder="Anomalia de entrada"
                    />
                    {/* MONTO DE SALIDA */}
                    <NumericFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale={true}
                      onValueChange={(values) =>
                        context === 'edit'
                          ? handleSubOperationAnomaliesChange(
                              values,
                              index,
                              'output',
                            )
                          : handleSubOperationAmounts(
                              values,
                              'output_anomaly',
                              index,
                            )
                      }
                      value={
                        context === 'edit'
                          ? subOperationsAnomalies?.find((e) => {
                              return e.index === index && e.type === 'output';
                            })?.amount
                          : subOperations[index]?.output_anomaly
                      }
                      customInput={TextField}
                      variant="standard"
                      InputProps={{
                        disableUnderline: false,

                        style: {
                          color: `black`,
                          borderRadius: '10px',
                          backgroundColor: 'white',
                        },
                      }}
                      error={errors.input_amount}
                      disabled={
                        profile.role === 'liquidador_caja'
                          ? true
                          : context === 'edit' &&
                            subOperationsAnomalies?.find((e) => {
                              return e.index === index && e.type === 'output';
                            })?.status !== 'active'
                          ? true
                          : profile.role === 'liquidador_caja' ||
                            (context === 'edit' &&
                              subOperations[index].status !== 'Pendiente')
                          ? true
                          : false
                      }
                      placeholder="Anomalia de salida"
                    />
                  </SuboperationContainerInputs>
                </SubOperationContainer>
              ))}
            </div>
          )}
          <h3
            style={{
              color: theme.palette.title.main,
              display: `${subOperations?.length > 0 ? 'none' : 'block'}`,
            }}
          >
            Liquidadores
          </h3>
          <SubOperationFormContainer>
            {subOperations?.length <= 0 && (
              <>
                {/* LIQUIDADOR */}
                <FormControl
                  variant="standard"
                  fullWidth
                  error={errors.liquidator_type}
                >
                  <InputLabel
                    sx={{ color: `${theme.palette.inputText.main}` }}
                    id="liquidador"
                  >
                    Liquidador
                  </InputLabel>
                  <Select
                    labelId="liquidador"
                    name="liquidator_type"
                    onChange={(event) => handleInputChange(event)}
                    value={operation.liquidator_type}
                    disabled={
                      profile?.role === 'liquidador_caja' ? true : false
                    }
                  >
                    <MenuItem value="CAJA A">CAJA A</MenuItem>
                    <MenuItem value="CAJA C">CAJA C</MenuItem>
                    <MenuItem value="DELIVERY">DELIVERY</MenuItem>
                  </Select>
                </FormControl>
                <AddCircleRoundedIcon
                  sx={{
                    color: 'green',
                    margin: '0',
                    padding: '0',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleAddSubOperation()}
                />
                {/* DELIVERY */}
                <Autocomplete
                  fullWidth
                  options={usersDelivery}
                  getOptionLabel={(usersDelivery) => usersDelivery.name}
                  value={selectedUser}
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      error={errors.assignedDelivery}
                      {...params}
                      label="Deliverys"
                      variant="standard"
                      fullWidth={true}
                      InputLabelProps={{
                        style: { color: `${theme.palette.inputText.main}` },
                      }}
                      sx={{
                        input: {
                          color: `${theme.palette.inputText.main}`,
                        },
                        '& .MuiInputLabel-root': { color: '#D3D6DA' },
                      }}
                    />
                  )}
                  onChange={(event, value) => {
                    setSelectedUser(value);
                    setOperation({ ...operation, assignedDelivery: value });
                  }}
                  style={{
                    display: `${
                      operation.liquidator_type === 'DELIVERY'
                        ? 'block'
                        : 'none'
                    }`,
                  }}
                />
                {/* DIRECCION */}
                <TextField
                  error={errors.address}
                  label="Dirección"
                  variant="standard"
                  type="text"
                  name="address"
                  fullWidth={true}
                  onChange={(event) => handleInputChange(event)}
                  style={{
                    visibility: `${operation.liquidator_type === 'DELIVERY'}`,
                  }}
                  InputLabelProps={{
                    style: { color: `${theme.palette.inputText.main}` },
                  }}
                  sx={{
                    input: {
                      color: `${theme.palette.inputText.main}`,
                    },
                    '& .MuiInputLabel-root': { color: '#D3D6DA' },
                    borderBottom: '1px solid #D3D6DA',
                  }}
                  InputProps={{ disableUnderline: !errors.address }}
                  value={operation.address}
                />

                <TextField
                  error={errors.addressDetail}
                  label="Detalle de la dirección"
                  variant="standard"
                  type="text"
                  name="addressDetail"
                  fullWidth={true}
                  onChange={(event) => handleInputChange(event)}
                  style={{
                    visibility: `${operation.liquidator_type === 'DELIVERY'}`,
                  }}
                  InputLabelProps={{
                    style: { color: `${theme.palette.inputText.main}` },
                  }}
                  sx={{
                    input: {
                      color: `${theme.palette.inputText.main}`,
                    },
                    '& .MuiInputLabel-root': { color: '#D3D6DA' },
                    borderBottom: '1px solid #D3D6DA',
                  }}
                  InputProps={{ disableUnderline: !errors.address }}
                  value={operation.addressDetail}
                />
              </>
            )}
          </SubOperationFormContainer>

          <BottomOperationFormContainer>
            {!subOperations?.length ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-around',
                  gap: '30px',
                }}
              >
                {/* ANOMALIA ENTRADA */}
                <NumericFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                  customInput={TextField}
                  variant="standard"
                  label="Anomalía de entrada"
                  name="input_anomaly"
                  disabled={false}
                  onValueChange={(values, e) =>
                    handleNumericFormatChange(values, 'input_anomaly', e)
                  }
                  InputLabelProps={{
                    style: { color: `${theme.palette.inputText.main}` },
                  }}
                  sx={{
                    input: {
                      color: `${theme.palette.inputText.main}`,
                    },
                    '& .MuiInputLabel-root': { color: '#D3D6DA' },
                    borderBottom: '1px solid #D3D6DA',
                  }}
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                  defaultValue={inputAnomalie ? inputAnomalie[0]?.amount : ''}
                />
                {/* ANOMALIA SALIDA */}
                <NumericFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                  customInput={TextField}
                  variant="standard"
                  label="Anomalía de salida"
                  name="output_anomaly"
                  disabled={false}
                  onValueChange={(values, e) =>
                    handleNumericFormatChange(values, 'output_anomaly', e)
                  }
                  InputLabelProps={{
                    style: { color: `${theme.palette.inputText.main}` },
                  }}
                  sx={{
                    input: {
                      color: `${theme.palette.inputText.main}`,
                    },
                    '& .MuiInputLabel-root': { color: '#D3D6DA' },
                    borderBottom: '1px solid #D3D6DA',
                  }}
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                  defaultValue={outputAnomalie ? outputAnomalie[0]?.amount : ''}
                />
              </div>
            ) : null}

            {/* COMENTARIOS */}
            <TextField
              InputLabelProps={{
                style: { color: `${theme.palette.inputText.main}` },
              }}
              sx={{
                input: {
                  color: `${theme.palette.inputText.main}`,
                },
                '& .MuiInputLabel-root': { color: '#D3D6DA' },
                borderBottom: '1px solid #D3D6DA',
              }}
              InputProps={{ disableUnderline: true }}
              variant="standard"
              type="text"
              name="comments"
              label="Comentarios"
              onChange={(event) => handleInputChange(event)}
              fullWidth={true}
              value={operation.comments}
            />

            <div>
              <Checkbox
                value={createIncomeCurrentAccount}
                onChange={handleCreateIncomeCurrentAccount}
                checked={
                  context === 'edit'
                    ? operation.createIncomeCurrentAccount
                    : createIncomeCurrentAccount
                }
                disabled={
                  context === 'edit' && operation.createIncomeCurrentAccount
                }
                sx={{
                  color: 'gray',
                  '&.Mui-checked': {
                    color: 'black',
                  },
                }}
              />
              <span>
                Acreditar monto de salida en cuenta corriente del cliente
              </span>
            </div>
          </BottomOperationFormContainer>
        </>
      )}
    </>
  );
};

export default MainForm;
